import { Button, Col, Form, Row } from "react-bootstrap";
import {
  ImagingModality,
  Radiopharmaceutical,
  imagingModalityName,
  radiopharmaceuticalName,
} from "../constants/enums";
import { usePatientScansContext } from "../contexts/PatientScansContext";
import { usePatientsContext } from "../contexts/PatientsContext";
import React from "react";
import ImportDICOMModal from "./ImportDICOMModal";
import { useTranslation } from "react-i18next";

function TechnicalParams() {
  const { t } = useTranslation([
    "patient",
    "sections",
    "importDicom",
    "common",
  ]);

  const { currentPatient } = usePatientsContext();
  const { currentPatientScan, setCurrentPatientScan } =
    usePatientScansContext();

  const [showImportModal, setShowImportModal] = React.useState(false);

  const ctContrast =
    currentPatientScan.modality === ImagingModality.CT ||
    currentPatientScan.modality === ImagingModality.PET_CT;
  const mriContrast =
    currentPatientScan.modality === ImagingModality.MRI ||
    currentPatientScan.modality === ImagingModality.PET_MRI;
  const shouldDisplayContrast = ctContrast || mriContrast;

  const PreDefinedCTContrast = React.useMemo(
    () => [t("common:none"), t("predefinedContract.imeron300")],
    [t]
  );

  const PreDefinedMRIContrast = React.useMemo(
    () => [t("common:none"), t("predefinedContract.gadovist01")],
    [t]
  );

  const PreDefinedDiuretics = React.useMemo(
    () => [t("common:none"), t("predefinedContract.furosemide20")],
    [t]
  );

  return (
    <>
      <ImportDICOMModal
        show={showImportModal}
        onHide={() => {
          setShowImportModal(false);
        }}
      />
      <Row style={{ height: 85 }}>
        <Col md className="d-flex">
          <h3 className="my-auto">{t("sections:technicalParams")}</h3>
        </Col>
        <Col md className="d-flex justify-content-end">
          <Button
            variant="success"
            className="my-auto"
            onClick={() => {
              setShowImportModal(true);
            }}
          >
            {t("importDicom:importFromDicom")}
          </Button>
        </Col>
      </Row>
      <Form style={{ marginTop: 12 }}>
        {/* Row 1 - Scanner Location / Radiopharmaceutical */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("scannerLocation")}</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.location || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  location: e.target.value,
                });
              }}
            >
              {/* TODO: Clarify location list and whether this is editable */}
              <option value="n/a" disabled>
                {t("selectScannerLocation")}
              </option>
              <option value={t("predefinedScannerLocations.location1")}>
                {t("predefinedScannerLocations.location1")}
              </option>
              <option value={t("predefinedScannerLocations.location2")}>
                {t("predefinedScannerLocations.location2")}
              </option>
              {currentPatientScan.location != null &&
                currentPatientScan.reconstruction_method !==
                  t("predefinedScannerLocations.location1") &&
                currentPatientScan.reconstruction_method !==
                  t("predefinedScannerLocations.location2") && (
                  <option value={currentPatientScan.location}>
                    {currentPatientScan.location}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("radiopharmaceutical")}</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.radiopharmaceutical || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  radiopharmaceutical: e.target.value as Radiopharmaceutical,
                });
              }}
            >
              <option value="n/a" disabled>
                {t("selectRadiopharmaceutical")}
              </option>
              {Object.values(Radiopharmaceutical).map((rph, index) => (
                <option value={rph} key={`Radiopharmaceutical-${index}`}>
                  {radiopharmaceuticalName(rph)}
                </option>
              ))}
              {currentPatientScan.radiopharmaceutical != null &&
                !Object.values(Radiopharmaceutical).includes(
                  currentPatientScan.radiopharmaceutical
                ) && (
                  <option value={currentPatientScan.radiopharmaceutical}>
                    {currentPatientScan.radiopharmaceutical}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
        </Row>
        {/* Row 2 - Imaging Technique, Injected Activity */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("modality")}</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              aria-label={t("modality")}
              value={currentPatientScan.modality || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  modality: e.target.value as ImagingModality,
                });
              }}
            >
              <option value="n/a" disabled>
                {t("selectModality")}
              </option>
              {Object.values(ImagingModality).map((imod, index) => (
                <option value={imod} key={`ImagingModality-${index}`}>
                  {imagingModalityName(imod)}
                </option>
              ))}
              {currentPatientScan.modality != null &&
                !Object.values(ImagingModality).includes(
                  currentPatientScan.modality
                ) && (
                  <option value={currentPatientScan.modality}>
                    {currentPatientScan.modality}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("injectedActivity")}</Form.Label>
            <Form.Control
              disabled={!currentPatient.id}
              type="number"
              step="0.01"
              value={currentPatientScan.injected_activity || ""}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  injected_activity: parseFloat(e.target.value) || null,
                });
              }}
            />
          </Form.Group>
        </Row>
        {/* Row 3 - Scanner Model, Injected Time p.i. */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("scannerModel")}</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.scanner_model || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  scanner_model: e.target.value,
                });
              }}
            >
              <option value="n/a" disabled>
                {t("selectScannerModel")}
              </option>
              <option value={t("predefinedScannerModel.siemensBiographMCT")}>
                {t("predefinedScannerModel.siemensBiographMCT")}
              </option>
              <option value={t("predefinedScannerModel.siemensBiograph64")}>
                {t("predefinedScannerModel.siemensBiograph64")}
              </option>
              {currentPatientScan.scanner_model != null &&
                currentPatientScan.scanner_model !==
                  t("predefinedScannerModel.siemensBiographMCT") &&
                currentPatientScan.scanner_model !==
                  t("predefinedScannerModel.siemensBiograph64") && (
                  <option value={currentPatientScan.scanner_model}>
                    {currentPatientScan.scanner_model}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("injectedTime")}</Form.Label>
            <Form.Control
              disabled={!currentPatient.id}
              type="number"
              step="0.01"
              value={currentPatientScan.injected_time || ""}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  injected_time: parseFloat(e.target.value) || null,
                });
              }}
            />
          </Form.Group>
        </Row>
        {/* Row 4 - Scanner Type, Diuretics */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("scannerType")}</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.scanner_type || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  scanner_type: e.target.value,
                });
              }}
            >
              <option value="n/a" disabled>
                {t("selectScannerType")}
              </option>
              <option value="Analogue">{t("scannerTypes.analogue")}</option>
              <option value="Digital">{t("scannerTypes.digital")}</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("diuretics")}</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.diuretics || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  diuretics: e.target.value,
                });
              }}
            >
              <option value="n/a" disabled>
                {t("selectDiuretics")}
              </option>
              {PreDefinedDiuretics.map((diuretic, index) => (
                <option value={diuretic} key={index}>
                  {diuretic}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        {/* Row 5 - Reconstruction Method, Contrast */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">
              {t("reconstructionMethod")}
            </Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.reconstruction_method || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  reconstruction_method: e.target.value,
                });
              }}
            >
              <option value="n/a" disabled>
                {t("selectReconstructionMethod")}
              </option>
              <option value={t("predefinedReconstructionMethods.psfTof")}>
                {t("predefinedReconstructionMethods.psfTof")}
              </option>
              <option value={t("predefinedReconstructionMethods.osemd3d")}>
                {t("predefinedReconstructionMethods.osemd3d")}
              </option>
              {currentPatientScan.reconstruction_method != null &&
                currentPatientScan.reconstruction_method !==
                  t("predefinedReconstructionMethods.psfTof") &&
                currentPatientScan.reconstruction_method !==
                  t("predefinedReconstructionMethods.osemd3d") && (
                  <option value={currentPatientScan.reconstruction_method}>
                    {currentPatientScan.reconstruction_method}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
          {shouldDisplayContrast && (
            <Form.Group as={Col}>
              <Form.Label className="mb-0">{t("contrastAgent")}</Form.Label>
              <Form.Select
                disabled={!currentPatient.id}
                value={currentPatientScan.contrast || "n/a"}
                onChange={(e) => {
                  setCurrentPatientScan({
                    ...currentPatientScan,
                    contrast: e.target.value,
                  });
                }}
              >
                <option value="n/a" disabled>
                  {t("selectContrastAgent")}
                </option>
                {(ctContrast
                  ? PreDefinedCTContrast
                  : PreDefinedMRIContrast
                ).map((contrast, index) => (
                  <option value={contrast} key={index}>
                    {contrast}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </Row>
      </Form>
    </>
  );
}

export default TechnicalParams;
