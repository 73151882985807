import { useEffect, useState } from "react";
import {
  PatientTreatment,
  createPatientTreatment,
  deletePatientTreatment,
  getTreatmentsForPatient,
  initialPatientTreatment,
  updatePatientTreatment,
} from "../../api/patientTreatments";
import { useAuthContext } from "../../contexts/AuthContext";
import { DateTime } from "luxon";
import { Collection, DisplayPatientTreatment } from "../../constants/enums";

const treatmentSortCriteria = (
  a: DisplayPatientTreatment,
  b: DisplayPatientTreatment
) => {
  if (a.ended_at == null && b.ended_at != null) {
    return -1;
  }
  if (a.ended_at != null && b.ended_at == null) {
    return 1;
  }
  return b.started_at.diff(a.started_at).milliseconds;
};

export interface UsePatientTreatmentsType extends Collection<PatientTreatment> {
  save: (newPatientId?: number) => Promise<void>;
  discardChanges: () => Promise<void>;
}

export default function usePatientTreatments(
  currentPatientId: number | null
): UsePatientTreatmentsType {
  const { getAccessToken } = useAuthContext();
  const [treatments, setTreatments] = useState<DisplayPatientTreatment[]>([]);

  async function fetchTreatments() {
    if (currentPatientId == null) {
      console.warn("current patient ID not defined");
      return;
    }
    try {
      const accessToken = await getAccessToken();
      const newTreatments = (
        await getTreatmentsForPatient(accessToken, currentPatientId)
      )
        .map((t) => ({
          ...t,
          saved: true,
          deleted: false,
          edited: false,
        }))
        .sort(treatmentSortCriteria);
      setTreatments(newTreatments);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetchTreatments();
  }, [currentPatientId]);

  function updateTreatmentAtIndex(
    newTreatment: PatientTreatment,
    index: number
  ) {
    setTreatments(
      treatments
        .map((t, i) => {
          return i === index
            ? {
                ...newTreatment,
                saved: false,
                deleted: t.deleted,
                edited: false,
              }
            : t;
        })
        .sort(treatmentSortCriteria)
    );
  }

  // Don't delete a treatment here – just set the deleted flag
  function deleteTreatmentAtIndex(index: number) {
    setTreatments(
      treatments.map((t, i) => ({
        ...t,
        deleted: i === index ? true : t.deleted,
      }))
    );
  }

  function editTreatmentAtIndex(newValue: boolean, index: number) {
    setTreatments(
      treatments.map((t, i) => ({
        ...t,
        edited: i === index ? newValue : t.edited,
      }))
    );
  }

  function addTreatment() {
    setTreatments(
      treatments
        .concat({
          ...initialPatientTreatment(currentPatientId, "", DateTime.now()),
          saved: false,
          deleted: false,
          edited: true,
        })
        .sort(treatmentSortCriteria)
    );
  }

  // This function creates new treatments or updates existing, where applicable
  // It accepts an optional parameter - newPatientId. It's needed during the
  // initial treatment creation, when we had no idea
  async function saveTreatments(newPatientId?: number) {
    const accessToken = await getAccessToken();
    for (const treatment of treatments) {
      if (treatment.saved) {
        // Don't need to save anything
        continue;
      }
      if (treatment.deleted) {
        if (treatment.id != null && treatment.patient_id != null) {
          await deletePatientTreatment(
            accessToken,
            treatment.patient_id,
            treatment.id
          );
        }
        continue;
      }
      if (treatment.id != null) {
        if (treatment.patient_id != null) {
          // Update the treatment
          await updatePatientTreatment(
            accessToken,
            treatment.patient_id,
            treatment.id,
            treatment
          );
        } else {
          // Unreachable code: saved treatment cannot have an empty patient ID
          console.error("Cannot update treatment - patient ID is null");
        }
      } else {
        if (treatment.patient_id == null && newPatientId == null) {
          console.warn("Cannot save treatment - patient ID is null");
        } else {
          await createPatientTreatment(
            accessToken,
            (treatment.patient_id || newPatientId) as number, // Force cast to please TypeScript
            treatment
          );
        }
      }
    }
  }

  async function discardTreatmentChanges() {
    await fetchTreatments();
  }

  return {
    list: treatments,
    updateAtIndex: updateTreatmentAtIndex,
    deleteAtIndex: deleteTreatmentAtIndex,
    add: addTreatment,
    editAtIndex: editTreatmentAtIndex,
    save: saveTreatments,
    discardChanges: discardTreatmentChanges,
  };
}
