import * as React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";

import AuthLayout from "../AuthLayout";
import { AuthError } from "../../utils/errors";
import { signUp } from "../../api/auth";
import { useTranslation } from "react-i18next";

function SignUpPage() {
  const { t } = useTranslation(["user", "org", "auth"]);
  const { setLastUserId } = useAuthContext();
  const navigate = useNavigate();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [orgName, setOrgName] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);

  function errorMessagesFor(key) {
    return (errorMessage[key] || []).map((message, index) => (
      <Form.Control.Feedback key={`${key}-error-${index}`} type="invalid">
        {message}
      </Form.Control.Feedback>
    ));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const response = await signUp(
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation,
        orgName
      );
      setLastUserId(response["user_id"]);
      navigate("/sign_up/confirm_email");
    } catch (error) {
      if (error instanceof AuthError) {
        setErrorMessage(error.details);
      }
      console.error(error.message);
    }
  }

  const body = (
    <Form onSubmit={handleSubmit}>
      <h5>{t("user:userDetails")}</h5>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="first_name">
            <Form.Label>{t("user:firstName")}</Form.Label>
            <Form.Control
              type="text"
              autoComplete="given-name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              required
              isInvalid={
                errorMessage && errorMessage.hasOwnProperty("first_name")
              }
            />
            {errorMessage && errorMessagesFor("first_name")}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="lat_name">
            <Form.Label>{t("user:lastName")}</Form.Label>
            <Form.Control
              type="text"
              autoComplete="family-name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              required
              isInvalid={
                errorMessage && errorMessage.hasOwnProperty("last_name")
              }
            />
            {errorMessage && errorMessagesFor("last_name")}
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>{t("user:email")}</Form.Label>
        <Form.Control
          type="email"
          autoFocus={true}
          autoComplete="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
          isInvalid={errorMessage && errorMessage.hasOwnProperty("email")}
        />
        {errorMessage && errorMessagesFor("email")}
      </Form.Group>
      <Form.Group controlId="password" className="mb-3">
        <Form.Label>{t("auth:password")}</Form.Label>
        <Form.Control
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required
          isInvalid={errorMessage && errorMessage.hasOwnProperty("password")}
        />
        {errorMessage && errorMessagesFor("password")}
      </Form.Group>
      <Form.Group controlId="password_confirmation" className="mb-3">
        <Form.Label>{t("auth:passwordConfirmation")}</Form.Label>
        <Form.Control
          type="password"
          autoComplete="new-password"
          value={passwordConfirmation}
          onChange={(e) => {
            setPasswordConfirmation(e.target.value);
          }}
          required
          isInvalid={
            errorMessage && errorMessage.hasOwnProperty("password_confirmation")
          }
        />
        {errorMessage && errorMessagesFor("password_confirmation")}
      </Form.Group>
      <h5>{t("org:companyDetails")}</h5>
      <Form.Group controlId="org.name" className="mb-3">
        <Form.Label>{t("org:companyName")}</Form.Label>
        <Form.Control
          type="text"
          autoComplete="organization"
          value={orgName}
          onChange={(e) => {
            setOrgName(e.target.value);
          }}
          required
          isInvalid={errorMessage && errorMessage.hasOwnProperty("name")}
        />
        {errorMessage && errorMessagesFor("name")}
      </Form.Group>
      <Button variant="primary" type="submit" className="w-100">
        {t("auth:signUp")}
      </Button>
    </Form>
  );

  const links = (
    <React.Fragment>
      <Link to="/sign_in">{t("auth:signIn")}</Link>
      <Link to="/sign_up/resend_confirmation_email">
        {t("auth:resendConfirmationEmailLink")}
      </Link>
    </React.Fragment>
  );

  return <AuthLayout title={t("auth:signUp")} body={body} footer={links} />;
}

export default SignUpPage;
