import classNames from "classnames";
import { Nav } from "react-bootstrap";
import { Link, matchPath, useLocation } from "react-router-dom";

import "./ClinicSidebar.css";
import { usePatientsContext } from "../../contexts/PatientsContext";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import useLuPSMAEligibility from "../ImagingFindings/useLuPSMAEligibility";
import useRecipCriteria from "../RecipCriteria/useRecipCriteria";
import { usePatientRecipCriteriaContext } from "../../contexts/PatientRecipCriteriaContext";
import { useTranslation } from "react-i18next";

export interface Section {
  link: string;
  label: string;
  display?: boolean;
  divider?: boolean;
}

interface SectionWithSubsections {
  label: string;
  display?: boolean;
  subsections: Section[];
}

export default function ClinicSidebar() {
  const { pathname } = useLocation();

  // Fetch patient and patient scan to conditionally disable certain items
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan } = usePatientScansContext();
  const { currentPatientScanRecipCriteria } = usePatientRecipCriteriaContext();
  // Calculate LuPSMA eligibility criteria to conditionally hide this section
  const { criteriaApplicable: luPSMACriteriaApplicable } = useLuPSMAEligibility(
    currentPatient,
    currentPatientScan
  );
  const { criteriaApplicable: recipCriteriaApplicable } = useRecipCriteria(
    currentPatientScan,
    currentPatientScanRecipCriteria
  );

  const { t } = useTranslation("sections");

  function isActive(link: string) {
    return matchPath(`${link}`, pathname) !== null;
  }

  const SECTIONS: (Section | SectionWithSubsections)[] = [
    {
      link: "/home/patient_info",
      label: t("patientInformation"),
    },
    {
      link: "/home/clinical_data",
      label: t("clinicalData"),
    },
    {
      link: "/home/technical_params",
      label: t("technicalParams"),
    },
    {
      label: "Imaging Findings",
      subsections: [
        {
          link: "/home/imaging_findings/descriptive_findings",
          label: t("descriptiveFindings"),
        },
        {
          link: "/home/imaging_findings/tnm_classification/local_lesions",
          label: t("tnmClassification"),
        },
        {
          link: "/home/imaging_findings/quantitative_parameters",
          label: t("quantitativeParams"),
        },
        {
          link: "/home/imaging_findings/lesion_tracking/local_lesions",
          label: t("lesionTracking"),
        },
        {
          link: "/home/imaging_findings/recip_criteria",
          label: t("recipCriteria"),
          display: recipCriteriaApplicable,
        },
        {
          link: "/home/imaging_findings/lupsma_eligibility",
          label: t("lupsmaEligibility"),
          display: luPSMACriteriaApplicable,
        },
      ],
    },
  ];

  return (
    <div
      className="d-flex flex-grow justify-content-between flex-column pt-0"
      style={{ height: "100%" }}
    >
      <Nav
        variant="pills"
        defaultActiveKey="home/patient_info"
        className="flex-column py-0 my-3"
      >
        {SECTIONS.filter(
          (section) =>
            !section.hasOwnProperty("display") || section.display === true
        ).map((section, index) => {
          if (section.hasOwnProperty("subsections")) {
            const sectionwithSubsections = section as SectionWithSubsections;
            return (
              <div key={index}>
                <Nav.Item>
                  <Nav.Link className="sidebar-nav-link mb-2" disabled>
                    {sectionwithSubsections.label}
                  </Nav.Link>
                </Nav.Item>
                <div className="sidebar-nav-subsection">
                  {sectionwithSubsections.subsections
                    .filter(
                      (subsection) =>
                        !subsection.hasOwnProperty("display") ||
                        subsection.display === true
                    )
                    .map((subsection, subsectionIndex) => (
                      <Nav.Item key={`${index}-${subsectionIndex}`}>
                        <Link
                          to={subsection.link}
                          className={classNames([
                            "nav-link",
                            "sidebar-nav-link",
                            { active: isActive(subsection.link) },
                            section.hasOwnProperty("divider") &&
                            subsection.divider === true
                              ? "mb-5"
                              : "mb-2",
                          ])}
                        >
                          {subsection.label}
                        </Link>
                      </Nav.Item>
                    ))}
                </div>
              </div>
            );
          }

          const sectionAsSection = section as Section;
          return (
            <Nav.Item key={index}>
              <Link
                to={sectionAsSection.link}
                className={classNames([
                  "nav-link",
                  "sidebar-nav-link",
                  { active: isActive(sectionAsSection.link) },
                  section.hasOwnProperty("divider") &&
                  sectionAsSection.divider === true
                    ? "mb-5"
                    : "mb-2",
                ])}
              >
                {sectionAsSection.label}
              </Link>
            </Nav.Item>
          );
        })}
      </Nav>
    </div>
  );
}
