import * as React from "react";
import RegionOverlay from "./RegionOverlay";

interface useRegionOverlayType {
  overlay: React.ReactElement;
  setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  updateOverlayOnMouseMove: (
    event: React.PointerEvent,
    targetId: string | undefined
  ) => void;
}

function useRegionOverlay(
  parentRef: React.MutableRefObject<SVGSVGElement | null>,
  targetIdToText: (targetId: string | undefined) => string
): useRegionOverlayType {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [overlayText, setOverlayText] = React.useState("");
  const [overlayLeft, setOverlayLeft] = React.useState(0);
  const [overlayTop, setOverlayTop] = React.useState(0);

  const updateOverlayOnMouseMove = (
    event: React.PointerEvent,
    targetId: string | undefined
  ) => {
    if (parentRef.current === null) {
      return;
    }
    // @ts-ignore: Typing not robust enough
    const newOverlayText = targetIdToText(targetId || event.target.id);
    if (newOverlayText) {
      const bodyOffsets = parentRef.current.getBoundingClientRect();
      setOverlayLeft(event.clientX - bodyOffsets.x);
      setOverlayTop(event.clientY - bodyOffsets.y - 15);
      setOverlayText(newOverlayText);
    } else {
      setShowOverlay(false);
    }
  };

  const overlay = (
    <RegionOverlay
      left={overlayLeft}
      top={overlayTop}
      show={showOverlay}
      text={overlayText}
    />
  );

  return {
    overlay,
    setShowOverlay,
    updateOverlayOnMouseMove,
  };
}

export default useRegionOverlay;
