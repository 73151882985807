import { PatientTreatment } from "../api/patientTreatments";
import { TestResult } from "../api/testResults";
import i18n from "../i18n";

export enum TNMClassifications {
  PROMISE_MITNM_V2 = "PROMISE V2 miTNM",
  AJCC_TNM = "AJCC TNM",
}

export enum MiTNMSections {
  PROSTATE_TUMOR = "local_lesions",
  PELVIC_LYMPH_NODE_METASTASES = "pelvic_and_inguinal_lymph_nodes",
  BONE_METASTASES = "osseous_metastases",
  OTHER_ORGAN_METASTASES = "distant_lymph_nodes_and_organs",
  SUMMARY = "summary",
}

export const MiTNMSectionsArray = Object.values(MiTNMSections);

export function MiTNMSectionName(section: MiTNMSections): string {
  switch (section) {
    case MiTNMSections.PROSTATE_TUMOR:
      return i18n.t("miTNM:sectionNames.prostateTumor");
    case MiTNMSections.PELVIC_LYMPH_NODE_METASTASES:
      return i18n.t("miTNM:sectionNames.pelvicLymphNodeMetastases");
    case MiTNMSections.BONE_METASTASES:
      return i18n.t("miTNM:sectionNames.boneMetastases");
    case MiTNMSections.OTHER_ORGAN_METASTASES:
      return i18n.t("miTNM:sectionNames.otherOrganMetastases");
    case MiTNMSections.SUMMARY:
      return i18n.t("miTNM:sectionNames.summary");
  }
}

export enum ClinicalIndication {
  STAGING = "staging",
  RESTAGING = "restaging",
}

export function clinicalIndicationName(clinicalIndication: ClinicalIndication) {
  switch (clinicalIndication) {
    case ClinicalIndication.STAGING:
      return i18n.t("patient:clinicalIndications.staging");
    case ClinicalIndication.RESTAGING:
      return i18n.t("patient:clinicalIndications.restaging");
    default:
      return "";
  }
}

export enum Radiotracer {
  PSMA = "PSMA",
  FDG = "FDG",
  FCH = "FCH",
  NOT_APPLICABLE = "N/A",
}

// TODO: merge with Radiotracer
export enum Radiopharmaceutical {
  GA_PSMA11 = "\u2076\u2078Ga-PSMA-11", // 68 F
  F_DCFPYL = "\u00B9\u2078F-DCFPyL", // 18 F
  F_PSMA1007 = "\u00B9\u2078F-PSMA-1007", // 18 F
  F_rhPSMA73 = "\u00B9\u2078F-rhPSMA-7.3", // 18 F
  F_FDG = "\u00B9\u2078F-FDG", // 18 F
  TC_MDP = "\u2079\u2079Tc-MDP", // 99 Tc
}

export function radiopharmaceuticalName(r: Radiopharmaceutical) {
  switch (r) {
    case Radiopharmaceutical.GA_PSMA11:
      return i18n.t("radiopharmaceutical:GaPSMA11");
    case Radiopharmaceutical.F_DCFPYL:
      return i18n.t("radiopharmaceutical:FDCFPyL");
    case Radiopharmaceutical.F_PSMA1007:
      return i18n.t("radiopharmaceutical:FPSMA1007");
    case Radiopharmaceutical.F_rhPSMA73:
      return i18n.t("radiopharmaceutical:FRhPSMA73");
    case Radiopharmaceutical.F_FDG:
      return i18n.t("radiopharmaceutical:FFDG");
    case Radiopharmaceutical.TC_MDP:
      return i18n.t("radiopharmaceutical:TcMDP");
  }
}

export enum TherapeuticRadiopharmaceutical {
  LU_PSMA617 = "\u00B9\u2077\u2077Lu-PSMA-617", //177 Lu
  LU_PSMAINT = "\u00B9\u2077\u2077Lu-PSMA-I&T", //177 Lu
  LU_J591 = "\u00B9\u2077\u2077Lu-J591", //177 Lu
  LU_rhPSMA73 = "\u00B9\u2077\u2077Lu-rhPSMA-7.3", //177 Lu
  TB_PSMA_INT = "\u00B9\u2076\u00B9Tb-PSMA-I&T", //161 Tb
  AC_PSMA_INT = "\u00B2\u00B2\u2075Ac-PSMA-I&T", //225 Ac
}

export function therapeuticRadiopharmaceuticalName(
  tr: TherapeuticRadiopharmaceutical
) {
  switch (tr) {
    case TherapeuticRadiopharmaceutical.LU_PSMA617:
      return i18n.t("therapeuticRadiopharmaceutical:LuPSMA617");
    case TherapeuticRadiopharmaceutical.LU_PSMAINT:
      return i18n.t("therapeuticRadiopharmaceutical:LuPSMAINT");
    case TherapeuticRadiopharmaceutical.LU_J591:
      return i18n.t("therapeuticRadiopharmaceutical:LuJ591");
    case TherapeuticRadiopharmaceutical.LU_rhPSMA73:
      return i18n.t("therapeuticRadiopharmaceutical:LuRhPSMA73");
    case TherapeuticRadiopharmaceutical.TB_PSMA_INT:
      return i18n.t("therapeuticRadiopharmaceutical:TbPSMAINT");
    case TherapeuticRadiopharmaceutical.AC_PSMA_INT:
      return i18n.t("therapeuticRadiopharmaceutical:AcPSMAINT");
  }
}

export function radiotracerName(radiotracer: Radiotracer): string {
  switch (radiotracer) {
    case Radiotracer.PSMA:
      return "PSMA";
    case Radiotracer.FDG:
      return "FDG";
    case Radiotracer.FCH:
      return "FCH";
    default:
      return "";
  }
}

export enum ImagingModality {
  PET = "PET",
  PET_CT = "PET/CT",
  PET_MRI = "PET/MRI",
  CT = "CT",
  MRI = "MRI",
  BONE_SCAN = "Bone Scan",
  NOT_APPLICABLE = "N/A",
}

export function imagingModalityName(modality: ImagingModality) {
  switch (modality) {
    case ImagingModality.PET:
      return i18n.t("imagingModality:pet");
    case ImagingModality.PET_CT:
      return i18n.t("imagingModality:petCT");
    case ImagingModality.PET_MRI:
      return i18n.t("imagingModality:petMRI");
    case ImagingModality.CT:
      return i18n.t("imagingModality:ct");
    case ImagingModality.MRI:
      return i18n.t("imagingModality:mri");
    case ImagingModality.BONE_SCAN:
      return i18n.t("imagingModality:boneScan");
    case ImagingModality.NOT_APPLICABLE:
      return i18n.t("imagingModality:notApplicable");
  }
}

export enum ScanType {
  REGULAR = "regular",
  DISCREPANCIES = "discrepancies",
}

export function scanTypeName(scanType: ScanType): string {
  switch (scanType) {
    case ScanType.REGULAR:
      return i18n.t("patient:scanTypes.regular");
    case ScanType.DISCREPANCIES:
      return i18n.t("patient:scanTypes.discrepancies");
    default:
      return "";
  }
}

export enum ClinicalState {
  LOCALIZED_PC = "Localized PC",
  BCR = "BCR",
  mHSPC = "mHSPC",
  CRPC = "CRPC",
  mCRPC = "mCRPC",
}

export function clinicalStateName(clinicalState: ClinicalState): string {
  switch (clinicalState) {
    case ClinicalState.LOCALIZED_PC:
      return i18n.t("patient:clinicalStates.primaryPC");
    case ClinicalState.BCR:
      return i18n.t("patient:clinicalStates.biochemicalRecurrencePC");
    case ClinicalState.mHSPC:
      return i18n.t("patient:clinicalStates.metastaticHormoneSensitivePC");
    case ClinicalState.CRPC:
      return i18n.t(
        "patient:clinicalStates.nonMetastaticCastrationResistantPC"
      );
    case ClinicalState.mCRPC:
      return i18n.t("patient:clinicalStates.metastaticCastrationResistantPC");
  }
}

export enum RestagingCategory {
  BIOCHEMICAL_FAILURE = "biochemical_failure",
  PSMA_THERAPY_ELIGIBILITY = "psma_therapy_eligibility",
  TREATMENT_RESPONSE_EVALUATION = "treatment_response_evaluation",
  BASELINE_SCAN = "baseline_scan",
  SCAN_COMPARISON_RISING_PSA = "scan_comparison_rising_psa",
}

export function restagingCategoryName(restagingCategory: RestagingCategory) {
  switch (restagingCategory) {
    case RestagingCategory.BIOCHEMICAL_FAILURE:
      return i18n.t("patient:restagingCategories.biochemicalFailure");
    case RestagingCategory.BASELINE_SCAN:
      return i18n.t("patient:restagingCategories.biochemicalFailure");
    case RestagingCategory.SCAN_COMPARISON_RISING_PSA:
      return i18n.t("patient:restagingCategories.scanComparisonRisingPSA");
    case RestagingCategory.TREATMENT_RESPONSE_EVALUATION:
      return i18n.t("patient:restagingCategories.treatmentResponseEvaluation");
    case RestagingCategory.PSMA_THERAPY_ELIGIBILITY:
      return i18n.t("patient:restagingCategories.psmaTherapyEligibility");
  }
}

export enum PatientTreatmentCategory {
  CHEMO = "chemo",
  HORMONAL = "hormonal",
  CUSTOM = "custom",
  OTHER = "other",
}

export function patientTreatmentCategoryName(
  category: PatientTreatmentCategory
) {
  switch (category) {
    case PatientTreatmentCategory.CHEMO:
      return i18n.t("treatments:categories.chemo");
    case PatientTreatmentCategory.HORMONAL:
      return i18n.t("treatments:categories.hormonal");
    case PatientTreatmentCategory.CUSTOM:
      return i18n.t("treatments:categories.custom");
    case PatientTreatmentCategory.OTHER:
      return i18n.t("treatments:categories.other");
  }
}

export enum LuPSMATherapyEligibility {
  Eligible = "ELIGIBLE",
  Ineligible = "INELIGIBLE",
  NotDefined = "NOT DEFINED",
}

export function luPSMATherapyEligibilityName(
  eligibility: LuPSMATherapyEligibility
) {
  switch (eligibility) {
    case LuPSMATherapyEligibility.Eligible:
      return i18n.t("luPSMAEligibility:eligibility.eligible").toUpperCase();
    case LuPSMATherapyEligibility.Ineligible:
      return i18n.t("luPSMAEligibility:eligibility.ineligible").toUpperCase();
    case LuPSMATherapyEligibility.NotDefined:
      return i18n.t("luPSMAEligibility:eligibility.notDefined").toUpperCase();
  }
}

export enum RecipCriteriaResponseEvaluation {
  CompleteResponse = "RECIP-CR",
  PartialResponse = "RECIP-SD",
  StableDisease = "RECIP-SD",
  ProgressiveDisease = "RECIP-PD",
  NotApplicable = "n/a",
}

export function recipCriteriaResponseEvaluationName(
  re: RecipCriteriaResponseEvaluation | void
): string {
  if (re == null) {
    return i18n.t("common:notApplicable");
  }
  switch (re) {
    case RecipCriteriaResponseEvaluation.CompleteResponse:
      return i18n.t("recipCriteria:completeResponseCode");
    case RecipCriteriaResponseEvaluation.PartialResponse:
      return i18n.t("recipCriteria:partialResponseCode");
    case RecipCriteriaResponseEvaluation.StableDisease:
      return i18n.t("recipCriteria:stableDiseaseCode");
    case RecipCriteriaResponseEvaluation.ProgressiveDisease:
      return i18n.t("recipCriteria:progressiveDiseaseCode");
    case RecipCriteriaResponseEvaluation.NotApplicable:
      return i18n.t("common:notApplicable");
  }
}

export interface Collection<T> {
  list: (T & CollectionItem)[];
  updateAtIndex: (newValue: T, index: number) => void;
  deleteAtIndex: (index: number) => void;
  add: () => void;
  editAtIndex: (newValue: boolean, index: number) => void;
}

export interface CollectionItem {
  saved: boolean;
  deleted: boolean;
  edited: boolean;
}

export interface DisplayPatientTreatment
  extends PatientTreatment,
    CollectionItem {}

export interface DisplayTestResult extends TestResult, CollectionItem {}
