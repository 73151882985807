import * as React from "react";
import {
  BoneMarkerInfo,
  createPatientScan,
  getPatientScanById,
  initialPatientScanForProduct,
  OtherMarkerInfo,
  PatientScan,
  PelvicMarkerInfo,
  TumorMarkerInfo,
  updatePatientScan,
} from "../api/patientScans";
import {
  ClinicalIndication,
  ClinicalState,
  Radiotracer,
  ScanType,
} from "../constants/enums";
import { useAuthContext } from "./AuthContext";
import { useOrgContext } from "./OrgContext";
import { useCurrentPatientId, useCurrentScanId } from "../utils/routerUtils";

interface CurrentPatientScanContextType {
  loading: boolean;
  saved: boolean;
  currentPatientScan: PatientScan;
  setCurrentPatientScan: (newPatientScan: PatientScan) => void;
  updateTumorMarkers: (markers: TumorMarkerInfo) => void;
  updatePelvicMarkers: (markers: PelvicMarkerInfo) => void;
  updateBoneMarkers: (markers: BoneMarkerInfo) => void;
  updateOtherMarkers: (markers: OtherMarkerInfo) => void;
  saveCurrentPatientScan: () => Promise<void>;
  discardCurrentPatientScanChanges: () => void;
  markerColor: string;
}

const CurrentPatientScanContext =
  React.createContext<CurrentPatientScanContextType>({
    loading: false,
    saved: true,
    currentPatientScan: initialPatientScanForProduct("basic"),
    setCurrentPatientScan: (p) => {},
    updateTumorMarkers: (markers) => {},
    updatePelvicMarkers: (markers) => {},
    updateBoneMarkers: (markers) => {},
    updateOtherMarkers: (markers) => {},
    saveCurrentPatientScan: async () => {},
    discardCurrentPatientScanChanges: async () => {},
    markerColor: "#e53700",
  });

export function CurrentPatientScanContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { getAccessToken } = useAuthContext();
  const { currentOrg } = useOrgContext();

  const initialPatientScan = React.useMemo(
    () => initialPatientScanForProduct(currentOrg?.product || "basic"),
    [currentOrg]
  );

  const currentPatientId = useCurrentPatientId();
  const currentPatientScanId = useCurrentScanId();

  const [loading, setLoading] = React.useState(false);
  const [saved, setSaved] = React.useState(true);

  const [currentPatientScan, setCurrentPatientScan__internal] =
    React.useState<PatientScan>(initialPatientScan);

  const markerColor: string = React.useMemo(() => {
    if (currentPatientScan.scan_type === ScanType.REGULAR) {
      switch (currentPatientScan.radiotracer) {
        case Radiotracer.FDG:
          return "#9DC3E6";
        case Radiotracer.FCH:
          return "#7030A0";
        case Radiotracer.PSMA:
        default:
          return "#e53700";
      }
    } else {
      const discrepancyType = `${currentPatientScan.metadata.firstScanRadiotracer}-${currentPatientScan.metadata.secondScanRadiotracer}`;
      switch (discrepancyType) {
        case "PSMA-FDG":
          return "#92D051";
        case "PSMA-FCH":
          return "#FFD966";
        case "[PSMA-FDG]-FCH":
          return "#7030A0";
        case "[PSMA-FCH]-FDG":
          return "#9DC3E6";
        default:
          return "#e53700";
      }
    }
  }, [
    currentPatientScan.scan_type,
    currentPatientScan.radiotracer,
    currentPatientScan.metadata.firstScanRadiotracer,
    currentPatientScan.metadata.secondScanRadiotracer,
  ]);

  // Refresh the current patient scan on every patient scan ID change
  React.useEffect(() => {
    refreshCurrentPatient();
  }, [currentPatientId, currentPatientScanId]);

  async function refreshCurrentPatient() {
    setLoading(true);
    if (currentPatientId == null || currentPatientScanId == null) {
      setCurrentPatientScan__internal(initialPatientScan);
      setSaved(true);
      setLoading(false);
      return;
    }
    const accessToken = await getAccessToken();
    setCurrentPatientScan__internal(
      await getPatientScanById(
        accessToken,
        currentPatientId,
        currentPatientScanId
      )
    );
    setSaved(true);
    setLoading(false);
  }

  // Reset the clinical state every time clinical indication is changed
  React.useEffect(() => {
    if (
      currentPatientScan.clinical_indication === ClinicalIndication.STAGING &&
      currentPatientScan.clinical_state !== ClinicalState.LOCALIZED_PC
    ) {
      setCurrentPatientScan({
        ...currentPatientScan,
        clinical_state: ClinicalState.LOCALIZED_PC,
      });
    }
    if (
      currentPatientScan.clinical_indication === ClinicalIndication.RESTAGING &&
      currentPatientScan.clinical_state === ClinicalState.LOCALIZED_PC
    ) {
      setCurrentPatientScan({
        ...currentPatientScan,
        clinical_state: null,
      });
    }
  }, [currentPatientScan.clinical_indication]);

  function setCurrentPatientScan(newPatientScan: PatientScan) {
    setCurrentPatientScan__internal(newPatientScan);
    setSaved(false);
  }

  function updateTumorMarkers(markers: TumorMarkerInfo) {
    if (currentPatientScan) {
      setCurrentPatientScan({
        ...currentPatientScan,
        marker_info: {
          ...currentPatientScan.marker_info,
          prostate_tumor: markers,
        },
      });
    }
  }

  function updatePelvicMarkers(markers: PelvicMarkerInfo) {
    if (currentPatientScan) {
      setCurrentPatientScan({
        ...currentPatientScan,
        marker_info: {
          ...currentPatientScan.marker_info,
          pelvic_lymph_node_metastases: markers,
        },
      });
    }
  }

  function updateBoneMarkers(markers: BoneMarkerInfo) {
    if (currentPatientScan) {
      setCurrentPatientScan({
        ...currentPatientScan,
        marker_info: {
          ...currentPatientScan.marker_info,
          bone_metastases: markers,
        },
      });
    }
  }

  function updateOtherMarkers(markers: OtherMarkerInfo) {
    if (currentPatientScan) {
      setCurrentPatientScan({
        ...currentPatientScan,
        marker_info: {
          ...currentPatientScan.marker_info,
          other_organ_metastases: markers,
        },
      });
    }
  }

  async function saveCurrentPatientScan() {
    if (currentPatientId == null) {
      console.warn("Cannot save patient scan - patient ID is null");
      return;
    }

    const accessToken = await getAccessToken();

    try {
      if (currentPatientScan.id == null) {
        const newPatientScan = await createPatientScan(
          accessToken,
          currentPatientId,
          currentPatientScan
        );
        setCurrentPatientScan__internal(newPatientScan);
      } else {
        await updatePatientScan(
          accessToken,
          currentPatientId,
          currentPatientScan.id,
          currentPatientScan
        );
      }
      setSaved(true);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <CurrentPatientScanContext.Provider
      value={{
        loading,
        saved,
        currentPatientScan,
        setCurrentPatientScan,
        updateTumorMarkers,
        updateBoneMarkers,
        updatePelvicMarkers,
        updateOtherMarkers,
        saveCurrentPatientScan,
        discardCurrentPatientScanChanges: refreshCurrentPatient,
        markerColor,
      }}
    >
      {children}
    </CurrentPatientScanContext.Provider>
  );
}

export function usePatientScansContext() {
  return React.useContext(CurrentPatientScanContext);
}
