import { matchPath, useLocation } from "react-router";
import { useCurrentPatientId } from "../../utils/routerUtils";
import React from "react";
import { Nav } from "react-bootstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useCurrentPatientContext } from "../../contexts/CurrentPatientContext";

interface Section {
  link?: string;
  label: string;
  display?: boolean;
  divider?: boolean;
  children?: Section[];
}

export default function ClinicSidebarV2() {
  const { pathname } = useLocation();

  function isActive(link: string) {
    return matchPath(`${link}`, pathname) !== null;
  }

  const currentPatientId = useCurrentPatientId();

  const { currentPatient } = useCurrentPatientContext();

  function renderSection(section: Section): React.ReactElement {
    return (
      <>
        <Nav.Item key={section.link || section.label}>
          <Link
            to={section.link || "/"}
            className={classNames([
              "nav-link",
              "sidebar-nav-link",
              { active: section.link ? isActive(section.link) : false },
              section.divider != null && section.divider ? "mb-5" : "mb-2",
              { disabled: section.link == null },
            ])}
          >
            {section.label}
          </Link>
        </Nav.Item>
        {section.children && (
          <div className="sidebar-nav-subsection">
            {section.children
              .filter((s) => s.display == null || s.display)
              .map((s) => renderSection(s))}
          </div>
        )}
      </>
    );
  }

  console.log(currentPatientId);

  const SECTIONS: Section[] = [
    {
      link: "/patients",
      label: "All patients",
    },
    {
      link: "/patients/new",
      label: "New patient",
      display: isActive("/patients/new"),
    },
    {
      link: `/patients/${currentPatientId}`,
      label: currentPatient.patient_id || "Patient",
      display: currentPatientId != null,
      children: [
        {
          link: `/patients/${currentPatientId}/scans`,
          label: "Scans",
          display: currentPatientId != null,
        },
        {
          link: "TODO: Lesion Tracking",
          label: "Lesion Tracking",
        },
      ],
    },
  ];

  /*
    Sections mind map

    Main page (/patients):
    All patients

    Create patient (/patients/new)

    All Patients
    
    New patient

    View/edit patient (/patients/:patientId)
    Check patient scans (/patients/:patientId/scans)

    All patients
    
    Patient :patientId
    - Scans
    - Lesion Tracking

    New patient scan (/patients/:patientId/scans/new/*)

    All patients
    
    Patient :patientId
    - Scans
    - Lesion Tracking
    
    New Scan
    - Clinical Information
    - Technical Information
    - Descriptive Findings
    - TNM Classification
    - Quantitative Parameters
    - Other criteria
    
    
    View/edit patient scan (/patients/:patientId/scans/:scanId/*)

    All patients
    
    Patient :patientId
    - Scans
    - Lesion Tracking
    
    Scan :scanId
    - Clinical Information
    - Technical Information
    - Descriptive Findings
    - TNM Classification
    - Quantitative Parameters
    - Other criteria


    Blocks

    All patients - always visible

    New patient block - visible if matches /patients/new

    Existing patient block - visible if matches /patients/:patientId/*

    New/Existing patient scan block - visible if matches /patients/:patientId/scans/:scanId/* or /patients/:patientId/scans/new/*
  */

  return (
    <div
      className="d-flex flex-grow justify-content-between flex-column pt-0"
      style={{ height: "100%" }}
    >
      <Nav
        variant="pills"
        defaultActiveKey="/patients"
        className="flex-column py-0 my-3"
      >
        {SECTIONS.filter((s) => s.display == null || s.display).map((s) =>
          renderSection(s)
        )}
      </Nav>
    </div>
  );
}
