import { useEffect, useState } from "react";
import {
  TestResult,
  createTestResult,
  deleteTestResult,
  getTestResultsForPatient,
  initialTestResult,
  updateTestResult,
} from "../../api/testResults";
import { Collection, DisplayTestResult } from "../../constants/enums";
import { useAuthContext } from "../../contexts/AuthContext";
import { DateTime } from "luxon";

const testResultSortCriteria = (a: DisplayTestResult, b: DisplayTestResult) => {
  return b.taken_at.diff(a.taken_at).milliseconds;
};

export interface UsePatientTestResultsType extends Collection<TestResult> {
  save: (newPatientId?: number) => Promise<void>;
  discardChanges: () => Promise<void>;
}

export default function usePatientTestResults(
  currentPatientId: number | null
): UsePatientTestResultsType {
  const { getAccessToken } = useAuthContext();
  const [testResults, setTestResults] = useState<DisplayTestResult[]>([]);

  async function fetchTestResults() {
    if (currentPatientId == null) {
      console.warn("Current patient ID is not defined");
      return;
    }
    try {
      const accessToken = await getAccessToken();
      const newTestResults = (
        await getTestResultsForPatient(accessToken, currentPatientId)
      )
        .map((tr) => ({
          ...tr,
          saved: true,
          deleted: false,
          edited: false,
        }))
        .sort(testResultSortCriteria);
      setTestResults(newTestResults);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetchTestResults();
  }, [currentPatientId]);

  function updateTestResultAtIndex(newTestResult: TestResult, index: number) {
    setTestResults(
      testResults
        .map((tr, i) =>
          i === index
            ? {
                ...newTestResult,
                saved: false,
                deleted: tr.deleted,
                edited: false,
              }
            : tr
        )
        .sort(testResultSortCriteria)
    );
  }

  function deleteTestResultAtIndex(index: number) {
    setTestResults(
      testResults.map((tr, i) => ({
        ...tr,
        deleted: i === index ? true : tr.deleted,
      }))
    );
  }

  function editTestResultAtIndex(newValue: boolean, index: number) {
    setTestResults(
      testResults.map((tr, i) => ({
        ...tr,
        edited: i === index ? newValue : tr.edited,
      }))
    );
  }

  function addTestResult() {
    setTestResults(
      testResults
        .concat({
          ...initialTestResult("", 0, DateTime.now()),
          saved: false,
          deleted: false,
          edited: true,
        })
        .sort(testResultSortCriteria)
    );
  }

  async function saveTestResults(newPatientId?: number) {
    const accessToken = await getAccessToken();
    for (const testResult of testResults) {
      if (testResult.saved) {
        // Don't need to save anything
        continue;
      }
      if (testResult.deleted) {
        // Delete test result if it's saved to the DB
        if (testResult.id != null && testResult.patient_id != null) {
          await deleteTestResult(
            accessToken,
            testResult.patient_id,
            testResult.id
          );
        }
        continue;
      }
      if (testResult.id != null) {
        if (testResult.patient_id != null) {
          await updateTestResult(
            accessToken,
            testResult.patient_id,
            testResult.id,
            testResult
          );
        } else {
          // Unreachable code: saved treatment cannot have an empty patient ID
          console.error("Cannot update test result - patient ID is null");
        }
      } else {
        if (testResult.patient_id == null && newPatientId == null) {
          console.warn("Cannot save test result - patient ID is null");
        } else {
          await createTestResult(
            accessToken,
            (testResult.patient_id || newPatientId) as number,
            testResult
          );
        }
      }
    }
  }

  async function discardTestResultChanges() {
    await fetchTestResults();
  }

  return {
    list: testResults,
    updateAtIndex: updateTestResultAtIndex,
    deleteAtIndex: deleteTestResultAtIndex,
    add: addTestResult,
    editAtIndex: editTestResultAtIndex,
    save: saveTestResults,
    discardChanges: discardTestResultChanges,
  };
}
