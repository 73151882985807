import * as React from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getOrgUsers } from "../../api/orgs";
import { getAllPatients, Patient } from "../../api/patients";
import {
  createStudyParticipation,
  deleteStudyParticipation,
  getAllStudyParticipations,
  initialStudyParticipation,
  StudyParticipation,
  updateStudyParticipation,
} from "../../api/studyParticipations";
import { useAuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

function UserManagement() {
  const { t } = useTranslation(["studyParticipation", "user", "sections"]);
  const { getAccessToken, currentUser } = useAuthContext();

  const [orgUsers, setOrgUsers] = React.useState<any[]>([]);
  const [patients, setPatients] = React.useState<Patient[]>([]);
  const [studyParticipations, setStudyParticipations] = React.useState<
    StudyParticipation[]
  >([]);

  // Fetch all users on first render
  React.useEffect(() => {
    fetchUsers();
    fetchPatients();
    fetchStudyParticipations();
  }, []);

  function findUserStudyParticipation(userId: number) {
    return studyParticipations.find((p) => p.user_id === userId);
  }

  async function fetchUsers() {
    const accessToken = await getAccessToken();
    const users = await getOrgUsers(accessToken, currentUser.org_id);
    setOrgUsers(users);
  }

  async function fetchPatients() {
    const accessToken = await getAccessToken();
    const newPatients = await getAllPatients(accessToken);
    setPatients(newPatients);
  }

  async function fetchStudyParticipations() {
    const accessToken = await getAccessToken();
    const newStudyParticipations = await getAllStudyParticipations(accessToken);
    setStudyParticipations(newStudyParticipations);
  }

  async function onUserStudyParticipationChange(userId: number, group: string) {
    const accessToken = await getAccessToken();
    const userStudyParticipation = findUserStudyParticipation(userId);

    if (!userStudyParticipation && group !== "None") {
      // Create new study participation
      // Create a new study participation for that user with the correct group allocation
      const studyParticipationObject = {
        ...initialStudyParticipation(group),
        user_id: userId,
      };

      const response = await createStudyParticipation(
        accessToken,
        studyParticipationObject
      );

      setStudyParticipations(
        studyParticipations.concat({
          ...studyParticipationObject,
          id: response.id,
        })
      );
    }

    if (userStudyParticipation?.id) {
      if (group === "None") {
        // Remove the study participation
        await deleteStudyParticipation(accessToken, userStudyParticipation.id);
        setStudyParticipations(
          studyParticipations.filter((p) => p.id !== userStudyParticipation.id)
        );
      } else {
        // Update the group allocation both on the server and on the client
        userStudyParticipation.group = group;

        await updateStudyParticipation(
          accessToken,
          userStudyParticipation.id,
          userStudyParticipation
        );

        setStudyParticipations(
          studyParticipations.map((p) => ({
            ...p,
            group: p.id === userStudyParticipation.id ? group : p.group,
          }))
        );
      }
    }
  }

  function toUserRow(user: any) {
    const userStudyParticipation = findUserStudyParticipation(user.id);
    const currentPatient = userStudyParticipation
      ? patients.find((p) => p.id === userStudyParticipation.current_patient_id)
      : undefined;

    return (
      <Form key={user.id}>
        <Row>
          <Col md={3}>
            <Form.Control
              plaintext
              readOnly
              value={`${user.first_name} ${user.last_name}`}
            />
          </Col>
          <Col md={3}>
            <Form.Control plaintext readOnly tabIndex={-1} value={user.email} />
          </Col>
          <Col md={2}>
            <Form.Select
              disabled={
                userStudyParticipation !== undefined &&
                userStudyParticipation.current_patient_id !== null
              }
              value={
                userStudyParticipation ? userStudyParticipation.group : "None"
              }
              onChange={(e) => {
                onUserStudyParticipationChange(user.id, e.target.value);
              }}
            >
              <option value="None">None</option>
              <option value="1">Group 1</option>
              <option value="2">Group 2</option>
            </Form.Select>
          </Col>
          <Col md="2">
            <Form.Control
              plaintext
              readOnly
              value={
                currentPatient?.patient_id || t("studyParticipation:notStarted")
              }
            />
          </Col>
          <Col md="2">
            <Form.Control
              plaintext
              readOnly
              value={
                (userStudyParticipation &&
                  userStudyParticipation.current_step) ||
                t("studyParticipation:notStarted")
              }
            />
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <div className="my-3 px-3">
      <Row>
        <Col md>
          <h3>{t("sections:users")}</h3>
        </Col>
        <Col md>
          <Button
            //@ts-ignore Is works, I've tried!
            as={Link}
            to="/invite/new"
            variant="success"
            className="float-end"
          >
            {t("user:inviteUserButton")}
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="3">{t("user:username")}</Col>
        <Col md="3">{t("user:email")}</Col>
        <Col md="2">{t("studyParticipation:studyGroup")}</Col>
        <Col md="2">{t("studyParticipation:currentPatient")}</Col>
        <Col md="2">{t("studyParticipation:currentStep")}</Col>
      </Row>
      <hr className="mt-1 mb-2" />
      {orgUsers.map(toUserRow)}
    </div>
  );
}

export default UserManagement;
