import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function PatientScanSelector({
  disabled = false,
  patientScans,
  value = "-1",
  onChange,
  placeholder,
  filter,
  scanLabel,
}) {
  const { t } = useTranslation("patient");
  const filteredPatientScans =
    filter === undefined ? patientScans : patientScans.filter(filter);

  return (
    <Form.Group>
      <Form.Select
        aria-label={t("selectScan")}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <option value="-1" key="PatientScanSelect-NewScan">
          {placeholder}
        </option>
        {filteredPatientScans.map((scan) => (
          <option value={scan.id} key={`PatientScanSelect-${scan.id}`}>
            {scanLabel(scan)}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}

PatientScanSelector.propTypes = {
  disabled: PropTypes.bool,
  patientScans: PropTypes.array.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  filter: PropTypes.func,
  scanLabel: PropTypes.func,
};

export default PatientScanSelector;
