import { DateTime } from "luxon";
import { PatientScan } from "../api/patientScans";
import i18n from "../i18n";

function regularScanLabel(patientScan: PatientScan) {
  let label = patientScan.date
    ? patientScan.date.toLocaleString(DateTime.DATE_SHORT)
    : patientScan.created_at?.toLocaleString(DateTime.DATE_SHORT) ||
      `#${patientScan.id || i18n.t("common:unknown")}`;

  if (patientScan.radiotracer || patientScan.modality) {
    const descriptionItems = [];
    if (patientScan.radiotracer) {
      descriptionItems.push(patientScan.radiotracer);
    }
    if (patientScan.modality) {
      descriptionItems.push(patientScan.modality);
    }
    label += `(${descriptionItems.join(" ")})`;
  }

  return label;
}

function discrepancyScanLabel(patientScan: PatientScan) {
  return i18n.t("common:discrepancyScanLabel", {
    firstScanRadiotracer: patientScan.metadata.firstScanRadiotracer,
    secondScanRadiotracer: patientScan.metadata.secondScanRadiotracer,
    createdAt:
      patientScan.created_at?.toLocaleString(DateTime.DATE_SHORT) ||
      i18n.t("common:notApplicable"),
  });
}

export function scanLabel(patientScan: PatientScan) {
  return patientScan.scan_type === "regular"
    ? regularScanLabel(patientScan)
    : discrepancyScanLabel(patientScan);
}

export function clinicalPatientScanLabel(patientScan: PatientScan) {
  let label = patientScan.date
    ? patientScan.date.toLocaleString(DateTime.DATE_SHORT)
    : patientScan.created_at?.toLocaleString(DateTime.DATE_SHORT) ||
      `#${patientScan.id || i18n.t("common:unknown")}`;

  if (patientScan.radiopharmaceutical || patientScan.modality) {
    const descriptionItems = [];
    if (patientScan.radiopharmaceutical) {
      descriptionItems.push(patientScan.radiopharmaceutical);
    }
    if (patientScan.modality) {
      descriptionItems.push(patientScan.modality);
    }
    label += ` (${descriptionItems.join(" ")})`;
  }

  return label;
}
