import * as React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import AuthLayout from "../AuthLayout";
import { AuthError } from "../../utils/errors";
import { resetTemporaryPassword } from "../../api/auth";
import { useAuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

function TemporaryPasswordResetPage() {
  const { t } = useTranslation(["auth", "errors"]);
  const { lastUserId, session, setSession, onSuccessfulAuth } =
    useAuthContext();
  const navigate = useNavigate();

  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      if (password === passwordConfirmation) {
        const response = await resetTemporaryPassword(
          lastUserId,
          password,
          session
        );
        onSuccessfulAuth(response);
        setSession(null);
        navigate("/", { replace: true });
      } else {
        throw new AuthError(t("errors:passwordsNotMatching"), [
          t("errors:passwordsNotMatching"),
        ]);
      }
    } catch (error) {
      if (error instanceof AuthError) {
        setErrorMessage(error.details);
      }
      console.error(error.message);
    }
  }

  const body = (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="password" className="mb-3">
        <Form.Label>{t("auth:newPassword")}</Form.Label>
        <Form.Control
          type="password"
          autoFocus={true}
          autoComplete="new-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required
          isInvalid={!!errorMessage}
        />
        {errorMessage && (
          <Form.Control.Feedback key="password-error-0" type="invalid">
            {errorMessage[0]}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group controlId="password_confirmation" className="mb-3">
        <Form.Label>{t("auth:confirmNewPassword")}</Form.Label>
        <Form.Control
          type="password"
          autoComplete="new-password"
          value={passwordConfirmation}
          onChange={(e) => {
            setPasswordConfirmation(e.target.value);
          }}
          required
          isInvalid={!!errorMessage}
        />
      </Form.Group>
      <Button variant="primary" type="submit" className="w-100">
        {t("common:submit")}
      </Button>
    </Form>
  );

  const links = <></>;

  return <AuthLayout title={t("resetPassword")} body={body} footer={links} />;
}

export default TemporaryPasswordResetPage;
