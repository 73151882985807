import regionName, {
  PELVIC_LYMPH_NODES_REGION_NAMES_SHORT,
  OTHER_LYMPH_NODES_REGION_NAMES_SHORT,
} from "../../../constants/regionNames";
import displayBoneRegionNames, {
  handleProstateRegions,
} from "../../../utils/regionNameUtils";
import {
  BoneMarkerInfo,
  Coordinates,
  EllipseCoordinates,
  OtherMarkerInfo,
  PatientScanMarkerInfo,
  PelvicMarkerInfo,
  TumorMarkerInfo,
} from "../../../api/patientScans";
import { suvMaxByRegion } from "../../../utils/svgUtils";
import i18n from "../../../i18n";

function tStageFindings(
  tumorMarkerInfo: TumorMarkerInfo,
  isProstateRemoved: boolean
) {
  const { markers, marker_count_by_region, extraprostatic_involvement } =
    tumorMarkerInfo;

  return isProstateRemoved
    ? prostateRemovedFindings(markers, marker_count_by_region)
    : prostateTumorFindings(
        markers,
        marker_count_by_region,
        extraprostatic_involvement
      );
}

function prostateTumorFindings(
  markers: { [key: string]: Coordinates | EllipseCoordinates },
  tumorCountByRegion: { [key: string]: number },
  extraprostaticInvolvement: string
) {
  if (Object.keys(tumorCountByRegion).length === 0) {
    return i18n.t("oncologicalFindings:noLocalLesions") + ".";
  }

  let result = i18n.t("oncologicalFindings:localLesion", {
    count: Object.keys(markers).length,
  });

  const lesionSuvMax = [];
  for (let marker in markers) {
    if (markers[marker].suvMax) {
      lesionSuvMax.push(markers[marker].suvMax);
    }
  }

  if (lesionSuvMax.length > 0) {
    result += ` (${i18n.t("common:SUVmax")} ${lesionSuvMax.join(", ")})`;
  }
  result += ".";

  result += " " + i18n.t("oncologicalFindings:prostateTumorHeader");

  result +=
    " " +
    Array.from(handleProstateRegions(Object.keys(tumorCountByRegion)))
      .map((area) => regionName(area))
      .join(", ") +
    ".";

  if (extraprostaticInvolvement.length > 0) {
    result +=
      " " +
      i18n.t("oncologicalFindings:extraprostaticInvolvement", {
        extraprostaticInvolvement,
      }) +
      ".";
  }

  return result;
}

function prostateRemovedFindings(
  markers: { [key: string]: Coordinates | EllipseCoordinates },
  tumorCountByRegion: {
    [key: string]: number;
  }
) {
  if (Object.keys(tumorCountByRegion).length === 0) {
    return `${i18n.t("oncologicalFindings:noLocalLesions")} ${i18n.t(
      "oncologicalFindings:prostateRemoved"
    )}.`;
  }

  let result = `${i18n.t(
    "oncologicalFindings:presenceOfLocalRecurrence"
  )} ${i18n.t("oncologicalFindings:prostateRemoved")}: `;

  const suv_max_by_region = suvMaxByRegion(markers);

  result += Object.keys(tumorCountByRegion)
    .map(
      (area) =>
        `${regionName(area)} (${tumorCountByRegion[area]}${
          suv_max_by_region[area]
            ? `, ${i18n.t("common:SUVmax")} ${suv_max_by_region[area].join(
                ", "
              )}`
            : ""
        })`
    )
    .join(", ");

  result += ".";
  return result;
}

function nStageFindings(pelvicMarkerInfo: PelvicMarkerInfo) {
  const {
    markers,
    marker_count_by_region,
    are_other_nodes_involved,
    other_nodes_involvement,
  } = pelvicMarkerInfo;

  const suv_max_by_region = suvMaxByRegion(markers);

  const filteredMetastasesCountByNode = Object.keys(
    marker_count_by_region
  ).filter((key) => PELVIC_LYMPH_NODES_REGION_NAMES_SHORT.has(key));

  if (filteredMetastasesCountByNode.length === 0 && !are_other_nodes_involved) {
    return `${i18n.t("oncologicalFindings:emptyNStage")}.`;
  }

  let result = i18n.t("oncologicalFindings:psmaPositive");

  if (filteredMetastasesCountByNode.length > 0) {
    result += " ";
    result += filteredMetastasesCountByNode
      .map(
        (nodeId) =>
          `${regionName(nodeId)} (${marker_count_by_region[nodeId]}${
            suv_max_by_region[nodeId]
              ? `, ${i18n.t("common:SUVmax")} ${suv_max_by_region[nodeId].join(
                  ", "
                )}`
              : ""
          })`
      )
      .join(", ");
    result += ` ${i18n.t("common:pelvic")} ${i18n.t("common:lymphNode", {
      count: filteredMetastasesCountByNode.length,
    })}`;
  }

  if (filteredMetastasesCountByNode.length > 0 && are_other_nodes_involved) {
    result += ` ${i18n.t("common:and")}`;
  }

  if (are_other_nodes_involved) {
    result += ` ${i18n.t("common:other")} (${other_nodes_involvement}) ${i18n.t(
      "common:lymphNode",
      { count: 2 } // To ensure it's plural
    )}`;
  }

  result += ".";

  return result;
}

function mStageFindings(
  boneMarkerInfo: BoneMarkerInfo,
  otherMarkerInfo: OtherMarkerInfo,
  pelvicMarkerInfo: PelvicMarkerInfo
) {
  const otherOrganMetastasesResult = otherOrganMetastasesFindings(
    otherMarkerInfo,
    pelvicMarkerInfo
  );
  const boneMetastasesResult = boneMetastasesFindings(boneMarkerInfo);

  if (otherOrganMetastasesResult === "" && boneMetastasesResult === "") {
    return i18n.t("oncologicalFindings:emptyMStage");
  }

  let result = "";

  if (otherOrganMetastasesResult !== "") {
    result += otherOrganMetastasesResult;
  }

  if (otherOrganMetastasesResult !== "" && boneMetastasesResult !== "") {
    result += " ";
  }

  if (boneMetastasesResult !== "") {
    result += boneMetastasesResult;
  }

  return result;
}

function otherOrganMetastasesFindings(
  otherMarkerInfo: OtherMarkerInfo,
  pelvicMarkerInfo: PelvicMarkerInfo
) {
  let result = "";

  const {
    markers: other_markers,
    marker_count_by_lymph_node,
    marker_count_by_organ,
    are_other_organs_involved,
    other_organ_involvement,
  } = otherMarkerInfo;

  const other_suv_max_by_region = suvMaxByRegion(other_markers);

  const {
    markers: pelvic_markers,
    marker_count_by_region: pelvic_marker_count_by_region,
  } = pelvicMarkerInfo;

  const pelvic_suv_max_by_region = suvMaxByRegion(pelvic_markers);
  const suv_max_by_region = {
    ...other_suv_max_by_region,
    ...pelvic_suv_max_by_region,
  };

  const filteredMetastasesCountByLymphNode = Object.keys(
    marker_count_by_lymph_node
  )
    .concat(Object.keys(pelvic_marker_count_by_region))
    .filter((key) => OTHER_LYMPH_NODES_REGION_NAMES_SHORT.has(key));

  if (
    filteredMetastasesCountByLymphNode.length > 0 ||
    Object.keys(marker_count_by_organ).length > 0 ||
    are_other_organs_involved
  ) {
    result += i18n.t("oncologicalFindings:psmaPositive");
  } else {
    return result;
  }

  if (filteredMetastasesCountByLymphNode.length > 0) {
    result += " ";
    result += filteredMetastasesCountByLymphNode
      .map(
        (lymphNodeId) =>
          `${regionName(lymphNodeId)} (${
            marker_count_by_lymph_node[lymphNodeId] ||
            pelvic_marker_count_by_region[lymphNodeId]
          }${
            suv_max_by_region[lymphNodeId]
              ? `, ${i18n.t("common:SUVmax")} ${suv_max_by_region[
                  lymphNodeId
                ].join(", ")}`
              : ""
          })`
      )
      .join(", ");
    result += i18n.t("common:lymphNode", {
      count: filteredMetastasesCountByLymphNode.length,
    });
  }

  if (
    filteredMetastasesCountByLymphNode.length > 0 &&
    (Object.keys(marker_count_by_organ).length > 0 || are_other_organs_involved)
  ) {
    result += ", ";
  }

  const displayOrganMetastases = Object.keys(marker_count_by_organ).map(
    (otherOrganId) =>
      `${regionName(otherOrganId)} (${marker_count_by_organ[otherOrganId]}${
        suv_max_by_region[otherOrganId]
          ? `, ${i18n.t("common:SUVmax")} ${suv_max_by_region[
              otherOrganId
            ].join(", ")}`
          : ""
      })`
  );

  if (displayOrganMetastases.length > 0) {
    result += ` ${displayOrganMetastases}`;
  }

  if (
    are_other_organs_involved &&
    (filteredMetastasesCountByLymphNode.length > 0 ||
      Object.keys(marker_count_by_organ).length > 0)
  ) {
    result += ` ${i18n.t("common:and")}`;
  }

  if (are_other_organs_involved) {
    result += ` ${i18n.t("oncologicalFindings:otherOrgansOrLymphNodes")}`;
  }

  result += ".";

  return result;
}

function boneMetastasesFindings(boneMarkerInfo: BoneMarkerInfo) {
  let result = "";

  const { markers, marker_count_by_region, marker_count, is_dmi } =
    boneMarkerInfo;

  if (is_dmi || marker_count > 0) {
    result += i18n.t("oncologicalFindings:psmaPositive");
  } else {
    return result;
  }

  if (is_dmi) {
    result += ` ${i18n.t("common:dmi")}.`;
  }

  if (marker_count > 0) {
    const suv_max_by_region = suvMaxByRegion(markers);
    result += ` ${i18n.t("oncologicalFindings:osseousLesions")} `;
    result += displayBoneRegionNames(
      Object.keys(marker_count_by_region).map(
        (regionId) =>
          `${regionName(regionId)} (${marker_count_by_region[regionId]}${
            suv_max_by_region[regionId]
              ? `, ${i18n.t("common:SUVmax")} ${suv_max_by_region[
                  regionId
                ].join(", ")}`
              : ""
          })`
      )
    );
    result += ".";
  }

  return result;
}

export default function useOncologicalFindings(
  markerInfo: PatientScanMarkerInfo,
  isProstateRemoved: boolean
): [string, string, string] {
  const {
    pelvic_lymph_node_metastases,
    prostate_tumor,
    bone_metastases,
    other_organ_metastases,
  } = markerInfo;

  return [
    `${i18n.t("oncologicalFindings:tStage")}: ${tStageFindings(
      prostate_tumor,
      isProstateRemoved
    )}`,
    `${i18n.t("oncologicalFindings:nStage")}: ${nStageFindings(
      pelvic_lymph_node_metastases
    )}`,
    `${i18n.t("oncologicalFindings:mStage")}: ${mStageFindings(
      bone_metastases,
      other_organ_metastases,
      pelvic_lymph_node_metastases
    )}`,
  ];
}
