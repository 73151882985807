import { CognitoJwtVerifier } from "aws-jwt-verify";
import { AuthError, JWTVerificationError } from "../utils/errors";
import i18n from "../i18n";

const accessTokenVerifier = CognitoJwtVerifier.create({
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  tokenUse: "access",
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
});

const idTokenVerifier = CognitoJwtVerifier.create({
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  tokenUse: "id",
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
});

export async function verifyAccessToken(accessToken) {
  return await accessTokenVerifier.verify(accessToken);
}

export async function refreshTokenSignIn(username, refreshToken) {
  const response = await fetch("/api/users/sign_in", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      refresh_token: refreshToken,
    }),
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    switch (response.status) {
      case 400:
      case 401:
      case 404:
        throw new AuthError(i18n.t("errors:cannotSignIn"), jsonResponse.errors);
      case 403:
        // This is a case for non-verified user
        // TODO: determine whether the user has to
        // Confirm email or reset a password, and redirect them
        throw new Error(
          i18n.t("errors:flowNotImplemented"),
          jsonResponse.errors
        );
      default:
        throw new Error(
          `${i18n.t("errors:genericError")}: ${response.status}`,
          jsonResponse.errors
        );
    }
  }

  return await parseSignInResponse(jsonResponse);
}

export async function signIn(username, password) {
  const response = await fetch("/api/users/sign_in", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    switch (response.status) {
      case 400:
      case 401:
      case 404:
        throw new AuthError(i18n.t("errors:cannotSignIn"), jsonResponse.errors);
      case 403:
        // This is a case for non-verified user
        // TODO: determine whether the user has to
        // Confirm email or reset a password, and redirect them
        throw new Error(
          i18n.t("errors:flowNotImplemented"),
          jsonResponse.errors
        );
      default:
        throw new Error(
          `${i18n.t("errors:genericError")}: ${response.status}`,
          jsonResponse.errors
        );
    }
  }

  return await parseSignInResponse(jsonResponse);
}

async function parseSignInResponse(jsonResponse) {
  if (jsonResponse.hasOwnProperty("authentication_result")) {
    // Successful auth - parse the id token
    try {
      const authenticationResult = jsonResponse["authentication_result"];
      const userData = await idTokenVerifier.verify(
        authenticationResult["id_token"]
      );
      return {
        current_user: {
          ...jsonResponse["current_user"],
          tokenDetails: userData,
        },
        access_token: authenticationResult["access_token"],
        refresh_token: authenticationResult["refresh_token"],
      };
    } catch {
      throw new JWTVerificationError(i18n.t("errors:cannotVerifyToken"));
    }
  } else {
    // Cognito sent a challenge. Pass the info to the callback
    return jsonResponse;
  }
}

export async function signOut(accessToken) {
  const response = await fetch("/api/users/sign_out", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    switch (response.status) {
      case 400:
      case 401:
        throw new AuthError(
          i18n.t("errors:refreshTokenNotRevoked"),
          jsonResponse.errors
        );
      case 403:
        // This is a case for non-verified user
        // TODO: determine whether the user has to
        // Confirm email or reset a password, and redirect them
        throw new Error(
          i18n.t("errors:flowNotImplemented"),
          jsonResponse.errors
        );
      default:
        throw new Error(
          `${i18n.t("errors:genericError")}: ${response.status}`,
          jsonResponse.errors
        );
    }
  }
}

export async function signUp(
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation,
  orgName
) {
  const response = await fetch("/api/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      password_confirmation: passwordConfirmation,
      org_name: orgName,
    }),
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    switch (response.status) {
      case 400:
        throw new AuthError(i18n.t("errors:cannotSignUp"), jsonResponse.errors);
      default:
        throw new Error(
          `${i18n.t("errors:genericError")}: ${response.status}`,
          jsonResponse.errors
        );
    }
  }

  return jsonResponse;
}

export async function confirmSignUp(userId, confirmationCode) {
  const response = await fetch("/api/users/confirm", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: userId,
      confirmation_code: confirmationCode,
    }),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    switch (response.status) {
      case 400:
      case 403:
      case 404:
        throw new AuthError(i18n.t("errors:cannotSignUp"), jsonResponse.errors);
      default:
        throw new Error(
          `${i18n.t("errors:genericError")}: ${response.status}`,
          jsonResponse.errors
        );
    }
  }
}

export async function sendConfirmationInstructions(username) {
  const response = await fetch("/api/users/confirm", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
    }),
  });

  if (!response.ok) {
    const jsonResponse = await response.json();
    switch (response.status) {
      case 400:
      case 404:
        throw new AuthError(
          i18n.t("errors:cannotSendConfirmationInstructions"),
          jsonResponse.errors
        );
      default:
        throw new Error(`${i18n.t("errors:genericError")}: ${response.status}`);
    }
  }
}

export async function requestPasswordReset(username) {
  const response = await fetch("/api/users/forgot_password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
    }),
  });

  if (!response.ok) {
    const jsonResponse = await response.json();
    switch (response.status) {
      case 400:
      case 404:
        throw new AuthError(
          i18n.t("errors:cannotSendConfirmationInstructions"),
          jsonResponse.errors
        );
      default:
        throw new Error(`${i18n.t("errors:genericError")}: ${response.status}`);
    }
  }
}

export async function confirmPasswordReset(
  userId,
  password,
  passwordConfirmation,
  confirmationCode
) {
  const response = await fetch("/api/users/forgot_password/confirm", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: userId,
      password,
      password_confirmation: passwordConfirmation,
      confirmation_code: confirmationCode,
    }),
  });

  if (!response.ok) {
    const jsonResponse = await response.json();

    switch (response.status) {
      case 400:
      case 401:
      case 404:
        throw new AuthError(
          i18n.t("errors:cannotResetPassword"),
          jsonResponse.errors
        );
      case 403:
        // This is a case for non-verified user
        // TODO: determine whether the user has to
        // Confirm email or reset a password, and redirect them
        throw new Error(
          i18n.t("errors:flowNotImplemented"),
          jsonResponse.errors
        );
      default:
        throw new Error(
          `${i18n.t("errors:genericError")}: ${response.status}`,
          jsonResponse.errors
        );
    }
  }
}

export async function inviteUser(accessToken, firstName, lastName, email) {
  const response = await fetch("/api/users/invite", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email,
    }),
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    switch (response.status) {
      case 400:
        throw new AuthError(
          i18n.t("errors:cannotInviteUser"),
          jsonResponse.errors
        );
      default:
        throw new Error(
          `${i18n.t("errors:genericError")}: ${response.status}`,
          jsonResponse.errors
        );
    }
  }
}

export async function resetTemporaryPassword(username, password, session) {
  const response = await fetch("/api/users/reset_password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      password,
      session,
    }),
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    switch (response.status) {
      case 400:
      case 401:
      case 404:
        throw new AuthError(
          i18n.t("errors:cannotResetPassword"),
          jsonResponse.errors
        );
      case 403:
        // This is a case for non-verified user
        // TODO: determine whether the user has to
        // Confirm email or reset a password, and redirect them
        throw new Error(
          i18n.t("errors:flowNotImplemented"),
          jsonResponse.errors
        );
      default:
        throw new Error(
          `${i18n.t("errors:genericError")}: ${response.status}`,
          jsonResponse.errors
        );
    }
  }

  return await parseSignInResponse(jsonResponse);
}
