import { DateTime } from "luxon";
import * as React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  createTestResult,
  deleteTestResult,
  getTestResultsForPatient,
  initialTestResult,
  TestResult,
  updateTestResult,
} from "../../api/testResults";
import { useAuthContext } from "../../contexts/AuthContext";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientsContext } from "../../contexts/PatientsContext";
import TestResultRowEdit from "./TestResultRowEdit";
import TestResultRow from "./TestResultRow";
import { calculatePSADoublingTime } from "../../utils/psaValueUtils";
import { useTranslation } from "react-i18next";

interface LabTestsProps {}

export default function LabTests(props: LabTestsProps) {
  const { getAccessToken } = useAuthContext();
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan } = usePatientScansContext();

  const [psaValues, setPSAValues] = React.useState<TestResult[]>([]);
  const [psaValuesEditMode, setPSAValuesEditMode] = React.useState<boolean[]>(
    []
  );
  const [psaDoublingTime, setPSADoublingTime] = React.useState<
    number | undefined
  >();
  const { t } = useTranslation("labTests");

  function sortPSAValues(newPSAValues: TestResult[], newEditMode: boolean[]) {
    const combinedPSAValues = newPSAValues.map((psaValue, index) => ({
      psaValue: psaValue,
      editMode: newEditMode[index],
    }));

    combinedPSAValues.sort(
      (a, b) => b.psaValue.taken_at.diff(a.psaValue.taken_at).milliseconds
    );

    setPSAValues(combinedPSAValues.map((cv) => cv.psaValue));
    setPSAValuesEditMode(combinedPSAValues.map((cv) => cv.editMode));
    setPSADoublingTime(
      calculatePSADoublingTime(combinedPSAValues.map((cv) => cv.psaValue))
    );
  }

  function setPSAValuesEditModeAtIndex(
    psaValueIndex: number,
    newValue: boolean
  ) {
    setPSAValuesEditMode(
      psaValuesEditMode.map((flag, index) => {
        return index === psaValueIndex ? newValue : flag;
      })
    );
  }

  // Fetch all existing PSA Values
  React.useEffect(() => {
    (async () => {
      if (!currentPatient.id) {
        console.warn("Current patient is not defined");
        return;
      }
      try {
        const accessToken = await getAccessToken();
        const response = await getTestResultsForPatient(
          accessToken,
          currentPatient.id
        );
        const newPSAValues = response.filter(
          (testResult) => testResult.test_name === "PSA"
        );
        sortPSAValues(newPSAValues, new Array(newPSAValues.length).fill(false));
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPatient.id]);

  function addPSAValue() {
    if (!currentPatient.id) {
      console.warn("Current patient is not defiend");
    }
    sortPSAValues(
      psaValues.concat({
        ...initialTestResult("PSA", 0, DateTime.now()),
        patient_id: currentPatient.id,
      }),
      psaValuesEditMode.concat(true)
    );
  }

  async function updatePSAValueAtIndex(
    psaValueIndex: number,
    newPSAValue: TestResult
  ) {
    if (!currentPatient.id) {
      console.warn("Current patient is not defiend");
      return;
    }

    const accessToken = await getAccessToken();

    if (newPSAValue.id) {
      await updateTestResult(
        accessToken,
        currentPatient.id,
        newPSAValue.id,
        newPSAValue
      );
    } else {
      const response = await createTestResult(
        accessToken,
        currentPatient.id,
        newPSAValue
      );
      newPSAValue.id = response.id;
    }

    sortPSAValues(
      psaValues.map((v, i) => (i === psaValueIndex ? newPSAValue : v)),
      psaValuesEditMode.map((flag, index) => {
        return index === psaValueIndex ? false : flag;
      })
    );
  }

  async function deletePSAValueAtIndex(index: number) {
    if (!currentPatient.id) {
      console.warn("Current patient is not defined");
      return;
    }

    const psaValue = psaValues[index];

    if (psaValue.id) {
      try {
        // PSA Value is saved to DB - need to remove it as well
        const accessToken = await getAccessToken();
        await deleteTestResult(accessToken, currentPatient.id, psaValue.id);
      } catch (e) {
        console.error(e);
        return;
      }
    }

    setPSAValues(psaValues.filter((_, i) => i !== index));
    setPSAValuesEditMode(psaValuesEditMode.filter((_, i) => i !== index));
    setPSADoublingTime(
      calculatePSADoublingTime(psaValues.filter((_, i) => i !== index))
    );
  }

  return (
    <>
      <Row className="mt-5">
        <Col>
          <h5>{t("header")}</h5>
        </Col>
      </Row>
      {/* Header */}
      {psaValues.length === 0 ? (
        <p>{t("emptyPlaceholder")}</p>
      ) : (
        <Row>
          <Form.Group as={Col} xs="6">
            <Form.Label className="mb-0">{t("psaValue")}</Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs="2">
            <Form.Label className="mb-0">{t("testDate")}</Form.Label>
          </Form.Group>
        </Row>
      )}
      <Form>
        {psaValues.map((psaValue, index) =>
          psaValuesEditMode[index] ? (
            <TestResultRowEdit
              key={index}
              showTestName={false}
              testResult={psaValue}
              onUpdateTestResult={(newTestResult) => {
                updatePSAValueAtIndex(index, newTestResult);
              }}
              onDiscardChanges={() => {
                setPSAValuesEditModeAtIndex(index, false);
              }}
            />
          ) : (
            <TestResultRow
              key={index}
              showTestName={false}
              // We consider the PSA value relevant to current scan if it is taken within 7 days
              highlighted={
                currentPatientScan?.date != null &&
                Math.abs(
                  psaValue.taken_at.diff(currentPatientScan.date, "days").days
                ) < 7
              }
              testResult={psaValue}
              onDeleteTestResult={() => {
                deletePSAValueAtIndex(index);
              }}
              onEditTestResult={() => {
                setPSAValuesEditModeAtIndex(index, true);
              }}
            />
          )
        )}
      </Form>
      <Row className="mb-3">
        <Col xs="6">
          <Button
            disabled={!currentPatient.id}
            variant="outline-primary"
            onClick={() => {
              addPSAValue();
            }}
          >
            {t("addPSAValueButton")}
          </Button>
        </Col>
        <Col xs="4" className="d-flex align-items-center">
          <h6 className="mb-0">
            {psaDoublingTime
              ? t("psaDoublingTime", { time: psaDoublingTime })
              : "N/A"}
          </h6>
        </Col>
      </Row>
    </>
  );
}
