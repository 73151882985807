import * as React from "react";
import { Container } from "react-bootstrap";

import MiTNM from "../MiTNM";
import LogToast from "../LogToast";

import { useOrgContext } from "../../contexts/OrgContext";

import "./MiTNMTransitions.css";
import HomePageClinicLayoutV2 from "./HomePageClinicLayoutV2";
import HomePageResearchLayout from "./HomePageResearchLayout";

function HomePage() {
  const { currentOrg } = useOrgContext();

  let layout = <></>;
  if (currentOrg) {
    switch (currentOrg.product) {
      case "basic":
        layout = <MiTNM />;
        break;
      case "clinic":
        layout = <HomePageClinicLayoutV2 />;
        break;
      case "research":
        layout = <HomePageResearchLayout />;
        break;
      default:
        layout = <></>;
    }
  }

  return (
    <Container fluid style={{ flexDirection: "column" }}>
      <LogToast />
      {layout}
    </Container>
  );
}

export default HomePage;
