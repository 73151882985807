import React from "react";
import {
  Patient,
  createPatient,
  getPatientById,
  initialPatient,
  updatePatient,
} from "../api/patients";
import { useAuthContext } from "./AuthContext";
import { useCurrentPatientId } from "../utils/routerUtils";
import { PatientTreatment } from "../api/patientTreatments";
import usePatientTreatments from "../components/ClinicalData/usePatientTreatments";
import { TestResult } from "../api/testResults";
import { Collection } from "../constants/enums";
import usePatientTestResults from "../components/ClinicalData/usePatientTestResults";

interface CurrentPatientContextType {
  loading: boolean;
  saved: boolean;
  currentPatient: Patient;
  setCurrentPatient: React.Dispatch<React.SetStateAction<Patient>>;
  saveCurrentPatient: () => Promise<Patient>;
  discardCurrentPatientChanges: () => void;
  treatments: Collection<PatientTreatment>;
  testResults: Collection<TestResult>;
}

const CurrentPatientContext = React.createContext<CurrentPatientContextType>({
  loading: false,
  saved: true,
  currentPatient: initialPatient(""),
  setCurrentPatient: () => {},
  saveCurrentPatient: async () => initialPatient(""),
  discardCurrentPatientChanges: () => {},
  treatments: {
    list: [],
    updateAtIndex: () => {},
    deleteAtIndex: () => {},
    add: () => {},
    editAtIndex: () => {},
  },
  testResults: {
    list: [],
    updateAtIndex: () => {},
    deleteAtIndex: () => {},
    add: () => {},
    editAtIndex: () => {},
  },
});

interface CurrentPatientContextProviderProps {
  children?: React.ReactNode;
}

export function CurrentPatientContextProvider({
  children,
}: CurrentPatientContextProviderProps) {
  const { getAccessToken } = useAuthContext();
  const currentPatientId = useCurrentPatientId();

  const [loading, setLoading] = React.useState(false);
  const [saved, setSaved] = React.useState(true);
  const [currentPatient, setCurrentPatient] = React.useState(
    initialPatient("")
  );

  const {
    list: treatmentList,
    updateAtIndex: updateTreatmentAtIndex,
    deleteAtIndex: deleteTreatmentAtIndex,
    add: addTreatment,
    editAtIndex: editTreatmentAtIndex,
    save: saveTreatments,
    discardChanges: discardTreatmentChanges,
  } = usePatientTreatments(currentPatientId);

  const {
    list: testResultList,
    updateAtIndex: updateTestResultAtIndex,
    deleteAtIndex: deleteTestResultAtIndex,
    add: addTestResult,
    editAtIndex: editTestResultAtIndex,
    save: saveTestResults,
    discardChanges: discardTestResultChanges,
  } = usePatientTestResults(currentPatientId);

  const fetchPatient = React.useCallback(
    async (patientId: number | null) => {
      setLoading(true);
      setSaved(true);
      if (patientId == null) {
        setCurrentPatient(initialPatient(""));
        setLoading(false);
        return;
      }

      const accessToken = await getAccessToken();
      // TODO: handle 404 case
      const patient = await getPatientById(accessToken, patientId);
      setCurrentPatient(patient);
      setLoading(false);
    },
    [getAccessToken]
  );

  React.useEffect(() => {
    fetchPatient(currentPatientId);
  }, [currentPatientId, fetchPatient]);

  async function saveCurrentPatient() {
    if (saved) {
      console.log("The patient is already saved, nothing to do");
      return currentPatient;
    }
    const accessToken = await getAccessToken();
    // Create / update patient, then patient treatments
    if (currentPatient.id === null) {
      const newPatient = await createPatient(accessToken, currentPatient);
      if (newPatient.id !== null) {
        await saveTreatments(newPatient.id);
        await saveTestResults(newPatient.id);
      } else {
        console.warn("Patient ID is missing");
      }
      return newPatient;
    } else {
      await updatePatient(accessToken, currentPatient.id, currentPatient);
      await saveTreatments(currentPatient.id);
      await saveTestResults(currentPatient.id);
      return currentPatient;
    }
  }

  async function discardCurrentPatientChanges() {
    await fetchPatient(currentPatientId);
    await discardTreatmentChanges();
    await discardTestResultChanges();
  }

  return (
    <CurrentPatientContext.Provider
      value={{
        loading,
        saved,
        currentPatient,
        setCurrentPatient,
        saveCurrentPatient,
        discardCurrentPatientChanges,
        treatments: {
          list: treatmentList,
          updateAtIndex: updateTreatmentAtIndex,
          deleteAtIndex: deleteTreatmentAtIndex,
          add: addTreatment,
          editAtIndex: editTreatmentAtIndex,
        },
        // TODO: Change testResults once the implementation is finished
        testResults: {
          list: testResultList,
          updateAtIndex: updateTestResultAtIndex,
          deleteAtIndex: deleteTestResultAtIndex,
          add: addTestResult,
          editAtIndex: editTestResultAtIndex,
        },
      }}
    >
      {children}
    </CurrentPatientContext.Provider>
  );
}

export const useCurrentPatientContext = () =>
  React.useContext(CurrentPatientContext);
