import { DateTime } from "luxon";
import { Col, Form, Row } from "react-bootstrap";
import { GleasonScore, gleasonScores } from "../../api/patients";
import { ClinicalIndication } from "../../constants/enums";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientsContext } from "../../contexts/PatientsContext";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";

function InitialDiagnosis() {
  const { currentPatient, setCurrentPatient } = usePatientsContext();
  const { currentPatientScan } = usePatientScansContext();
  const { t } = useTranslation("patient");

  const largeSpan =
    currentPatientScan.clinical_indication === ClinicalIndication.RESTAGING
      ? 3
      : 4;

  return (
    <Form style={{ marginTop: 12 }}>
      <Row className="mb-3">
        <Form.Group as={Col} lg={largeSpan} md={6} sm={12}>
          <Form.Label className="mb-0">{t("initialPsaValue")}</Form.Label>
          <Form.Control
            type="number"
            step="0.01"
            disabled={!currentPatient.id}
            value={currentPatient.initial_psa_value || ""}
            onChange={(e) => {
              setCurrentPatient({
                ...currentPatient,
                initial_psa_value: parseFloat(e.target.value) || null,
              });
            }}
          />
        </Form.Group>
        <Form.Group as={Col} lg={largeSpan} md={6} sm={12}>
          <Form.Label className="mb-0">{t("diagnosisDate")}</Form.Label>
          <DatePicker
            disabled={!currentPatient.id}
            className="form-control"
            value={
              currentPatient.diagnosis_date &&
              currentPatient.diagnosis_date.toJSDate()
            }
            onChange={(value: Date | null) => {
              setCurrentPatient({
                ...currentPatient,
                diagnosis_date: value && DateTime.fromJSDate(value),
              });
            }}
          />
        </Form.Group>
        <Form.Group as={Col} lg={largeSpan} md={6} sm={12}>
          <Form.Label className="mb-0">{t("gleasonScore")}</Form.Label>
          <Form.Select
            disabled={!currentPatient.id}
            value={currentPatient.gleason_score || "n/a"}
            onChange={(e) => {
              setCurrentPatient({
                ...currentPatient,
                gleason_score: e.target.value as GleasonScore,
              });
            }}
          >
            <option value="n/a" disabled>
              {t("gleasonScorePlaceholder")}
            </option>
            {gleasonScores.map((score) => (
              <option value={score} key={score}>
                {score}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {currentPatientScan.clinical_indication ===
          ClinicalIndication.RESTAGING && (
          <Form.Group as={Col} lg={largeSpan} md={6} sm={12}>
            <Form.Label className="mb-0">{t("ctnm")}</Form.Label>
            <Form.Control
              disabled={!currentPatient.id}
              type="text"
              value={currentPatient.c_tnm || ""}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  c_tnm: e.target.value,
                });
              }}
            />
          </Form.Group>
        )}
      </Row>
    </Form>
  );
}

export default InitialDiagnosis;
