import { Row, Col, Button } from "react-bootstrap";
import { useOrgContext } from "../contexts/OrgContext";
import SaveButton from "./MiTNM/SaveButton";
import TNMCodeLine from "./MiTNM/TNMCodeLine";
import { usePatientsContext } from "../contexts/PatientsContext";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import {
  DISAPPEARED_LESION_COLOR,
  NEW_LESION_COLOR,
  REMAINING_LESION_COLOR,
} from "../utils/clinicDiscrepancyUtils";
import useLuPSMAEligibility from "./ImagingFindings/useLuPSMAEligibility";
import useRecipCriteria from "./RecipCriteria/useRecipCriteria";
import { usePatientScansContext } from "../contexts/PatientScansContext";
import { usePatientRecipCriteriaContext } from "../contexts/PatientRecipCriteriaContext";
import { Section } from "./Sidebar/ClinicSidebar";
import { useMemo } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { MiTNMSectionName, MiTNMSectionsArray } from "../constants/enums";
import { useTranslation } from "react-i18next";

export default function MiTNMFooter() {
  const { t } = useTranslation(["sections", "common", "lesionTracking"]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { currentOrg } = useOrgContext();
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan } = usePatientScansContext();
  const { currentPatientScanRecipCriteria } = usePatientRecipCriteriaContext();
  const { criteriaApplicable: luPSMACriteriaApplicable } = useLuPSMAEligibility(
    currentPatient,
    currentPatientScan
  );
  const { criteriaApplicable: recipCriteriaApplicable } = useRecipCriteria(
    currentPatientScan,
    currentPatientScanRecipCriteria
  );

  const FLATTENED_SECTIONS: Section[] = [
    {
      link: "/home/patient_info",
      label: t("patientInformation"),
      display: currentOrg?.product === "clinic",
    },
    {
      link: "/home/clinical_data",
      label: t("clinicalData"),
      display: currentOrg?.product === "clinic",
    },
    {
      link: "/home/technical_params",
      label: t("technicalParams"),
      display: currentOrg?.product === "clinic",
    },
    {
      link: "/home/imaging_findings/descriptive_findings",
      label: t("descriptiveFindings"),
      display: currentOrg?.product === "clinic",
    },
    ...MiTNMSectionsArray.map(
      (section) =>
        ({
          link: `/home/imaging_findings/tnm_classification/${section}`,
          label: `${t("tnmClassification")} - ${MiTNMSectionName(section)}`,
        } as Section)
    ),
    {
      link: "/home/imaging_findings/quantitative_parameters",
      label: t("quantitativeParams"),
      display: currentOrg?.product === "clinic",
    },
    ...MiTNMSectionsArray.map(
      (section) =>
        ({
          link: `/home/imaging_findings/lesion_tracking/${section}`,
          label: `${t("lesionTracking")} - ${MiTNMSectionName(section)}`,
          display: currentOrg?.product === "clinic",
        } as Section)
    ),
    {
      link: "/home/imaging_findings/recip_criteria",
      label: t("recipCriteria"),
      display: currentOrg?.product === "clinic" && recipCriteriaApplicable,
    },
    {
      link: "/home/imaging_findings/lupsma_eligibility",
      label: t("lupsmaEligibility"),
      display: currentOrg?.product === "clinic" && luPSMACriteriaApplicable,
    },
  ].filter((section) => section.display == null || section?.display === true);

  const sectionIndex = FLATTENED_SECTIONS.findIndex(
    (section: Section) => matchPath(section.link, pathname) != null
  );

  const isMiTNM =
    matchPath(
      "/home/imaging_findings/tnm_classification/:mitnmSection",
      pathname
    ) != null;

  const isLesionTracking =
    matchPath(
      "/home/imaging_findings/lesion_tracking/:mitnmSection",
      pathname
    ) != null;

  function onNextClick() {
    if (sectionIndex === FLATTENED_SECTIONS.length - 1) {
      console.warn(
        "Cannot move to the next section, the button should be disabled"
      );
      return true;
    }
    navigate(FLATTENED_SECTIONS[sectionIndex + 1].link);
    return currentOrg?.product === "research" ? false : true;
  }

  function onPreviousClick() {
    if (sectionIndex === 0) {
      console.warn(
        "Cannot move to the previous section, the button should be disabled"
      );
      return;
    }
    navigate(FLATTENED_SECTIONS[sectionIndex - 1].link);
  }

  const saveButtonLabel = useMemo(() => {
    if (currentOrg?.product === "research") {
      return t("common:nextStep");
    }
    return sectionIndex === FLATTENED_SECTIONS.length - 1
      ? t("common:save")
      : t("common:next");
  }, [t, FLATTENED_SECTIONS.length, currentOrg?.product, sectionIndex]);

  const savebuttonDisabled = useMemo(() => {
    return currentPatient.id === null;
  }, [currentPatient.id]);

  return (
    <GlobalHotKeys
      handlers={{
        PREVIOUS_STEP: onPreviousClick,
        NEXT_STEP: onNextClick,
      }}
      allowChanges
    >
      <Row>
        <Col className="d-flex flex-row justify-content-between flex-nowrap pb-2">
          <Button
            variant="outline-secondary"
            disabled={sectionIndex === 0}
            style={{ minWidth: 100, minHeight: 41 }}
            onClick={onPreviousClick}
          >
            {t("common:previous")}
          </Button>

          {isMiTNM && <TNMCodeLine />}

          {isLesionTracking && (
            <div className="d-flex flex-row justify-content-evenly w-100 flex-nowrap">
              <div className="d-flex flex-row  align-items-center">
                <div
                  className="ms-auto me-2"
                  style={{
                    color: NEW_LESION_COLOR,
                    backgroundColor: NEW_LESION_COLOR,
                    height: "1.5rem",
                    width: "1.5rem",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{t("lesionTracking:newLesion")}</span>
              </div>
              <div className="d-flex flex-row  align-items-center">
                <div
                  className="ms-auto me-2"
                  style={{
                    color: DISAPPEARED_LESION_COLOR,
                    backgroundColor: DISAPPEARED_LESION_COLOR,
                    height: "1.5rem",
                    width: "1.5rem",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{t("lesionTracking:disappearedLesion")}</span>
              </div>
              <div className="d-flex flex-row  align-items-center">
                <div
                  className="ms-auto me-2"
                  style={{
                    color: REMAINING_LESION_COLOR,
                    backgroundColor: REMAINING_LESION_COLOR,
                    height: "1.5rem",
                    width: "1.5rem",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{t("lesionTracking:remainedLesion")}</span>
              </div>
            </div>
          )}

          <SaveButton
            style={{ minWidth: 100 }}
            beforeSave={onNextClick}
            label={saveButtonLabel}
            disabled={savebuttonDisabled}
          />
        </Col>
      </Row>
    </GlobalHotKeys>
  );
}
