import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { AppSettingsContextProvider } from "./contexts/AppSettingsContext";
import { OrgContextProvider } from "./contexts/OrgContext";
import { AuthContextProvider } from "./contexts/AuthContext";
import { LogContextProvider } from "./components/LogToast/LogContext";
import router from "./router";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <AuthContextProvider>
    <OrgContextProvider>
      <AppSettingsContextProvider>
        <LogContextProvider>
          <RouterProvider router={router} />
        </LogContextProvider>
      </AppSettingsContextProvider>
    </OrgContextProvider>
  </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
