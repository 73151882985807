import * as React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import BootstrapIcon from "../../BootstrapIcon";
import { useLogContext } from "../../LogToast/LogContext";
import { Patient } from "../../../api/patients";
import { PatientScan } from "../../../api/patientScans";
import { useAuthContext } from "../../../contexts/AuthContext";
import useLuPSMAEligibility from "../../ImagingFindings/useLuPSMAEligibility";
import { getTreatmentsForPatient } from "../../../api/patientTreatments";
import { getTestResultsForPatient } from "../../../api/testResults";
import {
  getClinicalInformation,
  getDescriptiveFindings,
  getTechnicalInformation,
} from "./clinicalReportUtils";
import { Spinner } from "react-bootstrap";
import { PatientRecipCriteria } from "../../../api/patientRecipCriteria";
import useRecipCriteria from "../../RecipCriteria/useRecipCriteria";
import { getPatientScanDescriptiveFindings } from "../../../api/patientScanDescriptiveFindings";
import { blobToDataURL, drawSVGGridOnCanvas } from "../../../utils/imageUtils";
import { GlobalHotKeys } from "react-hotkeys";
import { useTranslation } from "react-i18next";

interface CopyClinicalReportButtonProps {
  patient: Patient;
  patientScan: PatientScan;
  patientRecipCriteria: PatientRecipCriteria | null;
  tnmCodeLine: string;
  oncologicalFindings: [string, string, string];
  images: Blob[];
  labels: string[];
}

const CopyClinicalReportButton = ({
  patient,
  patientScan,
  patientRecipCriteria,
  tnmCodeLine,
  oncologicalFindings,
  images,
  labels,
}: CopyClinicalReportButtonProps) => {
  const { t } = useTranslation([
    "clinicalReport",
    "luPSMAEligibility",
    "recipCriteria",
    "errors",
  ]);
  const { log } = useLogContext();
  const { getAccessToken } = useAuthContext();
  const { criteriaApplicable: luPSMACriteriaApplicable, reportText } =
    useLuPSMAEligibility(patient, patientScan);
  const [loading, setLoading] = React.useState(false);
  const {
    criteriaApplicable: recipCriteriaApplicable,
    reportText: recipCriteriaReportText,
    reference,
  } = useRecipCriteria(patientScan, patientRecipCriteria);

  async function generateRichText(contentType: string): Promise<Blob> {
    if (!patient.id || !patientScan.id || loading) {
      return new Blob();
    }
    setLoading(true);

    const accessToken = await getAccessToken();
    const patientTreatments = await getTreatmentsForPatient(
      accessToken,
      patient.id
    );
    const patientTestResults = await getTestResultsForPatient(
      accessToken,
      patient.id
    );
    const clinicalInformation = getClinicalInformation(
      patient,
      patientScan,
      patientTestResults.filter((result) => result.test_name === "PSA"),
      patientTreatments
    );
    const patientScanDescriptiveFindings =
      await getPatientScanDescriptiveFindings(
        accessToken,
        patient.id,
        patientScan.id
      );
    const descriptiveFindings = getDescriptiveFindings(
      patientScanDescriptiveFindings
    );

    const technicalInformation = getTechnicalInformation(patientScan);

    const combinedImageGrid = await drawSVGGridOnCanvas(images, labels);
    const combinedImageDataURL = await blobToDataURL(combinedImageGrid);

    const psmaEligibilityRichText = luPSMACriteriaApplicable
      ? `
      <h2>${t("luPSMAEligibility:eligibilityLabel", {
        radiopharmaceutical:
          patientScan.therapeutic_radiopharmaceutical || "PSMA",
      })}</h2>
      <p>${reportText}</p> 
      <p>      
        <small><br/>${t("luPSMAEligibility:citation")}</small>
      </p>
    `
      : "";

    const recipCriteriaRichText =
      recipCriteriaApplicable && recipCriteriaReportText !== ""
        ? `
        <h2>${t("recipCriteria:header")}</h2>
        <p>${recipCriteriaReportText}</p>
        <p>
          <small><br/>${reference}</small>
        </p>
        `
        : "";

    const richText = `
      <style>
        * {
          font-family: Arial, sans-serif;
          font-size: 10pt;
        }
        h1 {
          font-size: 20pt;
          margin-bottom: 0;
        }
        h2 {
          font-size: 12pt;
          margin-top: 10pt;
          margin-bottom: 0;
        }
        h3 {
          font-size: 10pt;
          margin-top: 5pt;
          margin-bottom: 0;
        }
        p {
          text-align: justify;
          margin-bottom: 0;
          margin-top: 0;
        }
        ul.top-level > li:first-child {
          margin-top: 5pt;
        }
        ul.top-level > li:last-child {
          margin-bottom: 5pt;
        }
        p > small {
          font-size: 7pt;
          text-align: justify;
        }
      </style>
      <h1>${t("clinicalReport:title")}</h1>
      <h2>${t("clinicalReport:clinicalHistorySectionTitle")}</h2>
      <p>${clinicalInformation}</p>
      <h2>${t("clinicalReport:technicalInformationSectionTitle")}</h2>
      <p>${technicalInformation}</p>
      <h2>${t("clinicalReport:oncologicalFindingsSectionTitle")}</h2>
      ${oncologicalFindings.map((finding) => `<p>${finding}</p>`).join("\n")}
      <h2>${t("descriptiveFindingsSectionTitle")}</h2>
      <p>${t("descriptiveFindings:headNeck")}:</p>
      ${descriptiveFindings[0]
        .split("\n")
        .map((finding) => `<p>${finding}</p>`)
        .join("\n")}
      <p style="margin-top: 12pt">${t("descriptiveFindings:chest")}:</p>
      ${descriptiveFindings[1]
        .split("\n")
        .map((finding) => `<p>${finding}</p>`)
        .join("\n")}
      <p style="margin-top: 12pt">${t("descriptiveFindings:abdomenPelvis")}:</p>
      ${descriptiveFindings[2]
        .split("\n")
        .map((finding) => `<p>${finding}</p>`)
        .join("\n")}
      <p>${t("descriptiveFindings:muskoskeletal")}:</p> 
      ${descriptiveFindings[3]
        .split("\n")
        .map((finding) => `<p>${finding}</p>`)
        .join("\n")}
      ${psmaEligibilityRichText}
      ${recipCriteriaRichText}
      <h2>${t("clinicalReport:tnmCodelineSectionTitle")}</h2>
      <p>${tnmCodeLine}</p>
      <img style="width: 100%" src="${combinedImageDataURL}" />
      <p>
        <small><br/>${t("clinicalReport:citation")}</small>
      </p>`;

    return new Blob([richText], {
      type: contentType,
    });
  }

  async function onClick() {
    if (navigator.clipboard.write === undefined) {
      log(t("errors:copyToClipboardNotSupported"));
    }

    const clipboardItem = new ClipboardItem({
      "text/html": generateRichText("text/html"),
      "text/plain": generateRichText("text/plain"),
    });
    try {
      await navigator.clipboard.write([clipboardItem]);
      log(t("clinicalReport.copyReportSuccessPrompt"));
    } catch (e: any) {
      console.error(e);
      log(e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <GlobalHotKeys
      handlers={{
        COPY_ONCOLOGICAL_FINDINGS: onClick,
      }}
      allowChanges={true}
    >
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="copy-oncological-findings-button-tooltip">
            {t("clinicalReport:copyReportOverlay")}
          </Tooltip>
        }
      >
        <Button
          variant="link"
          onClick={onClick}
          disabled={loading}
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          {loading ? (
            <Spinner
              as="span"
              className="me-2"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <BootstrapIcon name="clipboard-pulse" size={28} />
          )}
        </Button>
      </OverlayTrigger>
    </GlobalHotKeys>
  );
};

export default CopyClinicalReportButton;
