import * as React from "react";
import { Patient } from "../../api/patients";
import { PatientScan } from "../../api/patientScans";
import {
  ClinicalIndication,
  LuPSMATherapyEligibility,
  RestagingCategory,
  luPSMATherapyEligibilityName,
} from "../../constants/enums";
import { useTranslation } from "react-i18next";

interface LuPSMAEligibilityProps {
  criteriaApplicable: boolean;
  eligibleForTherapy: LuPSMATherapyEligibility;
  reportText: string;
}

export default function useLuPSMAEligibility(
  currentPatient: Patient,
  currentPatientScan: PatientScan
): LuPSMAEligibilityProps {
  const { t } = useTranslation("luPSMAEligibility");
  const criteriaApplicable = React.useMemo(
    () =>
      currentPatient.id !== null &&
      currentPatientScan.clinical_indication === ClinicalIndication.RESTAGING &&
      currentPatientScan.restaging_category ===
        RestagingCategory.PSMA_THERAPY_ELIGIBILITY,
    [
      currentPatient.id,
      currentPatientScan.clinical_indication,
      currentPatientScan.restaging_category,
    ]
  );

  const eligibleForTherapy = React.useMemo(() => {
    if (
      criteriaApplicable &&
      currentPatientScan.liver_suv_max &&
      currentPatientScan.tumor_suv_max &&
      currentPatientScan.has_psma_negative_soft_tissue_lesions !== null
    ) {
      return currentPatientScan.tumor_suv_max >
        currentPatientScan.liver_suv_max &&
        currentPatientScan.has_psma_negative_soft_tissue_lesions === false
        ? LuPSMATherapyEligibility.Eligible
        : LuPSMATherapyEligibility.Ineligible;
    } else {
      return LuPSMATherapyEligibility.NotDefined;
    }
  }, [
    criteriaApplicable,
    currentPatientScan.liver_suv_max,
    currentPatientScan.tumor_suv_max,
    currentPatientScan.has_psma_negative_soft_tissue_lesions,
  ]);

  const reportText = React.useMemo(() => {
    if (!criteriaApplicable) {
      return "";
    }

    return t("reportText", {
      eligibility: luPSMATherapyEligibilityName(eligibleForTherapy),
      therapyDescription: currentPatientScan.therapeutic_radiopharmaceutical
        ? t("therapeuticRadiopharmaceuticalTherapyText", {
            radiopharmaceutical:
              currentPatientScan.therapeutic_radiopharmaceutical,
          })
        : t("genericTherapyText"),
      tumorSUVmax: currentPatientScan.tumor_suv_max,
      SUVmaxComparison:
        (currentPatientScan.tumor_suv_max || 0) >
        (currentPatientScan.liver_suv_max || 0)
          ? t("higher")
          : t("lower"),
      liverSUVmax: currentPatientScan.liver_suv_max,
      psmaNegativeSoftTissueLesionDescription:
        currentPatientScan.has_psma_negative_soft_tissue_lesions
          ? t("hasPSMANegativeSoftTissueLesionsText", {
              SUVmax:
                currentPatientScan.psma_negative_soft_tissue_lesions_suv_max,
              location: currentPatientScan.psma_negative_soft_tissue_lesions,
            })
          : t("noPSMANegativeSoftTissueLesionsText"),
    });
  }, [currentPatientScan, eligibleForTherapy, criteriaApplicable]);

  return {
    criteriaApplicable,
    eligibleForTherapy,
    reportText,
  };
}
