import { Row, Col } from "react-bootstrap";

import MiTNMHeader from "../MiTNMHeader";
import { Outlet } from "react-router-dom";
import React from "react";
import ClinicSidebarV2 from "../Sidebar/ClinicSidebarV2";

function HomePageClinicLayoutV2() {
  return (
    <Row className="px-3" style={{ height: "100vh" }}>
      <Col lg="3" className="Sidebar d-flex flex-column">
        <MiTNMHeader />
        <ClinicSidebarV2 />
      </Col>
      <Col lg="9" className="Content">
        <Outlet />
      </Col>
    </Row>
  );
}

export default HomePageClinicLayoutV2;
