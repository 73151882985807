import * as React from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientsContext } from "../../contexts/PatientsContext";
import useLuPSMAEligibility from "./useLuPSMAEligibility";
import {
  LuPSMATherapyEligibility,
  TherapeuticRadiopharmaceutical,
  luPSMATherapyEligibilityName,
  therapeuticRadiopharmaceuticalName,
} from "../../constants/enums";
import { useTranslation } from "react-i18next";

interface LuPSMAEligibilityProps {}

function LuPSMAEligibility(props: LuPSMAEligibilityProps) {
  const { t } = useTranslation([
    "luPSMAEligibility",
    "patient",
    "sections",
    "common",
  ]);
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan, setCurrentPatientScan } =
    usePatientScansContext();

  const { criteriaApplicable, eligibleForTherapy } = useLuPSMAEligibility(
    currentPatient,
    currentPatientScan
  );

  const therapyEligibilityTextColor = React.useMemo(() => {
    switch (eligibleForTherapy) {
      case LuPSMATherapyEligibility.Eligible:
        return "text-success";
      case LuPSMATherapyEligibility.Ineligible:
        return "text-danger";
      case LuPSMATherapyEligibility.NotDefined:
      default:
        return "text-muted";
    }
  }, [eligibleForTherapy]);

  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">{t("sections:luPSMAEligibility")}</h3>
      </div>
      <Form style={{ marginTop: 12 }}>
        {criteriaApplicable ? (
          <>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="mb-0">
                  {t("patient:therapeuticRadiopharmaceutical")}
                </Form.Label>
                <Form.Select
                  value={
                    currentPatientScan.therapeutic_radiopharmaceutical || "n/a"
                  }
                  onChange={(e) => {
                    setCurrentPatientScan({
                      ...currentPatientScan,
                      therapeutic_radiopharmaceutical: e.target
                        .value as TherapeuticRadiopharmaceutical,
                    });
                  }}
                >
                  <option value="n/a" disabled>
                    {t("patient:selectTherapeuticRadiopharmaceutical")}
                  </option>
                  {Object.values(TherapeuticRadiopharmaceutical).map(
                    (rph, index) => (
                      <option value={rph} key={index}>
                        {therapeuticRadiopharmaceuticalName(rph)}
                      </option>
                    )
                  )}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="mb-0">
                  {t("patient:liverSUVmax")}
                </Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  value={currentPatientScan.liver_suv_max || ""}
                  onChange={(e) => {
                    setCurrentPatientScan({
                      ...currentPatientScan,
                      liver_suv_max: parseFloat(e.target.value) || null,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="mb-0">
                  {t("patient:tumorSUVmax")}
                </Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  value={currentPatientScan?.tumor_suv_max || ""}
                  onChange={(e) => {
                    setCurrentPatientScan({
                      ...currentPatientScan,
                      tumor_suv_max: parseFloat(e.target.value) || null,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="mb-0">
                  {t("patient:hasPSMANegativeSoftTissueLesions")}
                </Form.Label>
                <div style={{ marginTop: "6px" }}>
                  <Form.Check
                    inline
                    defaultChecked={
                      currentPatientScan.has_psma_negative_soft_tissue_lesions ===
                      true
                    }
                    onClick={() => {
                      setCurrentPatientScan({
                        ...currentPatientScan,
                        has_psma_negative_soft_tissue_lesions: true,
                      });
                    }}
                    label={t("common:yes")}
                    name="has_psma_negative_soft_tissue_lesions"
                    type="radio"
                    key="has_psma_negative_soft_tissue_lesions-radio-yes"
                  />
                  <Form.Check
                    inline
                    defaultChecked={
                      currentPatientScan.has_psma_negative_soft_tissue_lesions ===
                      false
                    }
                    onClick={() => {
                      setCurrentPatientScan({
                        ...currentPatientScan,
                        has_psma_negative_soft_tissue_lesions: false,
                        psma_negative_soft_tissue_lesions: "",
                      });
                    }}
                    label={t("common:no")}
                    name="has_psma_negative_soft_tissue_lesions"
                    type="radio"
                    key="has_psma_negative_soft_tissue_lesions-radio-no"
                  />
                </div>
              </Form.Group>
            </Row>
            {currentPatientScan.has_psma_negative_soft_tissue_lesions ===
              true && (
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="mb-0">
                    {t("patient:psmaNegativeSoftTissueLesionsSUVmax")}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    value={
                      currentPatientScan?.psma_negative_soft_tissue_lesions_suv_max ||
                      ""
                    }
                    onChange={(e) => {
                      setCurrentPatientScan({
                        ...currentPatientScan,
                        psma_negative_soft_tissue_lesions_suv_max:
                          parseFloat(e.target.value) || null,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="mb-0">
                    {t("patient:psmaNegativeSoftTissueLesionLocation")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      currentPatientScan.psma_negative_soft_tissue_lesions || ""
                    }
                    onChange={(e) => {
                      setCurrentPatientScan({
                        ...currentPatientScan,
                        psma_negative_soft_tissue_lesions: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Row>
            )}
            <Row className="mb-5">
              <Col>
                <h5>
                  {t("eligibilityLabel", {
                    radiopharmaceutical:
                      currentPatientScan.therapeutic_radiopharmaceutical ||
                      "PSMA",
                  })}{" "}
                  <b className={therapyEligibilityTextColor}>
                    {luPSMATherapyEligibilityName(eligibleForTherapy)}
                  </b>
                </h5>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <h6>{t("definition.header")}</h6>
                <p>{t("definition.1")}</p>
                <p className="mb-0">{t("definition.2")}</p>
                <ul>
                  <li>{t("definition.3")}</li>
                  <li>{t("definition.4")}</li>
                  <li>{t("definition.5")}</li>
                </ul>
                <p>{t("definition.6")}</p>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mb-3">
            <Col>
              <Alert variant="secondary">
                {t("criteriaNotApplicable.alertText")}
                <ul>
                  <li>{t("criteriaNotApplicable.modality")}</li>
                  <li>{t("criteriaNotApplicable.radiopharmaceutical")}</li>
                </ul>
              </Alert>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
}

export default LuPSMAEligibility;
