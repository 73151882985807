import * as React from "react";
import { GlobalHotKeys } from "react-hotkeys";
import intersect from "path-intersection";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import ProstateTumorStagingImage from "./ProstateTumorStagingImage";
import BootstrapIcon from "../../BootstrapIcon";
import regionName from "../../../constants/regionNames";
import findAffectedAreaNode, { findMarker } from "../../../utils/svgUtils";
import { handleProstateRegions } from "../../../utils/regionNameUtils";
import { useRegionOverlay } from "../../RegionOverlay";
import { Coordinates, EllipseCoordinates } from "../../../api/patientScans";
import { ProstateTumorProps } from "./ProstateTumorWrapper";
import useSUVmaxOverlay from "../../SUVmaxOverlay/useSUVmaxOverlay";
import { useTranslation } from "react-i18next";

function ProstateTumorStaging({
  markerInfo,
  updateTumorMarkers,
  markerColor,
}: ProstateTumorProps) {
  const { t } = useTranslation("miTNM");
  const ref = React.useRef<SVGSVGElement>(null);
  const [svgPoint, setSvgPoint] = React.useState<SVGPoint>();

  const {
    markers,
    marker_count_by_region,
    markers_by_label,
    extraprostatic_involvement,
  } = markerInfo;

  const [currentTumorLabel, setCurrentTumorLabel] = React.useState(0);

  // Set the SVG point after the initial render
  React.useEffect(() => {
    if (ref.current) {
      setSvgPoint(ref.current.createSVGPoint());
    }
  }, []);

  const {
    overlay: regionOverlay,
    setShowOverlay,
    updateOverlayOnMouseMove,
  } = useRegionOverlay(ref, regionName);

  const updateMarkers = (newMarkers: {
    [key: string]: Coordinates | EllipseCoordinates;
  }) => {
    updateTumorMarkers({
      ...markerInfo,
      markers: newMarkers,
    });
    calculateAffectedAreas(newMarkers);
  };

  const { overlay: suvMaxOverlay, onContextMenu } = useSUVmaxOverlay({
    parentRef: ref,
    markers,
    updateMarkers,
  });

  // Extraprostatitic involvement state
  const [showExtraprostaticInvolvement, setShowExtraprostaticInvolvement] =
    React.useState(false);

  React.useEffect(() => {
    // Show Extraprostatitic involvement input in case of T4
    if (
      marker_count_by_region.hasOwnProperty("Apex-TLO") ||
      marker_count_by_region.hasOwnProperty("Apex-BLO") ||
      marker_count_by_region.hasOwnProperty("Apex-BRO") ||
      marker_count_by_region.hasOwnProperty("Apex-TRO") ||
      marker_count_by_region.hasOwnProperty("Mid-TLO") ||
      marker_count_by_region.hasOwnProperty("Mid-BLO") ||
      marker_count_by_region.hasOwnProperty("Mid-BRO") ||
      marker_count_by_region.hasOwnProperty("Mid-TRO") ||
      marker_count_by_region.hasOwnProperty("Base-TLO") ||
      marker_count_by_region.hasOwnProperty("Base-BLO") ||
      marker_count_by_region.hasOwnProperty("Base-BRO") ||
      marker_count_by_region.hasOwnProperty("Base-TRO") ||
      marker_count_by_region.hasOwnProperty("EPE")
    ) {
      setShowExtraprostaticInvolvement(true);
    } else {
      setShowExtraprostaticInvolvement(false);
      updateTumorMarkers({
        ...markerInfo,
        extraprostatic_involvement: "",
      });
    }
  }, [markerInfo.marker_count_by_region]);

  const [currentTumor, setCurrentTumor] = React.useState<string>();

  function addNewTumorLabel() {
    const newTumorsByLabel = Array.from(markers_by_label);
    newTumorsByLabel.push([]);
    updateTumorMarkers({
      ...markerInfo,
      markers_by_label: newTumorsByLabel,
    });
    setCurrentTumorLabel(newTumorsByLabel.length - 1);
  }

  function removeTumorLabel(label: number, newTumorsByLabel: string[][]) {
    if (label === newTumorsByLabel.length - 1) {
      setCurrentTumorLabel(currentTumorLabel - 1);
    }
    // Remove all tumors inside the label
    const newTumors = markerInfo.markers;
    newTumorsByLabel[label].forEach((tumorId) => {
      delete newTumors[tumorId];
    });
    newTumorsByLabel.splice(label, 1);
    updateTumorMarkers({
      ...markerInfo,
      markers: newTumors,
      markers_by_label: newTumorsByLabel,
    });
  }

  function removeTumorFromTumorsByLabel(tumorId: string) {
    const newTumorsByLabel = Array.from(markers_by_label);
    for (let i = 0; i < newTumorsByLabel.length; i++) {
      const tumorIndex = newTumorsByLabel[i].indexOf(tumorId);
      if (tumorIndex !== -1) {
        newTumorsByLabel[i].splice(tumorIndex, 1);
        if (newTumorsByLabel.length === 0 && currentTumorLabel !== i) {
          removeTumorLabel(i, newTumorsByLabel);
        } else {
          updateTumorMarkers({
            ...markerInfo,
            markers_by_label: newTumorsByLabel,
          });
        }
        return;
      }
    }
  }

  const onPointerDown = (event: React.PointerEvent) => {
    if (event.button === 2 || (event.button === 1 && event.ctrlKey)) {
      // Right click is handled by onContetxMenu
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    const [found, elementId] = findMarker(4, event.target);
    if (found) {
      // Delete tumor
      const newTumors = structuredClone(markers);
      delete newTumors[elementId];
      removeTumorFromTumorsByLabel(elementId);
      updateTumorMarkers({
        ...markerInfo,
        markers: newTumors,
      });
      calculateAffectedAreas(newTumors);
    } else {
      // Convert screen coords to SVG coords
      if (!svgPoint) {
        console.error("SVG Point or ref are not defined");
        return;
      }
      svgPoint.x = event.clientX;
      svgPoint.y = event.clientY;
      const point = svgPoint.matrixTransform(
        ref.current!.getScreenCTM()!.inverse()
      );

      const tumorId = `Tumor*${Date.now()}`;

      const newTumors = structuredClone(markers);
      newTumors[tumorId] = {
        x1: point.x,
        y1: point.y,
        x2: point.x,
        y2: point.y,
      };
      const newTumorsByLabel = Array.from(markers_by_label);
      newTumorsByLabel[currentTumorLabel].push(tumorId);
      updateTumorMarkers({
        ...markerInfo,
        markers: newTumors,
        markers_by_label: newTumorsByLabel,
      });
      setCurrentTumor(tumorId);
    }
  };

  const onPointerMove = (event: React.PointerEvent) => {
    if (currentTumor) {
      setShowOverlay(false);
      // Convert screen coords to SVG coords
      if (!svgPoint) {
        console.error("SVG Point or ref are not defined");
        return;
      }
      // Convert screen coords to SVG coords
      svgPoint.x = event.clientX;
      svgPoint.y = event.clientY;
      const point = svgPoint.matrixTransform(
        ref.current!.getScreenCTM()!.inverse()
      );

      const newMarkers = structuredClone(markers);
      markers[currentTumor] = {
        ...markers[currentTumor],
        x2: point.x,
        y2: point.y,
      };

      updateTumorMarkers({
        ...markerInfo,
        markers: newMarkers,
      });
    } else {
      const [showOverlay, targetId] = findAffectedAreaNode(3, event.target);
      setShowOverlay(showOverlay);
      if (showOverlay) {
        updateOverlayOnMouseMove(event, targetId);
      }
    }
  };

  const onPointerUp = (event: React.PointerEvent) => {
    setCurrentTumor(undefined);
    calculateAffectedAreas(markers);
  };

  // Calculate affected areas after each change to tumors
  function calculateAffectedAreas(innerTumors: {
    [key: string]: Coordinates | EllipseCoordinates;
  }) {
    if (svgPoint === undefined) {
      return;
    }

    const newTumorCountByRegion: { [key: string]: number } = {};

    Object.keys(innerTumors).forEach((tumorId) => {
      const tumor = document.getElementById(tumorId);
      if (tumor === null) return;

      // Set the svgPoint to the centre of the tumor
      const { x1, y1, x2, y2 } = innerTumors[tumorId] as EllipseCoordinates;
      const tumorRegions = new Set<string>();

      svgPoint.x = Math.round((x1 + x2) / 2);
      svgPoint.y = Math.round((y1 + y2) / 2);

      ["Apex", "Mid", "Base"].forEach((region) => {
        ["TLI", "TLM", "TRI", "TRM", "BLI", "BLM", "BRI", "BRM"].forEach(
          (subregion) => {
            const prostateRegionId = `${region}-${subregion}`;
            if (
              isOverlap(
                tumor,
                svgPoint,
                document.getElementById(prostateRegionId)
              )
            ) {
              tumorRegions.add(prostateRegionId);
              newTumorCountByRegion[prostateRegionId] =
                newTumorCountByRegion.hasOwnProperty(prostateRegionId)
                  ? newTumorCountByRegion[prostateRegionId] + 1
                  : 1;
            }
          }
        );
      });

      if (isOverlap(tumor, svgPoint, document.getElementById("EPE"))) {
        // Add virtual elements for all previously found prostate regions
        let regionAdded = false;
        tumorRegions.forEach((prostateRegionId) => {
          if (prostateRegionId.endsWith("M")) {
            const outerTumorRegion = prostateRegionId.replace(/.$/, "O");
            regionAdded = true;
            tumorRegions.add(outerTumorRegion);
            newTumorCountByRegion[outerTumorRegion] =
              newTumorCountByRegion.hasOwnProperty(outerTumorRegion)
                ? newTumorCountByRegion[outerTumorRegion] + 1
                : 1;
          }
        });
        // If no regions added, add raw "EPE" label instead
        if (!regionAdded) {
          tumorRegions.add("EPE");
          newTumorCountByRegion.EPE = newTumorCountByRegion.hasOwnProperty(
            "EPE"
          )
            ? newTumorCountByRegion.EPE + 1
            : 1;
        }
      }

      ["Bladder", "LSV", "RSV"].forEach((region) => {
        if (isOverlap(tumor, svgPoint, document.getElementById(region))) {
          tumorRegions.add(region);
          newTumorCountByRegion[region] = newTumorCountByRegion.hasOwnProperty(
            region
          )
            ? newTumorCountByRegion[region] + 1
            : 1;
        }
      });
    });

    // Calculate symmetric difference to add a record in the log
    const regionsAdded = new Set();
    const regionsRemoved = new Set();
    Object.keys(marker_count_by_region).forEach((area) => {
      if (
        !newTumorCountByRegion.hasOwnProperty(area) ||
        marker_count_by_region[area] !== newTumorCountByRegion[area]
      ) {
        regionsRemoved.add(area);
      }
    });
    Object.keys(newTumorCountByRegion).forEach((area) => {
      if (
        !marker_count_by_region.hasOwnProperty(area) ||
        marker_count_by_region[area] !== newTumorCountByRegion[area]
      ) {
        regionsAdded.add(area);
      }
    });

    updateTumorMarkers({
      ...markerInfo,
      markers: innerTumors,
      marker_count_by_region: newTumorCountByRegion,
    });

    const displayRegionsAdded = Array.from(
      new Set<string>(
        Array.from(handleProstateRegions(regionsAdded)).map(regionName)
      )
    );
    if (displayRegionsAdded.length > 0) {
      // log("Selected " + displayRegionsAdded.join(", "));
    }

    const displayRegionsRemoved = Array.from(
      new Set<string>(
        Array.from(handleProstateRegions(regionsAdded)).map(regionName)
      )
    );
    if (displayRegionsRemoved.length > 0) {
      // log("Deselected " + displayRegionsRemoved.join(", "));
    }
  }

  function isOverlap(
    tumor: HTMLElement,
    tumorCentrePoint: SVGPoint,
    element: HTMLElement | null
  ): boolean {
    if (element === null) {
      return false;
    }
    const tumorPath = getPath(tumor);
    const elementPath = getPath(element);
    if (tumorPath && elementPath) {
      const intersections = intersect(
        tumorPath.getAttribute("d")!,
        elementPath.getAttribute("d")!
      );

      return (
        intersections.length > 0 || elementPath.isPointInFill(tumorCentrePoint)
      );
    }
    return false;
  }

  function getPath(
    element: HTMLElement | SVGGeometryElement
  ): SVGGeometryElement | undefined {
    if (element.tagName === "path") return element as SVGGeometryElement;
    if (element.firstChild) {
      return getPath(element.firstChild as HTMLElement);
    }
    return undefined;
  }

  return (
    <GlobalHotKeys
      handlers={{
        NEW_TUMOR_LABEL: addNewTumorLabel,
      }}
      allowChanges={true}
    >
      <Card border="light">
        <div className="card-img-top">
          {regionOverlay}
          {suvMaxOverlay}
          <ProstateTumorStagingImage
            ref={ref}
            tumors={markers}
            tumorsByLabel={markers_by_label}
            currentTumorLabel={currentTumorLabel}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            onPointerLeave={() => {
              setShowOverlay(false);
            }}
            markerColor={markerColor}
            onContextMenu={onContextMenu}
          />
        </div>
        <Card.Text
          as="div"
          style={{
            marginTop: showExtraprostaticInvolvement ? "-39px" : 0,
          }}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {showExtraprostaticInvolvement && (
              <Row className="d-flex align-items-baseline gx-1 mx-3">
                <Col xs={6} md={4}>
                  <Form.Label
                    htmlFor="extraprostaticInvolvement"
                    visuallyHidden
                  >
                    {t("miTNM:extraprostaticInvolvement")}
                  </Form.Label>
                  <Form.Control
                    style={{ pointerEvents: "auto" }}
                    id="extraprostaticInvolvement"
                    placeholder={t("miTNM:extraprostaticInvolvement")}
                    value={extraprostatic_involvement}
                    onChange={(event) => {
                      updateTumorMarkers({
                        ...markerInfo,
                        extraprostatic_involvement: event.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row
              className="d-flex align-items-baseline gx-1 mx-3"
              style={{ minHeight: 40 }}
            >
              <Col xs="auto" className="ms-auto d-flex flex-row">
                {markers_by_label.map((_, i) => (
                  <ButtonGroup className="me-1" key={`tumorLabel-${i}`}>
                    <Button
                      variant={
                        i === currentTumorLabel ? "outline-primary" : "primary"
                      }
                      style={{
                        minWidth: 38,
                        height: 38,
                        borderRadius:
                          i === currentTumorLabel ? "38px 0 0 38px" : "38px",
                      }}
                      onClick={() => {
                        setCurrentTumorLabel(i);
                      }}
                    >
                      {i === currentTumorLabel
                        ? t("miTNM:editingLesion", { number: i + 1 })
                        : i + 1}
                    </Button>
                    {i === currentTumorLabel && (
                      <Button
                        variant="outline-primary"
                        style={{
                          width: 38,
                          height: 38,
                          borderRadius: "0 50% 50% 0",
                        }}
                        disabled={markers_by_label.length <= 1}
                        onClick={() => {
                          const newTumorsByLabel = Array.from(markers_by_label);
                          removeTumorLabel(i, newTumorsByLabel);
                        }}
                      >
                        <BootstrapIcon name="x-lg" size={12} />
                      </Button>
                    )}
                  </ButtonGroup>
                ))}
                <Button
                  variant="primary"
                  key={`tumorLabel-add-new`}
                  disabled={
                    markers_by_label[markers_by_label.length - 1].length === 0
                  }
                  style={{ width: 38, height: 38, borderRadius: "50%" }}
                  onClick={() => {
                    addNewTumorLabel();
                  }}
                >
                  <BootstrapIcon name="plus-lg" size={12} />
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Text>
      </Card>
    </GlobalHotKeys>
  );
}

export default ProstateTumorStaging;
