import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { usePatientScansContext } from "../contexts/PatientScansContext";
import { clinicalPatientScanLabel } from "../utils/patientScanUtils";
import PatientTypeahead from "./Sidebar/PatientTypeahead";
import {
  ClinicalIndication,
  clinicalIndicationName,
  ClinicalState,
  clinicalStateName,
  RestagingCategory,
  restagingCategoryName,
} from "../constants/enums";
import { DateTime } from "luxon";
import { usePatientsContext } from "../contexts/PatientsContext";
import DatePicker from "react-date-picker";
import {
  serializeFromPatientScan,
  serializeToPatientScan,
} from "../api/patientScans";
import { serializeFromPatient, serializeToPatient } from "../api/patients";
import { useTranslation } from "react-i18next";

const PatientScanLocalStorageKey = `tmp.patient-scan`;
const PatientLocalStorageKey = `tmp.patient`;

interface PatientInformationProps {}

function PatientInformation(props: PatientInformationProps) {
  const { t } = useTranslation(["patient", "sections"]);
  const { currentPatient, setCurrentPatient } = usePatientsContext();
  const {
    patientScans,
    currentPatientScan,
    setCurrentPatientScan,
    savePatientScan,
    resetPatientScan,
  } = usePatientScansContext();

  // Save patient scan changes to local storage
  React.useEffect(() => {
    window.localStorage.setItem(
      PatientScanLocalStorageKey,
      serializeFromPatientScan(currentPatientScan)
    );
  }, [currentPatientScan]);

  // Save patient changes to local storage
  React.useEffect(() => {
    window.localStorage.setItem(
      PatientLocalStorageKey,
      serializeFromPatient(currentPatient)
    );
  }, [currentPatient]);

  // If patient scan is not created, create one on leaving the tab
  React.useEffect(() => {
    return () => {
      if (
        window.localStorage.getItem(PatientScanLocalStorageKey) === null ||
        window.localStorage.getItem(PatientLocalStorageKey) === null
      ) {
        return;
      }

      const patient = serializeToPatient(
        JSON.parse(window.localStorage.getItem(PatientLocalStorageKey) || "")
      );

      if (!patient.id) {
        return;
      }

      const patientScan = serializeToPatientScan(
        JSON.parse(
          window.localStorage.getItem(PatientScanLocalStorageKey) || ""
        )
      );

      if (!patientScan.id) {
        savePatientScan(patient.id, patientScan);
        window.localStorage.removeItem(PatientLocalStorageKey);
        window.localStorage.removeItem(PatientScanLocalStorageKey);
      }
    };
  }, []);

  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">{t("sections:patientInformation")}</h3>
      </div>
      <Form style={{ marginTop: 12 }}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="patientId">
            <Form.Label className="mb-0">{t("patientId")}</Form.Label>

            <PatientTypeahead />
          </Form.Group>
          <Form.Group as={Col} controlId="studyId">
            <Form.Label className="mb-0">{t("studyId")}</Form.Label>
            <Form.Control
              type="text"
              disabled={!currentPatient.id}
              value={currentPatient.study_id || ""}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  study_id: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("dateOfBirth")}</Form.Label>
            <DatePicker
              className="form-control"
              disabled={!currentPatient.id}
              value={
                currentPatient.date_of_birth &&
                currentPatient.date_of_birth.toJSDate()
              }
              onChange={(value: Date | null) => {
                setCurrentPatient({
                  ...currentPatient,
                  date_of_birth: value && DateTime.fromJSDate(value),
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("age")}</Form.Label>
            <Form.Control
              plaintext
              readOnly
              value={t("common:ageInYears", {
                count: currentPatient.date_of_birth
                  ? Math.floor(
                      -currentPatient.date_of_birth.diffNow("years").years
                    )
                  : 0,
              })}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="previousScan">
            <Form.Label className="mb-0">{t("previousScans")}</Form.Label>
            <Form.Select
              htmlSize={5}
              value={currentPatientScan.id || -1}
              disabled={!currentPatient.id}
              onChange={(e) => {
                const scanIdToLoad = parseInt(e.target.value);
                if (scanIdToLoad !== -1) {
                  const scanToLoad = patientScans.find(
                    (elem: any) => elem.id === scanIdToLoad
                  );
                  if (scanToLoad) {
                    setCurrentPatientScan(scanToLoad);
                  }
                } else {
                  resetPatientScan();
                }
              }}
            >
              <option value="-1" key={`Scan-none`}>
                {t("newScan")}
              </option>
              {patientScans.map((scan: any) => (
                <option value={scan.id} key={`Scan-${scan.id}`}>
                  {clinicalPatientScanLabel(scan)}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("scanDate")}</Form.Label>
            <DatePicker
              className="form-control"
              disabled={!currentPatient.id}
              value={
                currentPatientScan.date && currentPatientScan.date.toJSDate()
              }
              onChange={(value: Date | null) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  date: value && DateTime.fromJSDate(value),
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("clinicalIndication")}</Form.Label>
            <Form.Select
              aria-label={t("clinicalIndication")}
              disabled={!currentPatient.id}
              value={currentPatientScan.clinical_indication.toString()}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  clinical_indication: e.target.value as ClinicalIndication,
                });
              }}
            >
              {Object.values(ClinicalIndication).map(
                (clinicalIndication, index) => (
                  <option
                    value={clinicalIndication}
                    key={`ClinicalIndication-${index}`}
                  >
                    {clinicalIndicationName(clinicalIndication)}
                  </option>
                )
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("clinicalState")}</Form.Label>
            <Form.Select
              aria-label={t("clinicalState")}
              disabled={
                !currentPatient.id ||
                currentPatientScan.clinical_indication ===
                  ClinicalIndication.STAGING
              }
              value={currentPatientScan.clinical_state?.toString() || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  clinical_state: e.target.value as ClinicalState,
                });
              }}
            >
              <option disabled value="n/a">
                {t("selectClinicalState")}
              </option>
              {currentPatientScan.clinical_indication ===
              ClinicalIndication.STAGING ? (
                <option value={ClinicalState.LOCALIZED_PC}>
                  {clinicalStateName(ClinicalState.LOCALIZED_PC)}
                </option>
              ) : (
                Object.values(ClinicalState)
                  .filter(
                    (clinicalState) =>
                      clinicalState !== ClinicalState.LOCALIZED_PC
                  )
                  .map((clinicalState, index) => (
                    <option value={clinicalState} key={index}>
                      {clinicalStateName(clinicalState)}
                    </option>
                  ))
              )}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            {currentPatientScan.clinical_indication ===
              ClinicalIndication.RESTAGING && (
              <Form.Label className="mb-0"> </Form.Label>
            )}
            <Form.Check
              disabled={!currentPatient.id}
              checked={currentPatientScan.prostate_removed}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  prostate_removed: e.target.checked,
                });
              }}
              label={t("priorRadicalProstatectomy")}
              style={{
                minHeight: "2.25rem",
                lineHeight: 1.5,
                paddingTop: "0.5rem",
              }}
            />
          </Form.Group>
          {currentPatientScan.clinical_indication ===
            ClinicalIndication.RESTAGING && (
            <Form.Group as={Col}>
              <Form.Label className="mb-0">{t("restagingCategory")}</Form.Label>
              <Form.Select
                aria-label={t("restagingCategory")}
                disabled={!currentPatient.id}
                value={
                  currentPatientScan.restaging_category?.toString() || "n/a"
                }
                onChange={(e) => {
                  setCurrentPatientScan({
                    ...currentPatientScan,
                    restaging_category: e.target.value as RestagingCategory,
                  });
                }}
              >
                <option disabled value="n/a">
                  {t("selectRestagingCategory")}
                </option>
                {Object.values(RestagingCategory).map(
                  (restagingCategory, index) => (
                    <option value={restagingCategory} key={index}>
                      {restagingCategoryName(restagingCategory)}
                    </option>
                  )
                )}
              </Form.Select>
            </Form.Group>
          )}
        </Row>
      </Form>
    </>
  );
}

export default PatientInformation;
