import { useTranslation } from "react-i18next";
import InitialDiagnosis from "./InitialDiagnosis";
import LabTests from "./LabTests";
import TreatmentHistory from "./TreatmentHistory";

export default function ClinicalData(): React.ReactElement {
  const { t } = useTranslation("sections");

  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">{t("clinicalData")}</h3>
      </div>
      <InitialDiagnosis />
      <TreatmentHistory />
      <LabTests />
    </>
  );
}
