import { DateTime } from "luxon";
import i18n from "../i18n";

export function formatDateDiff(date1: DateTime, date2: DateTime) {
  let duration = date1.diff(date2, "days");

  const negativeDuration = duration.valueOf() < 0;

  if (negativeDuration) {
    duration = duration.negate();
  }

  return i18n.t(
    negativeDuration ? "common:durationBefore" : "common:durationAfter",
    {
      duration: duration.toHuman({ listStyle: "narrow" }),
    }
  );
}

export function parseDate(dateString: any): DateTime | null {
  return dateString ? DateTime.fromISO(dateString) : null;
}
