import { DateTime } from "luxon";
import { parseDate } from "../utils/dateUtils";
import { APIError } from "../utils/errors";

export interface PatientFeedback {
  id: number | null;
  patient_id: number;
  created_by_id: number | null;
  treatable: boolean;
  comment: string;
  created_at: DateTime | null;
  updated_at: DateTime | null;
}

export function initialPatientFeedback(
  patient_id: number,
  treatable: boolean,
  comment: string
): PatientFeedback {
  return {
    id: null,
    patient_id,
    created_by_id: null,
    treatable,
    comment,
    created_at: null,
    updated_at: null,
  };
}

export function serializeToPatientFeedback(jsonResponse: any): PatientFeedback {
  return {
    ...initialPatientFeedback(0, false, ""),
    ...jsonResponse,
    created_at: parseDate(jsonResponse.created_at),
    updated_at: parseDate(jsonResponse.updated_at),
  };
}

export function serializeFromPatientFeedback(
  patientFeedback: PatientFeedback
): string {
  return JSON.stringify({
    ...patientFeedback,
    created_at: patientFeedback.created_at?.toISO(),
    updated_at: patientFeedback.updated_at?.toISO(),
  });
}

export async function createPatientFeedback(
  accessToken: string,
  patientId: number,
  patientFeedback: PatientFeedback
): Promise<any> {
  const response = await fetch(`/api/patients/${patientId}/feedback`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serializeFromPatientFeedback(patientFeedback),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function getPatientFeedbackForPatient(
  accessToken: string,
  patientId: number
): Promise<PatientFeedback[]> {
  const response = await fetch(`/api/patients/${patientId}/feedback`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse.map(serializeToPatientFeedback);
}
