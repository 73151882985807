import {
  Button,
  ButtonGroup,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { PatientTreatment } from "../../api/patientTreatments";
import { DateTime } from "luxon";
import BootstrapIcon from "../BootstrapIcon";
import { isSameDayTreatment } from "../../utils/treatmentUtils";
import { useTranslation } from "react-i18next";

interface TreatmentRowProps {
  treatment: PatientTreatment;
  onDeleteTreatment: () => void;
  onEditTreatment: () => void;
}

export default function TreatmentRow({
  treatment,
  onDeleteTreatment,
  onEditTreatment,
}: TreatmentRowProps): React.ReactElement {
  const { t } = useTranslation(["treatments", "common"]);
  const sameDayTreatment = isSameDayTreatment(treatment);

  let endedAtValue = "";
  if (/* Ongoing treatment */ treatment.ended_at == null) {
    endedAtValue = t("common:ongoing");
  } else {
    if (sameDayTreatment) {
      endedAtValue = t("common:sameDay");
    } else {
      endedAtValue = treatment.ended_at.toLocaleString(DateTime.DATE_SHORT);
    }
  }

  return (
    <Row>
      <Form.Group as={Col} xs="6">
        <Form.Control
          plaintext
          readOnly
          tabIndex={-1}
          value={treatment.treatment_name}
        />
      </Form.Group>
      <Form.Group as={Col} xs="2">
        <Form.Control
          plaintext
          readOnly
          value={treatment.started_at.toLocaleString(DateTime.DATE_SHORT)}
        />
      </Form.Group>
      <Form.Group as={Col} xs="2" className="d-flex align-items-center">
        <Form.Control plaintext readOnly tabIndex={-1} value={endedAtValue} />
      </Form.Group>
      <Form.Group as={Col} xs="2" className="d-flex align-items-center">
        <ButtonGroup size="sm">
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-edit">{t("editOverlay")}</Tooltip>}
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                onEditTreatment();
              }}
            >
              <BootstrapIcon name="pencil" size={16} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-delete">{t("deleteOverlay")}</Tooltip>
            }
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                if (window.confirm(t("confirmationDialog"))) {
                  onDeleteTreatment();
                }
              }}
            >
              <BootstrapIcon name="trash3" size={16} />
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </Form.Group>
    </Row>
  );
}
