import React from "react";
import { PatientScan, getPatientScansForPatient } from "../api/patientScans";
import { useAuthContext } from "./AuthContext";
import { useCurrentPatientId } from "../utils/routerUtils";

interface PatientScansContextV2Type {
  loading: boolean;
  patientScans: PatientScan[];
  refreshPatientScans: () => Promise<void>;
}

const PatientScansContextV2 = React.createContext<PatientScansContextV2Type>({
  loading: false,
  patientScans: [],
  refreshPatientScans: async () => {},
});

export function PatientScansContextV2Provider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { getAccessToken } = useAuthContext();

  const currentPatientId = useCurrentPatientId();

  const [loading, setLoading] = React.useState(false);
  const [patientScans, setPatientScans] = React.useState<PatientScan[]>([]);

  React.useEffect(() => {
    refreshPatientScans();
  }, [currentPatientId]);

  async function refreshPatientScans() {
    setLoading(true);
    if (currentPatientId == null) {
      setPatientScans([]);
      setLoading(false);
      return;
    }
    const accessToken = await getAccessToken();
    setPatientScans(
      await getPatientScansForPatient(accessToken, currentPatientId)
    );
    setLoading(false);
  }

  return (
    <PatientScansContextV2.Provider
      value={{ loading, patientScans, refreshPatientScans }}
    >
      {children}
    </PatientScansContextV2.Provider>
  );
}

export const usePatientScansContextV2 = () =>
  React.useContext(PatientScansContextV2);
