import * as pdfMake from "pdfmake/build/pdfmake";
import { blobToDataURL } from "./imageUtils";

import poppinsMedium from "../fonts/Poppins-Medium.ttf";
import poppinsMediumItalic from "../fonts/Poppins-MediumItalic.ttf";
import poppinsSemiBold from "../fonts/Poppins-SemiBold.ttf";
import poppinsSemiBoldItalic from "../fonts/Poppins-SemiBoldItalic.ttf";
import sourceSerifPro from "../fonts/SourceSerifPro-Regular.ttf";
import sourceSerifProItalic from "../fonts/SourceSerifPro-Italic.ttf";
import {
  ContentText,
  Content,
  Style,
  StyleDictionary,
  TDocumentDefinitions,
} from "pdfmake/interfaces";
import i18n from "../i18n";

function generateReport(
  logoPNGBlob: Blob,
  imageSnapshots: Blob,
  clinicalInformation: string,
  technicalInformation: string,
  oncologicalFindings: [string, string, string],
  descriptiveFindings: [string, string, string, string],
  luPSMACriteriaApplicable: boolean,
  luPSMACriteriaTherapeuticRadiopharmaceutical: string,
  luPSMACriteriaReportText: string,
  recipCriteriaApplicable: boolean,
  recipCriteriaReportText: string,
  recipCriteriaReference: string,
  tnmCodeLine: string
): Promise<pdfMake.TCreatedPdf> {
  return Promise.all(
    [logoPNGBlob, imageSnapshots].map((imageBlob) => blobToDataURL(imageBlob))
  ).then(([logo, image]) => {
    let content: Content = [
      {
        image: logo,
        height: 50,
        width: 99.55,
      },
      {
        text: i18n.t("clinicalReport:clinicalHistorySectionTitle"),
        style: ["header"],
      },
      {
        text: clinicalInformation,
      } as ContentText,
      {
        text: i18n.t("clinicalReport:technicalInformationSectionTitle"),
        style: ["header"],
      },
      { text: technicalInformation } as ContentText,

      {
        text: i18n.t("clinicalReport:oncologicalFindingsSectionTitle"),
        style: ["header"],
      },
      ...oncologicalFindings,
      {
        text: i18n.t("clinicalReport:descriptiveFindingsSectionTitle"),
        style: ["header"],
      },
      {
        text: i18n.t("descriptiveFindings:headNeck"),
        style: ["subHeader"],
      },
      descriptiveFindings[0],
      {
        text: i18n.t("descriptiveFindings:chest"),
        style: ["subHeader"],
      },
      descriptiveFindings[1],
      {
        text: i18n.t("descriptiveFindings:abdomenPelvis"),
        style: ["subHeader"],
      },
      descriptiveFindings[2],
      {
        text: i18n.t("descriptiveFindings:muskoskeletal"),
        style: ["subHeader"],
      },
      descriptiveFindings[3],
    ];

    if (luPSMACriteriaApplicable) {
      content = content.concat([
        {
          text: i18n.t("luPSMAEligibility:eligibilityLabel", {
            radiopharmaceutical: luPSMACriteriaTherapeuticRadiopharmaceutical,
          }),
          style: ["header"],
        },
        luPSMACriteriaReportText,
        {
          text: i18n.t("luPSMAEligibility:citation"),
          style: ["small"],
        },
      ]);
    }

    if (recipCriteriaApplicable && recipCriteriaReportText !== "") {
      content = content.concat([
        { text: i18n.t("recipCriteria:header"), style: ["header"] },
        recipCriteriaReportText,
        {
          text: recipCriteriaReference,
          style: ["small"],
        },
      ]);
    }

    content = content.concat([
      {
        text: i18n.t("clinicalReport:tnmCodelineSectionTitle"),
        style: ["header"],
      },
      { text: tnmCodeLine } as ContentText,
      {
        image: image,
        width: 514,
      },
      {
        text: i18n.t("clinicalReport:citation"),
        style: ["small"],
      },
    ]);

    const reportDefinition: TDocumentDefinitions = {
      info: {
        title: i18n.t("clinicalReport.title"),
      },
      content,
      styles: {
        header: {
          font: "Poppins",
          fontSize: 18,
          bold: true,
          margin: [0, 25, 0, 5],
        },
        subHeader: {
          font: "Poppins",
          fontSize: 16,
          bold: true,
        },
        small: {
          fontSize: 10,
        },
      } as StyleDictionary,
      defaultStyle: {
        fontSize: 14,
        alignment: "justify",
        font: "SourceSerifPro",
      } as Style,
    };

    return pdfMake.createPdf(reportDefinition, undefined, {
      Poppins: {
        normal: new URL(poppinsMedium, document.baseURI).href,
        italics: new URL(poppinsMediumItalic, document.baseURI).href,
        bold: new URL(poppinsSemiBold, document.baseURI).href,
        bolditalics: new URL(poppinsSemiBoldItalic, document.baseURI).href,
      },
      SourceSerifPro: {
        normal: new URL(sourceSerifPro, document.baseURI).href,
        italics: new URL(sourceSerifProItalic, document.baseURI).href,
      },
    });
  });
}

export default generateReport;
