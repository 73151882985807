import * as React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { inviteUser } from "../../api/auth";
import { AuthError } from "../../utils/errors";
import { useAuthContext } from "../../contexts/AuthContext";

import AuthLayout from "../AuthLayout";
import { useTranslation } from "react-i18next";

function NewInvitePage() {
  const { t } = useTranslation(["user", "common"]);
  const { getAccessToken } = useAuthContext();
  const navigate = useNavigate();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);

  function errorMessagesFor(key) {
    return (errorMessage[key] || []).map((message, index) => (
      <Form.Control.Feedback key={`${key}-error-${index}`} type="invalid">
        {message}
      </Form.Control.Feedback>
    ));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const accessToken = await getAccessToken();
      await inviteUser(accessToken, firstName, lastName, email);
      navigate(-1);
    } catch (error) {
      if (error instanceof AuthError) {
        setErrorMessage(error.details);
      }
      console.error(error.message);
    }
  }

  const body = (
    <Form onSubmit={handleSubmit}>
      <h5>{t("user:userDetails")}</h5>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="first_name">
            <Form.Label>{t("user:firstName")}</Form.Label>
            <Form.Control
              type="text"
              autoComplete="given-name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              required
              isInvalid={
                errorMessage && errorMessage.hasOwnProperty("first_name")
              }
            />
            {errorMessage && errorMessagesFor("first_name")}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="lat_name">
            <Form.Label>{t("user:lastName")}</Form.Label>
            <Form.Control
              type="text"
              autoComplete="family-name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              required
              isInvalid={
                errorMessage && errorMessage.hasOwnProperty("last_name")
              }
            />
            {errorMessage && errorMessagesFor("last_name")}
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>{t("user:email")}</Form.Label>
        <Form.Control
          type="email"
          autoFocus={true}
          autoComplete="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
          isInvalid={errorMessage && errorMessage.hasOwnProperty("email")}
        />
        {errorMessage && errorMessagesFor("email")}
      </Form.Group>
      <Button variant="primary" type="submit" className="w-100">
        {t("user:inviteUserButton")}
      </Button>
    </Form>
  );

  const links = (
    <Button
      variant="link"
      onClick={() => {
        navigate(-1);
      }}
    >
      {t("common:back")}
    </Button>
  );

  return (
    <AuthLayout title={t("user:newInvitation")} body={body} footer={links} />
  );
}

export default NewInvitePage;
