import { Outlet } from "react-router-dom";
import { StudyParticipationContextProvider } from "../../contexts/StudyParticipationContext";
import RequireAuth from "../RequireAuth";
import { PatientRecipCriteriaContextProvider } from "../../contexts/PatientRecipCriteriaContext";
import { CurrentPatientContextProvider } from "../../contexts/CurrentPatientContext";
import { PatientsContextV2Provider } from "../../contexts/PatientsContextV2";
import { PatientScansContextV2Provider } from "../../contexts/PatientScansContextV2";
import { CurrentPatientScanContextProvider } from "../../contexts/CurrentPatientScanContext";

export default function HomePageContextProvidersV2() {
  return (
    <PatientsContextV2Provider>
      <CurrentPatientContextProvider>
        <PatientScansContextV2Provider>
          <CurrentPatientScanContextProvider>
            <StudyParticipationContextProvider>
              <PatientRecipCriteriaContextProvider>
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              </PatientRecipCriteriaContextProvider>
            </StudyParticipationContextProvider>
          </CurrentPatientScanContextProvider>
        </PatientScansContextV2Provider>
      </CurrentPatientContextProvider>
    </PatientsContextV2Provider>
  );
}
