import { DateTime } from "luxon";
import { parseDate } from "../utils/dateUtils";
import { APIError } from "../utils/errors";
import i18n from "../i18n";

export interface PatientScanDescriptiveFindings {
  id: number | null;
  patient_scan_id: number | null;
  head_neck: string;
  lungs_pleura: string;
  cardiovascular: string;
  chest_lymph_nodes_mediastinum: string;
  liver_gallbladder: string;
  spleen_pancreas_adrenals: string;
  kidneys_ureters: string;
  bowels: string;
  bladder_reproductive_organs: string;
  abdomen_lymph_nodes: string;
  abdomen_vessels: string;
  abdominal_wall: string;
  muskoskeletal: string;
  created_at: DateTime | null;
  updated_at: DateTime | null;
}

export function initialPatientScanDescriptiveFindings(): PatientScanDescriptiveFindings {
  return {
    id: null,
    patient_scan_id: null,
    head_neck: i18n.t("descriptiveFindings:initialState.headNeck"),
    lungs_pleura: i18n.t("descriptiveFindings:initialState.lungsPleura"),
    cardiovascular: i18n.t("descriptiveFindings:initialState.cardiovascular"),
    chest_lymph_nodes_mediastinum: i18n.t(
      "descriptiveFindings:initialState.chestLymphNodesMediastinum"
    ),
    liver_gallbladder: i18n.t(
      "descriptiveFindings:initialState.liverGallbladder"
    ),
    spleen_pancreas_adrenals: i18n.t(
      "descriptiveFindings:initialState.spleenPancreasAdrenals"
    ),
    kidneys_ureters: i18n.t("descriptiveFindings:initialState.kidneysUreters"),
    bowels: i18n.t("descriptiveFindings:initialState.bowels"),
    bladder_reproductive_organs: i18n.t(
      "descriptiveFindings:initialState.bladderReproductiveOrgans"
    ),
    abdomen_lymph_nodes: i18n.t(
      "descriptiveFindings:initialState.abdomenLymphNodes"
    ),
    abdomen_vessels: i18n.t("descriptiveFindings:initialState.abdomenVessels"),
    abdominal_wall: i18n.t("descriptiveFindings:initialState.abdominalWall"),
    muskoskeletal: i18n.t("descriptiveFindings:initialState.muskoskeletal"),
    created_at: null,
    updated_at: null,
  };
}

export function serializeToPatientScanDescriptiveFindings(
  jsonResponse: any
): PatientScanDescriptiveFindings {
  const initialState = initialPatientScanDescriptiveFindings();
  return {
    id: jsonResponse.id,
    patient_scan_id: jsonResponse.patient_scan_id,
    head_neck: jsonResponse.head_neck || initialState.head_neck,
    lungs_pleura: jsonResponse.lungs_pleura || initialState.lungs_pleura,
    cardiovascular: jsonResponse.cardiovascular || initialState.cardiovascular,
    chest_lymph_nodes_mediastinum:
      jsonResponse.chest_lymph_nodes_mediastinum ||
      initialState.chest_lymph_nodes_mediastinum,
    liver_gallbladder:
      jsonResponse.liver_gallbladder || initialState.liver_gallbladder,
    spleen_pancreas_adrenals:
      jsonResponse.spleen_pancreas_adrenals ||
      initialState.spleen_pancreas_adrenals,
    kidneys_ureters:
      jsonResponse.kidneys_ureters || initialState.kidneys_ureters,
    bowels: jsonResponse.bowels || initialState.bowels,
    bladder_reproductive_organs:
      jsonResponse.bladder_reproductive_organs ||
      initialState.bladder_reproductive_organs,
    abdomen_lymph_nodes:
      jsonResponse.abdomen_lymph_nodes || initialState.abdomen_lymph_nodes,
    abdomen_vessels:
      jsonResponse.abdomen_vessels || initialState.abdomen_vessels,
    abdominal_wall: jsonResponse.abdominal_wall || initialState.abdominal_wall,
    muskoskeletal: jsonResponse.muskoskeletal || initialState.muskoskeletal,
    created_at: parseDate(jsonResponse.created_at),
    updated_at: parseDate(jsonResponse.updated_at),
  };
}

export function serializeFromPatientScanDescriptiveFindings(
  findings: PatientScanDescriptiveFindings
): string {
  return JSON.stringify({
    ...findings,
    created_at: findings.created_at?.toISO(),
    updated_at: findings.updated_at?.toISO(),
  });
}

export async function createPatientScanDescriptiveFindings(
  accessToken: string,
  patientId: number,
  patientScanId: number,
  findings: PatientScanDescriptiveFindings
): Promise<any> {
  const response = await fetch(
    `/api/patients/${patientId}/scans/${patientScanId}/descriptive_findings`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: serializeFromPatientScanDescriptiveFindings(findings),
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function updatePatientScanDescriptiveFindings(
  accessToken: string,
  patientId: number,
  patientScanId: number,
  findings: PatientScanDescriptiveFindings
): Promise<void> {
  const response = await fetch(
    `/api/patients/${patientId}/scans/${patientScanId}/descriptive_findings`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: serializeFromPatientScanDescriptiveFindings(findings),
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }
}

export async function getPatientScanDescriptiveFindings(
  accessToken: string,
  patientId: number,
  patientScanId: number
): Promise<PatientScanDescriptiveFindings> {
  const response = await fetch(
    `/api/patients/${patientId}/scans/${patientScanId}/descriptive_findings`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return serializeToPatientScanDescriptiveFindings(jsonResponse);
}
