import { DateTime } from "luxon";
import { PatientScan } from "../../../api/patientScans";
import { PatientTreatment } from "../../../api/patientTreatments";
import { Patient } from "../../../api/patients";
import { TestResult } from "../../../api/testResults";
import {
  ImagingModality,
  clinicalIndicationName,
  clinicalStateName,
  imagingModalityName,
} from "../../../constants/enums";
import { calculatePSADoublingTime } from "../../../utils/psaValueUtils";
import {
  isOngoingTreatment,
  isSameDayTreatment,
} from "../../../utils/treatmentUtils";
import { PatientScanDescriptiveFindings } from "../../../api/patientScanDescriptiveFindings";
import i18n from "../../../i18n";

export function getClinicalInformation(
  patient: Patient,
  patientScan: PatientScan,
  patientPSAValues: TestResult[],
  patientTreatments: PatientTreatment[]
) {
  let clinicalInformation = "";

  // Sort PSA values by distance to the scan date
  // Defer to the most recent if the scan date is not defined
  function psaValueSortCriteria(a: TestResult, b: TestResult): number {
    if (patientScan.date == null) {
      return (
        a.taken_at.diffNow().milliseconds - b.taken_at.diffNow().milliseconds
      );
    }
    return (
      Math.abs(b.taken_at.diff(patientScan.date).milliseconds) -
      Math.abs(a.taken_at.diff(patientScan.date).milliseconds)
    );
  }

  const sortedPSAValues = patientPSAValues.sort(psaValueSortCriteria);
  const psaDoublingTime = calculatePSADoublingTime(sortedPSAValues);

  // Date of birth
  if (patient.date_of_birth) {
    const count = Math.floor(-patient.date_of_birth.diffNow("years").years);
    clinicalInformation += i18n.t("clinicalReport:patientAge", { count });
  } else {
    clinicalInformation += i18n.t("clinicalReport:noPatientAge");
  }

  if (patientScan.clinical_state) {
    const clinicalState = clinicalStateName(
      patientScan.clinical_state
    ).toLowerCase();
    clinicalInformation +=
      " " +
      i18n.t("clinicalReport:clinicalState", {
        clinicalState,
      });
  }

  // Clinical indication is always defined
  const clinicalIndication = clinicalIndicationName(
    patientScan.clinical_indication
  ).toLowerCase();
  clinicalInformation +=
    " " + i18n.t("clinicalReport:clinicalIndication", { clinicalIndication });

  // Initial diagnosis
  if (patient.diagnosis_date) {
    const initialDiagnosisDate = patient.diagnosis_date.toLocaleString(
      DateTime.DATE_SHORT
    );
    clinicalInformation +=
      " " + i18n.t("clinicalReport:initialDiagnosis", { initialDiagnosisDate });

    if (patient.gleason_score) {
      clinicalInformation +=
        " " +
        i18n.t("clinicalReport:gleasonScore", {
          gleasonScore: patient.gleason_score,
        });
    }
    clinicalInformation += ".";
  }

  // Initial PSA value
  if (patient.initial_psa_value) {
    clinicalInformation +=
      " " +
      i18n.t("clinicalReport:initialPSAValue", {
        psaValue: patient.initial_psa_value,
      }) +
      ".";
  }

  // Most recent PSA value
  if (patientPSAValues.length > 0) {
    const mostRecentPSAValue = sortedPSAValues[patientPSAValues.length - 1];
    clinicalInformation +=
      " " +
      i18n.t("clinicalReport:mostRecentPSAValue", {
        takenAt: mostRecentPSAValue.taken_at.toLocaleString(
          DateTime.DATE_SHORT
        ),
        psaValue: mostRecentPSAValue.value,
      }) +
      ".";
  }

  // PSA doubling time
  if (psaDoublingTime) {
    clinicalInformation +=
      " " +
      i18n.t("clinicalReport:psaDoublingTime", { count: psaDoublingTime }) +
      ".";
  }

  if (patientTreatments.length > 0) {
    clinicalInformation += " " + i18n.t("clinicalReport:treatmentHistory");
    clinicalInformation += patientTreatments
      .map((treatment) => {
        // If same-day treatment, leave suffix empty
        let treatmentSuffix = "";
        if (isOngoingTreatment(treatment)) {
          treatmentSuffix = ` - ${i18n
            .t("common:ongoing")
            .toLocaleLowerCase()}`;
        } else if (!isSameDayTreatment(treatment)) {
          treatmentSuffix = ` - ${treatment.ended_at!.toLocaleString(
            DateTime.DATE_SHORT
          )}`;
        }
        return ` ${
          treatment.treatment_name
        } (${treatment.started_at.toLocaleString(
          DateTime.DATE_SHORT
        )}${treatmentSuffix})`;
      })
      .join(", ");
    clinicalInformation += ".";
  }

  return clinicalInformation;
}

export function getTechnicalInformation(patientScan: PatientScan) {
  // Technical information
  let technicalInformation = i18n.t(
    "clinicalReport:technicalInformationInjection"
  );

  if (patientScan.date) {
    const date = patientScan.date?.toLocaleString(DateTime.DATE_SHORT);
    technicalInformation += " " + i18n.t("clinicalReport:scanDate", { date });
  }

  if (patientScan.injected_activity) {
    const radiopharmaceutical =
      patientScan.radiopharmaceutical ||
      i18n.t("clinicalReport:unknownRadiopharmaceutical");
    technicalInformation +=
      " " +
      i18n.t("clinicalReport:injectedActivity", {
        injectedActivity: patientScan.injected_activity,
        radiopharmaceutical,
      });
  }
  technicalInformation += ".";

  const modality =
    patientScan.modality || i18n.t("clinicalReport:unknownModality");
  technicalInformation += " " + i18n.t("clinicalReport:scanInfo", { modality });

  if (patientScan.scanner_type || patientScan.scanner_model) {
    const scannerTypeAndModel = [
      patientScan.scanner_type,
      patientScan.scanner_model,
    ]
      .filter((s) => s != null)
      .join(" ");
    technicalInformation +=
      " " +
      i18n.t("clinicalReport:scannerTypeAndModel", { scannerTypeAndModel });
  }

  if (patientScan.location) {
    technicalInformation +=
      " " +
      i18n.t("clinicalReport:location", {
        location: patientScan.location,
      });
  }
  technicalInformation += ".";

  if (patientScan.reconstruction_method) {
    technicalInformation +=
      " " +
      i18n.t("clinicalReport:reconstructionMethod", {
        reconstructionMethod: patientScan.reconstruction_method,
      }) +
      ".";
  }

  if (patientScan.injected_time) {
    technicalInformation +=
      " " +
      i18n.t("clinicalReport:injectedTime", {
        count: patientScan.injected_time,
      }) +
      ".";
  }

  if (
    patientScan.modality === ImagingModality.PET_CT ||
    patientScan.modality === ImagingModality.PET_MRI
  ) {
    technicalInformation +=
      " " +
      i18n.t("clinicalReport:concurrentScan", {
        contrast:
          patientScan.contrast || false
            ? i18n.t("clinicalReport:hasContrast")
            : i18n.t("clinicalReport:noContrast"),
        modality: imagingModalityName(patientScan.modality),
      }) +
      ".";
  }

  // Contrast agent
  if (patientScan.contrast !== null) {
    if (patientScan.contrast === "None") {
      technicalInformation +=
        " " + i18n.t("clinicalReport:noContrastAgent") + ".";
    } else {
      technicalInformation +=
        " " +
        i18n.t("clinicalReport:contrastAgent", {
          contrast: patientScan.contrast,
        }) +
        ".";
    }
  }

  // Diuretics
  if (patientScan.diuretics !== null) {
    if (patientScan.diuretics === "None") {
      technicalInformation += " " + i18n.t("clinicalReport:noDiuretics") + ".";
    } else {
      technicalInformation +=
        " " +
        i18n.t("clinicalReport:diuretics", {
          diuretics: patientScan.diuretics,
        }) +
        ".";
    }
  }

  return technicalInformation;
}

export function getDescriptiveFindings(
  descriptiveFindings: PatientScanDescriptiveFindings
): [string, string, string, string] {
  const headNeckFindings = descriptiveFindings.head_neck;
  const chestFindings = [
    `${i18n.t("descriptiveFindings:lungsPleura")}: ${
      descriptiveFindings.lungs_pleura
    }`,
    `${i18n.t("descriptiveFindings:cardiovascular")}: ${
      descriptiveFindings.cardiovascular
    }`,
    `${i18n.t("descriptiveFindings:chestLymphNodesMediastinum")}: ${
      descriptiveFindings.chest_lymph_nodes_mediastinum
    }`,
  ].join("\n");
  const abdomenPelvisFindings = [
    `${i18n.t("descriptiveFindings:liverGallbladder")}: ${
      descriptiveFindings.liver_gallbladder
    }`,
    `${i18n.t("descriptiveFindings:spleenPancreasAdrenals")}: ${
      descriptiveFindings.spleen_pancreas_adrenals
    }`,
    `${i18n.t("descriptiveFindings:kidneysUreters")}: ${
      descriptiveFindings.kidneys_ureters
    }`,
    `${i18n.t("descriptiveFindings:bowels")}: ${descriptiveFindings.bowels}`,
    `${i18n.t("descriptiveFindings.bladderReproductiveOrgans")}: ${
      descriptiveFindings.bladder_reproductive_organs
    }`,
    `${i18n.t("descriptiveFindings:abdomenLymphNodes")}: ${
      descriptiveFindings.abdomen_lymph_nodes
    }`,
    `${i18n.t("descriptiveFindings:abdomenVessels")}: ${
      descriptiveFindings.abdomen_vessels
    }`,
    `${i18n.t("descriptiveFindings:abdominalWall")}: ${
      descriptiveFindings.abdominal_wall
    }`,
  ].join("\n");
  const muskoskeletalFindings = descriptiveFindings.muskoskeletal;
  return [
    headNeckFindings,
    chestFindings,
    abdomenPelvisFindings,
    muskoskeletalFindings,
  ];
}
