import * as React from "react";

import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import {
  createPatientFeedback,
  initialPatientFeedback,
} from "../api/patientFeedback";
import { useAuthContext } from "../contexts/AuthContext";
import { useLogContext } from "./LogToast/LogContext";
import { usePatientsContext } from "../contexts/PatientsContext";
import { useStudyParticipationContext } from "../contexts/StudyParticipationContext";
import { nextPatient } from "../utils/researchAutomatedWorkflowUtils";
import { useTranslation } from "react-i18next";

const INITIAL_STATE = {
  treatable: true,
  comment: "",
};

interface PatientFeedbackModalProps {
  showModal: boolean;
  onModalHide: () => void;
}

export default function PatientFeedbackModal({
  showModal,
  onModalHide,
}: PatientFeedbackModalProps) {
  const { t } = useTranslation(["luPSMAEligibility", "common"]);
  const { getAccessToken } = useAuthContext();

  const { patients, currentPatient } = usePatientsContext();
  const { currentUserStudyParticipation, setCurrentUserStudyParticipation } =
    useStudyParticipationContext();

  const { log } = useLogContext();
  const [treatable, setTreatable] = React.useState(INITIAL_STATE.treatable);
  const [comment, setComment] = React.useState(INITIAL_STATE.comment);

  return (
    <Modal size="lg" show={showModal} onHide={onModalHide} centered>
      <Modal.Header>
        <Modal.Title>
          {t("eligibilityLabel", { radiopharmaceutical: "PSMA" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();

                if (!currentPatient.id) {
                  console.warn("Current patient not defined. Cannot proceed");
                  return;
                }

                const newPatientFeedback = initialPatientFeedback(
                  currentPatient.id,
                  treatable,
                  comment
                );

                const accessToken = await getAccessToken();

                await createPatientFeedback(
                  accessToken,
                  currentPatient.id,
                  newPatientFeedback
                );

                await nextPatient(
                  accessToken,
                  patients,
                  currentUserStudyParticipation,
                  setCurrentUserStudyParticipation
                );

                setTreatable(INITIAL_STATE.treatable);
                setComment(INITIAL_STATE.comment);
                onModalHide();
                log(t("feedbackSubmittedPrompt"));
              }}
            >
              <Form.Group className="mb-3" controlId="treatable">
                <Form.Label>{t("feedbackQuestion")}</Form.Label>
                <Form.Check
                  label={t("common:yes")}
                  checked={treatable}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTreatable(true);
                    }
                  }}
                  name="treatable"
                  type="radio"
                />
                <Form.Check
                  label={t("common:no")}
                  checked={!treatable}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTreatable(false);
                    }
                  }}
                  name="treatable"
                  type="radio"
                />
              </Form.Group>
              {!treatable && (
                <Form.Group className="mb-3" controlId="comment">
                  <Form.Label>{t("feedbackComment")}</Form.Label>
                  <Form.Control
                    as={TextareaAutosize}
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </Form.Group>
              )}

              <Button className="w-100" variant="outline-primary" type="submit">
                {t("common:submit")}
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
