import * as React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";

import AuthLayout from "../AuthLayout";
import { AuthError } from "../../utils/errors";
import { signIn } from "../../api/auth";
import { useTranslation } from "react-i18next";

function SignInPage() {
  const { t } = useTranslation(["user", "auth"]);
  const { onSuccessfulAuth, setLastUserId, setSession } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const from =
    location.state && location.state.from ? location.state.from.pathname : "/";

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const response = await signIn(email, password);
      // Check whether the sign in is final and contains all the necessary info
      if (response.hasOwnProperty("access_token")) {
        onSuccessfulAuth(response);
        navigate(from, { replace: true });
      } else {
        setLastUserId(response.challenge_parameters.USER_ID_FOR_SRP);
        setSession(response.session);
        navigate("/reset_password/temporary", { replace: true });
      }
    } catch (error) {
      if (error instanceof AuthError) {
        setErrorMessage(error.details[0]);
      }
      console.error(error.message);
    }
  }

  const body = (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>{t("user:email")}</Form.Label>
        <Form.Control
          type="email"
          autoFocus={true}
          autoComplete="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
          isInvalid={!!errorMessage}
        />
      </Form.Group>
      <Form.Group controlId="password" className="mb-3">
        <Form.Label>{t("auth:password")}</Form.Label>
        <Form.Control
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required
          isInvalid={!!errorMessage}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      <Button variant="primary" type="submit" className="w-100">
        {t("auth:signIn")}
      </Button>
    </Form>
  );

  const links = (
    <React.Fragment>
      <Link to="/sign_up">{t("auth:signUp")}</Link>
      <Link to="/reset_password">{t("auth:forgotPasswordLink")}</Link>
      <Link to="/sign_up/resend_confirmation_email">
        {t("auth:resendConfirmationEmailLink")}
      </Link>
    </React.Fragment>
  );

  return <AuthLayout title={t("auth:signIn")} body={body} footer={links} />;
}

export default SignInPage;
