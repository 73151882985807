import * as React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate, Link } from "react-router-dom";

import AuthLayout from "../AuthLayout";
import { AuthError } from "../../utils/errors";
import { confirmPasswordReset } from "../../api/auth";
import { useAuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

function ConfirmPasswordResetPage() {
  const { t } = useTranslation(["auth", "common"]);
  const { lastUserId } = useAuthContext();
  const navigate = useNavigate();

  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [confirmationCode, setConfirmationCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await confirmPasswordReset(
        lastUserId,
        password,
        passwordConfirmation,
        confirmationCode
      );
      navigate("/sign_in");
    } catch (error) {
      if (error instanceof AuthError) {
        setErrorMessage(error.details);
      }
      console.error(error.message);
    }
  }

  const body = (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="password" className="mb-3">
        <Form.Label>{t("newPassword")}</Form.Label>
        <Form.Control
          type="password"
          autoFocus={true}
          autoComplete="new-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required
          isInvalid={!!errorMessage}
        />
      </Form.Group>
      <Form.Group controlId="password_confirmation" className="mb-3">
        <Form.Label>{t("confirmNewPassword")}</Form.Label>
        <Form.Control
          type="password"
          autoComplete="new-password"
          value={passwordConfirmation}
          onChange={(e) => {
            setPasswordConfirmation(e.target.value);
          }}
          required
          isInvalid={!!errorMessage}
        />
      </Form.Group>
      <Form.Group controlId="confirmation_code" className="mb-3">
        <Form.Label>{t("confirmationCode")}</Form.Label>
        <Form.Control
          type="text"
          autoComplete="one-time-code"
          value={confirmationCode}
          onChange={(e) => {
            setConfirmationCode(e.target.value);
          }}
          required
          isInvalid={!!errorMessage}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      <Button variant="primary" type="submit" className="w-100">
        {t("common:submit")}
      </Button>
    </Form>
  );

  const links = (
    <React.Fragment>
      <Link to="/sign_in">{t("signIn")}</Link>
      <Link to="/sign_up">{t("signUp")}</Link>
      <Link to="/reset_password">{t("resetPasswordLink")}</Link>
    </React.Fragment>
  );

  return <AuthLayout title="Reset password" body={body} footer={links} />;
}

export default ConfirmPasswordResetPage;
