import * as React from "react";

import { Typeahead } from "react-bootstrap-typeahead";
import { initialPatient, Patient } from "../../api/patients";
import { usePatientsContext } from "../../contexts/PatientsContext";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { useTranslation } from "react-i18next";

function PatientTypeahead() {
  const { t } = useTranslation("patient");
  const { patients, currentPatient, setCurrentPatient, savePatient } =
    usePatientsContext();

  const { currentPatientScan, resetPatientScan } = usePatientScansContext();

  async function createPatientWrapper(patient_name: string) {
    try {
      savePatient(initialPatient(patient_name));
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Typeahead
      id="patient-id-typeahead"
      labelKey="patient_id"
      placeholder={t("selectPatient")}
      allowNew
      clearButton
      newSelectionPrefix={`${t("addNewPatient")}: `}
      selected={currentPatient.id ? [currentPatient] : []}
      options={patients}
      onChange={([selection]) => {
        if (selection === undefined) {
          setCurrentPatient(initialPatient(""));
          // Only reset the current patient scan if the data is not saved
          if (currentPatientScan.id) {
            resetPatientScan();
          }
          return;
        }
        //@ts-ignore Wrong typing
        if (selection.customOption) {
          // We need to create a new patient
          //@ts-ignore Wrong typing
          createPatientWrapper(selection["patient_id"]);
        } else {
          setCurrentPatient(selection as Patient);
          // Only reset the current patient scan if the data is not saved
          if (currentPatientScan.id !== undefined) {
            resetPatientScan();
          }
        }
      }}
    />
  );
}

export default PatientTypeahead;
