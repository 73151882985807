import * as React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { useTranslation } from "react-i18next";

interface QuantitativeParamsProps {}

function QuantitativeParams(props: QuantitativeParamsProps) {
  const { t } = useTranslation(["quantitativeParams", "sections", "common"]);
  const { currentPatientScan, setCurrentPatientScan } =
    usePatientScansContext();

  const totalTumorVolume = React.useMemo(() => {
    let total = 0;
    for (let category in currentPatientScan.tumor_volume) {
      total += currentPatientScan.tumor_volume[category];
    }
    return total;
  }, [currentPatientScan.tumor_volume]);

  React.useEffect(() => {
    const wholeBodySUVmax = Math.max(
      currentPatientScan.tumor_uptake["Prostate"]?.suv_max || 0,
      currentPatientScan.tumor_uptake["Pelvic LN"]?.suv_max || 0,
      currentPatientScan.tumor_uptake["Osseous"]?.suv_max || 0,
      currentPatientScan.tumor_uptake["Distant LN"]?.suv_max || 0,
      currentPatientScan.tumor_uptake["Visceral"]?.suv_max || 0
    );
    setCurrentPatientScan({
      ...currentPatientScan,
      tumor_uptake: {
        ...currentPatientScan.tumor_uptake,
        "Whole-Body": {
          ...currentPatientScan.tumor_uptake["Whole-Body"],
          suv_max: wholeBodySUVmax,
        },
      },
    });
  }, [
    currentPatientScan.tumor_uptake["Prostate"],
    currentPatientScan.tumor_uptake["Pelvic LN"],
    currentPatientScan.tumor_uptake["Osseous"],
    currentPatientScan.tumor_uptake["Distant LN"],
    currentPatientScan.tumor_uptake["Visceral"],
  ]);

  function psmaScore(value: number) {
    if (
      currentPatientScan.normal_uptake["Blood Pool"]?.suv_max == null ||
      currentPatientScan.normal_uptake["Liver"]?.suv_max == null ||
      currentPatientScan.normal_uptake["Parotid Glands"]?.suv_max == null
    ) {
      return 0;
    }

    if (value < currentPatientScan.normal_uptake["Blood Pool"].suv_max) {
      return 0;
    }
    if (
      value >= currentPatientScan.normal_uptake["Blood Pool"].suv_max &&
      value < currentPatientScan.normal_uptake["Liver"].suv_max
    ) {
      return 1;
    }
    if (
      value >= currentPatientScan.normal_uptake["Liver"].suv_max &&
      value < currentPatientScan.normal_uptake["Parotid Glands"].suv_max
    ) {
      return 2;
    }
    if (value >= currentPatientScan.normal_uptake["Parotid Glands"].suv_max) {
      return 3;
    }
  }

  function categoryName(category: string) {
    switch (category) {
      case "Blood Pool":
        return t("categoryLabels.bloodPool");
      case "Liver":
        return t("categoryLabels.liver");
      case "Parotid Glands":
        return t("categoryLabels.parotidGlands");
      case "Spleen":
        return t("categoryLabels.spleen");
      case "Prostate":
        return t("categoryLabels.prostate");
      case "Pelvic LN":
        return t("categoryLabels.pelvic");
      case "Osseous":
        return t("categoryLabels.bone");
      case "Distant LN":
        return t("categoryLabels.visceral");
      case "Whole-Body":
        return t("categoryLabels.wholeBody");
      default:
        return "";
    }
  }

  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">{t("sections:quantitativeParams")}</h3>
      </div>
      <Row className="mt-3">
        <Col>
          <h5>{t("normalUptake")}</h5>
        </Col>
      </Row>
      <Form style={{ marginTop: 12 }}>
        <Row className="my-2">
          <Form.Group as={Col} xs="2">
            <Form.Label className="mb-0">{t("tableHeader.organ")}</Form.Label>
          </Form.Group>
          <Col xs="8" className="px-0">
            <Row>
              <Form.Group as={Col} xs={{ span: 4, offset: 4 }}>
                <Form.Label className="mb-0">
                  {t("tableHeader.SUVmean")}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs="4">
                <Form.Label className="mb-0">
                  {t("tableHeader.SUVmax")}
                </Form.Label>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        {["Blood Pool", "Liver", "Parotid Glands", "Spleen"].map(
          (category, index) => (
            <Row key={index} className="my-2">
              <Form.Group as={Col} xs="2">
                <Form.Control
                  plaintext
                  readOnly
                  tabIndex={-1}
                  value={categoryName(category)}
                />
              </Form.Group>
              <Col xs="8" className="px-0">
                <Row>
                  <Form.Group as={Col} xs={{ span: 4, offset: 4 }}>
                    <Form.Control
                      disabled={!currentPatientScan}
                      type="number"
                      min="0"
                      step=".1"
                      pattern="^\d*(\.\d{0,1})?$"
                      value={
                        currentPatientScan.normal_uptake[category]?.suv_mean ||
                        ""
                      }
                      onChange={(e) => {
                        setCurrentPatientScan({
                          ...currentPatientScan,
                          normal_uptake: {
                            ...currentPatientScan.normal_uptake,
                            [category]: {
                              ...currentPatientScan.normal_uptake[category],
                              suv_mean: parseFloat(e.target.value) || 0,
                            },
                          },
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs="4">
                    <Form.Control
                      disabled={!currentPatientScan}
                      type="number"
                      min="0"
                      step=".1"
                      pattern="^\d*(\.\d{0,1})?$"
                      value={
                        currentPatientScan.normal_uptake[category]?.suv_max ||
                        ""
                      }
                      onChange={(e) => {
                        setCurrentPatientScan({
                          ...currentPatientScan,
                          normal_uptake: {
                            ...currentPatientScan.normal_uptake,
                            [category]: {
                              ...currentPatientScan.normal_uptake[category],
                              suv_max: parseFloat(e.target.value) || 0,
                            },
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          )
        )}
      </Form>
      <Row className="mt-3">
        <Col>
          <h5>{t("tumorUptake")}</h5>
        </Col>
      </Row>
      <Form style={{ marginTop: 12 }}>
        <Row className="my-2">
          <Form.Group as={Col} xs="2">
            <Form.Label className="mb-0">{t("tableHeader.organ")}</Form.Label>
          </Form.Group>
          <Col xs="8" className="px-0">
            <Row>
              <Form.Group as={Col} xs="4">
                <Form.Label className="mb-0">
                  {t("tableHeader.volume")}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs="4">
                <Form.Label className="mb-0">
                  {t("tableHeader.SUVmean")}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs="4">
                <Form.Label className="mb-0">
                  {t("tableHeader.SUVmax")}
                </Form.Label>
              </Form.Group>
            </Row>
          </Col>
          <Form.Group as={Col} xs="2">
            <Form.Label className="mb-0">
              {t("tableHeader.psmaScore")}
            </Form.Label>
          </Form.Group>
        </Row>
        {["Prostate", "Osseous", "Pelvic LN", "Distant LN"].map(
          (category, index) => (
            <Row key={index} className="my-2">
              <Form.Group as={Col} xs="2">
                <Form.Control
                  plaintext
                  readOnly
                  tabIndex={-1}
                  value={categoryName(category)}
                />
              </Form.Group>
              <Col xs="8" className="px-0">
                <Row>
                  <Form.Group as={Col} xs="4">
                    <Form.Control
                      disabled={!currentPatientScan}
                      type="number"
                      min="0"
                      step=".1"
                      pattern="^\d*(\.\d{0,1})?$"
                      value={currentPatientScan.tumor_volume[category] || ""}
                      onChange={(e) => {
                        setCurrentPatientScan({
                          ...currentPatientScan,
                          tumor_volume: {
                            ...currentPatientScan.tumor_volume,
                            [category]: parseFloat(e.target.value) || 0,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs="4">
                    <Form.Control
                      disabled={!currentPatientScan}
                      type="number"
                      min="0"
                      step=".1"
                      pattern="^\d*(\.\d{0,1})?$"
                      value={
                        currentPatientScan.tumor_uptake[category]?.suv_mean ||
                        ""
                      }
                      onChange={(e) => {
                        setCurrentPatientScan({
                          ...currentPatientScan,
                          tumor_uptake: {
                            ...currentPatientScan.tumor_uptake,
                            [category]: {
                              ...currentPatientScan.tumor_uptake[category],
                              suv_mean: parseFloat(e.target.value) || 0,
                            },
                          },
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs="4">
                    <Form.Control
                      disabled={!currentPatientScan}
                      type="number"
                      min="0"
                      step=".1"
                      pattern="^\d*(\.\d{0,1})?$"
                      value={
                        currentPatientScan.tumor_uptake[category]?.suv_max || ""
                      }
                      onChange={(e) => {
                        setCurrentPatientScan({
                          ...currentPatientScan,
                          tumor_uptake: {
                            ...currentPatientScan.tumor_uptake,
                            [category]: {
                              ...currentPatientScan.tumor_uptake[category],
                              suv_max: parseFloat(e.target.value) || 0,
                            },
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Form.Group as={Col} xs="2">
                <Form.Control
                  plaintext
                  readOnly
                  tabIndex={-1}
                  value={psmaScore(
                    currentPatientScan.tumor_uptake[category]?.suv_max || 0
                  )}
                />
              </Form.Group>
            </Row>
          )
        )}
        {["Visceral", "Whole-Body"].map((category, index) => (
          <Row key={index} className="my-2">
            <Form.Group as={Col} xs="2">
              <Form.Control
                plaintext
                readOnly
                tabIndex={-1}
                value={categoryName(category)}
              />
            </Form.Group>
            <Col xs="8" className="px-0">
              <Row>
                <Form.Group as={Col} xs="4">
                  {category === "Whole-Body" ? (
                    <Form.Control
                      style={{ paddingLeft: "0.75rem" }}
                      plaintext
                      readOnly
                      tabIndex={-1}
                      value={totalTumorVolume}
                    />
                  ) : (
                    <Form.Control
                      disabled={!currentPatientScan}
                      type="number"
                      min="0"
                      step=".1"
                      pattern="^\d*(\.\d{0,1})?$"
                      value={currentPatientScan.tumor_volume[category] || ""}
                      onChange={(e) => {
                        setCurrentPatientScan({
                          ...currentPatientScan,
                          tumor_volume: {
                            ...currentPatientScan.tumor_volume,
                            [category]: parseFloat(e.target.value) || 0,
                          },
                        });
                      }}
                    />
                  )}
                </Form.Group>
                <Form.Group as={Col} xs="4">
                  <Form.Control
                    disabled={!currentPatientScan}
                    type="number"
                    min="0"
                    step=".1"
                    pattern="^\d*(\.\d{0,1})?$"
                    value={
                      currentPatientScan.tumor_uptake[category]?.suv_mean || ""
                    }
                    onChange={(e) => {
                      setCurrentPatientScan({
                        ...currentPatientScan,
                        tumor_uptake: {
                          ...currentPatientScan.tumor_uptake,
                          [category]: {
                            ...currentPatientScan.tumor_uptake[category],
                            suv_mean: parseFloat(e.target.value) || 0,
                          },
                        },
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} xs="4">
                  {category === "Whole-Body" ? (
                    <Form.Control
                      style={{ paddingLeft: "0.75rem" }}
                      plaintext
                      readOnly
                      tabIndex={-1}
                      value={
                        currentPatientScan.tumor_uptake[category]?.suv_max || 0
                      }
                    />
                  ) : (
                    <Form.Control
                      disabled={!currentPatientScan}
                      type="number"
                      min="0"
                      step=".1"
                      pattern="^\d*(\.\d{0,1})?$"
                      value={
                        currentPatientScan.tumor_uptake[category]?.suv_max || ""
                      }
                      onChange={(e) => {
                        setCurrentPatientScan({
                          ...currentPatientScan,
                          tumor_uptake: {
                            ...currentPatientScan.tumor_uptake,
                            [category]: {
                              ...currentPatientScan.tumor_uptake[category],
                              suv_max: parseFloat(e.target.value) || 0,
                            },
                          },
                        });
                      }}
                    />
                  )}
                </Form.Group>
              </Row>
            </Col>
            <Form.Group as={Col} xs="2">
              <Form.Control
                plaintext
                readOnly
                tabIndex={-1}
                value={psmaScore(
                  currentPatientScan.tumor_uptake[category]?.suv_max || 0
                )}
              />
            </Form.Group>
          </Row>
        ))}
      </Form>
    </>
  );
}

export default QuantitativeParams;
