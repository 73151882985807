import React from "react";
import { usePatientsContext } from "../../contexts/PatientsContext";
import { Row, Form, Col, Table } from "react-bootstrap";
import useRecipCriteria from "./useRecipCriteria";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientRecipCriteriaContext } from "../../contexts/PatientRecipCriteriaContext";
import { useTranslation } from "react-i18next";
import { RecipCriteriaResponseEvaluation } from "../../constants/enums";

export default function RecipCriteria() {
  const { t } = useTranslation(["recipCriteria", "common", "sections"]);
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan } = usePatientScansContext();
  const {
    currentPatientScanRecipCriteria,
    setCurrentPatientScanRecipCriteria,
    saveCurrentPatientScanRecipCriteria,
  } = usePatientRecipCriteriaContext();

  const { responseEvaluation } = useRecipCriteria(
    currentPatientScan,
    currentPatientScanRecipCriteria
  );

  React.useEffect(() => {
    // Save recip criteria on page unload
    return () => {
      saveCurrentPatientScanRecipCriteria();
    };
  }, []);

  const recipCriteriaLabel = React.useMemo(() => {
    switch (responseEvaluation) {
      case RecipCriteriaResponseEvaluation.CompleteResponse:
        return `${t("completeResponse")} (${t("completeResponseCode")})`;
      case RecipCriteriaResponseEvaluation.PartialResponse:
        return `${t("partialResponse")} (${t("partialResponseCode")})`;
      case RecipCriteriaResponseEvaluation.StableDisease:
        return `${t("stableDisease")} (${t("stableDiseaseCode")})`;
      case RecipCriteriaResponseEvaluation.ProgressiveDisease:
        return `${t("progressiveDisease")} (${t("progressiveDiseaseCode")})`;
      case RecipCriteriaResponseEvaluation.NotApplicable:
        return t("common:notApplicable");
      default:
        console.warn("Unknown RECIP criteria label");
        return t("common:notApplicable");
    }
  }, [responseEvaluation]);

  const recipCriteriaColor = React.useMemo(() => {
    switch (responseEvaluation) {
      case RecipCriteriaResponseEvaluation.CompleteResponse:
        return "text-indigo";
      case RecipCriteriaResponseEvaluation.PartialResponse:
        return "text-success";
      case RecipCriteriaResponseEvaluation.StableDisease:
        return "text-info";
      case RecipCriteriaResponseEvaluation.ProgressiveDisease:
        return "text-danger";
      default:
        return "text-muted";
    }
  }, [responseEvaluation]);

  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">{t("sections:recipCriteria")}</h3>
      </div>
      <Form style={{ marginTop: 12 }} className="mb-5">
        <Row>
          <Form.Group as={Col} className="mb-3">
            <Form.Label className="mb-0">{t("volumeChange")}</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={
                currentPatientScanRecipCriteria.psma_positive_tumor_volume_change ||
                "n/a"
              }
              onChange={(e) => {
                setCurrentPatientScanRecipCriteria({
                  ...currentPatientScanRecipCriteria,
                  psma_positive_tumor_volume_change: e.target.value,
                  new_lesion_appearance:
                    e.target.value === "complete_response"
                      ? false
                      : currentPatientScanRecipCriteria.new_lesion_appearance,
                  new_lesion_location:
                    e.target.value === "complete_response"
                      ? null
                      : currentPatientScanRecipCriteria.new_lesion_location,
                });
              }}
            >
              <option disabled value="n/a">
                {t("volumeChangePlaceholder")}
              </option>
              <option value="complete_response">
                {t("volumeChangeOptions.completeResponse")}
              </option>
              <option value="partial_response">
                {t("volumeChangeOptions.partialResponse")}
              </option>
              <option value="stable_disease">
                {t("volumeChangeOptions.stableDisease")}
              </option>
              <option value="progressive_disease">
                {t("volumeChangeOptions.progressiveDisease")}
              </option>
            </Form.Select>
            <Form.Text>
              {`${t("recipCriteriaReference")} `}
              <a
                href="https://recip-criteria.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://recip-criteria.com
              </a>
            </Form.Text>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("newLesionAppearance")}</Form.Label>
            <div style={{ marginTop: "6px", marginBottom: "7px" }}>
              <Form.Check
                label={t("common:yes")}
                disabled={
                  !currentPatient.id ||
                  currentPatientScanRecipCriteria.psma_positive_tumor_volume_change ===
                    "complete_response"
                }
                inline
                checked={
                  currentPatientScanRecipCriteria.new_lesion_appearance || false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setCurrentPatientScanRecipCriteria({
                      ...currentPatientScanRecipCriteria,
                      new_lesion_appearance: true,
                    });
                  }
                }}
                name="new_lesion_appearance"
                type="radio"
              />
              <Form.Check
                label={t("common:no")}
                disabled={
                  !currentPatient.id ||
                  currentPatientScanRecipCriteria.psma_positive_tumor_volume_change ===
                    "complete_response"
                }
                inline
                checked={
                  !currentPatientScanRecipCriteria.new_lesion_appearance ||
                  false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setCurrentPatientScanRecipCriteria({
                      ...currentPatientScanRecipCriteria,
                      new_lesion_appearance: false,
                      new_lesion_location: null,
                    });
                  }
                }}
                name="new_lesion_appearance"
                type="radio"
              />
            </div>
          </Form.Group>
          {currentPatientScanRecipCriteria.new_lesion_appearance && (
            <Form.Group as={Col}>
              <Form.Label className="mb-0">{t("newLesionLocation")}</Form.Label>
              <Form.Control
                type="text"
                disabled={!currentPatient.id}
                value={
                  currentPatientScanRecipCriteria.new_lesion_location || ""
                }
                onChange={(e) => {
                  setCurrentPatientScanRecipCriteria({
                    ...currentPatientScanRecipCriteria,
                    new_lesion_location: e.target.value,
                  });
                }}
              />
            </Form.Group>
          )}
        </Row>
      </Form>
      <Row className="mb-5">
        <Col>
          <h5>
            {`${t("responseEvaluation")} `}
            <b className={recipCriteriaColor}>{recipCriteriaLabel}</b>
          </h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{t("newLesionAppearanceDefinition.header")}</p>
          <ul>
            <li>{t("newLesionAppearanceDefinition.option1")}</li>
            <ul>
              <li>{t("newLesionAppearanceDefinition.option1.1")}</li>
              <li>{t("newLesionAppearanceDefinition.option1.2")}</li>
              <li>{t("newLesionAppearanceDefinition.option1.3")}</li>
              <li>{t("newLesionAppearanceDefinition.option1.4")}</li>
            </ul>
            <li>{t("newLesionAppearanceDefinition.option2")}</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <Table striped hover className="align-middle m-0">
            <tbody>
              <tr>
                <td>{t("completeResponse")}</td>
                <td style={{ minWidth: 100 }}>
                  <b>{t("completeResponseCode")}</b>
                </td>
                <td>{t("completeResponseDescription")}</td>
              </tr>
              <tr>
                <td>{t("partialResponse")}</td>
                <td>
                  <b>{t("partialResponseCode")}</b>
                </td>
                <td>{t("partialResponseDescription")}</td>
              </tr>
              <tr>
                <td>{t("progressiveDisease")}</td>
                <td>
                  <b>{t("progressiveDiseaseCode")}</b>
                </td>
                <td>{t("progressiveDiseaseDescription")}</td>
              </tr>
              <tr>
                <td>{t("stableDisease")}</td>
                <td>
                  <b>{t("stableDiseaseCode")}</b>
                </td>
                <td>
                  {`${t("stableDiseaseDescription1")} `}
                  <i>{t("common:or")}</i>
                  <br />
                  {`${t("stableDiseaseDescription2")} `}
                  <i>{t("common:or")}</i>
                  <br />
                  {`${t("stableDiseaseDescription3")} `}
                  <i>{t("common:or")}</i>
                  <br />${t("stableDiseaseDescription4")}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
