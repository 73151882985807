import { Navigate, createBrowserRouter } from "react-router-dom";
import S3Fallback from "./components/S3Fallback";
import LandingPage from "./components/pages/LandingPage";
import SignInPage from "./components/pages/SignInPage";
import ConfirmEmailPage from "./components/pages/ConfirmEmailPage";
import ResendConfirmationInstructionsPage from "./components/pages/ResendConfirmationInstructionsPage";
import SignUpPage from "./components/pages/SignUpPage";
import RequestPasswordResetPage from "./components/pages/RequestPasswordResetPage";
import ConfirmPasswordResetPage from "./components/pages/ConfirmPasswordResetPage";
import TemporaryPasswordResetPage from "./components/pages/TemporaryPasswordResetPage";
import HomePageContextProviders from "./components/pages/HomePageContextProviders";
import RequireProduct from "./components/RequireProduct";
import PatientInformation from "./components/PatientInformation";
import ClinicalData from "./components/ClinicalData/ClinicalData";
import TechnicalParams from "./components/TechnicalParams";
import DescriptiveFindings from "./components/DescriptiveFindings";
import MiTNM from "./components/MiTNM";
import LuPSMAEligibility from "./components/ImagingFindings/LuPSMAEligibility";
import LesionTracking from "./components/ImagingFindings/LesionTracking";
import RecipCriteria from "./components/RecipCriteria/RecipCriteria";
import QuantitativeParams from "./components/ImagingFindings/QuantitativeParams";
import RequireAuth from "./components/RequireAuth";
import NewInvitePage from "./components/pages/NewInvitePage";
import UserSettingsPage from "./components/pages/UserSettingsPage";
import OrgSettingsPage from "./components/pages/OrgSettingsPage";
import PatientScansManagement from "./components/OrgSettings/PatientScansManagement";
import UserManagement from "./components/OrgSettings/UserManagement";
import PatientsPage from "./components/PatientsPage";
import PatientPage from "./components/PatientPage";
import PatientScansPage from "./components/PatientScansPage";
import HomePage from "./components/pages/HomePage";
import PatientScansComparisonManagement from "./components/OrgSettings/PatientScanComparisonsManagement";
import React from "react";
import HomePageV2 from "./components/pages/HomePageV2";
import HomePageContextProvidersV2 from "./components/pages/HomePageContextProvidersV2";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <S3Fallback>
        <LandingPage />
      </S3Fallback>
    ),
  },
  {
    path: "sign_in",
    element: <SignInPage />,
  },
  // Sign up
  {
    path: "sign_up",
    element: <SignUpPage />,
  },
  // Email confirmation
  {
    path: "sign_up/confirm_email",
    element: <ConfirmEmailPage />,
  },
  {
    path: "sign_up/resend_confirmation_email",
    element: <ResendConfirmationInstructionsPage />,
  },
  // Reset password flow
  {
    path: "reset_password",
    element: <RequestPasswordResetPage />,
  },
  {
    path: "reset_password/confirm",
    element: <ConfirmPasswordResetPage />,
  },
  {
    path: "reset_password/temporary",
    element: <TemporaryPasswordResetPage />,
  },
  // User invitation
  {
    path: "invite/new",
    element: (
      <RequireAuth role="org_admin">
        <NewInvitePage />
      </RequireAuth>
    ),
  },
  // Settings
  {
    path: "settings",
    children: [
      {
        path: "user",
        element: (
          <RequireAuth>
            <UserSettingsPage />
          </RequireAuth>
        ),
      },
      {
        path: "org",
        element: (
          <RequireAuth role="org_admin">
            <OrgSettingsPage />
          </RequireAuth>
        ),
        children: [
          {
            path: "patient_scans",
            element: <PatientScansManagement />,
          },
          {
            path: "patient_scan_comparisons",
            element: <RequireProduct product="clinic" />,
            children: [
              {
                index: true,
                element: <PatientScansComparisonManagement />,
              },
            ],
          },
          {
            path: "users",
            element: <UserManagement />,
          },
        ],
      },
    ],
  },
  // Logged-in user section: the actual app
  {
    path: "home",
    element: <HomePageContextProviders />,
    children: [
      {
        element: <HomePage />,
        children: [
          {
            element: <RequireProduct product="clinic" />,
            children: [
              // Old routing - need to replace it
              {
                index: true,
                element: <Navigate to="/home/patient_info" replace />,
              },
              {
                path: "patient_info",
                element: <PatientInformation />,
              },
              {
                path: "clinical_data",
                element: <ClinicalData />,
              },
              {
                path: "technical_params",
                element: <TechnicalParams />,
              },
              {
                path: "imaging_findings",
                children: [
                  {
                    path: "descriptive_findings",
                    element: <DescriptiveFindings />,
                  },
                  {
                    path: "tnm_classification",
                    children: [
                      {
                        index: true,
                        element: (
                          <Navigate
                            to="/home/imaging_findings/tnm_classification/local_lesions"
                            replace
                          />
                        ),
                      },
                      {
                        path: ":mitnmSection",
                        element: <MiTNM />,
                      },
                    ],
                  },
                  {
                    path: "lupsma_eligibility",
                    element: <LuPSMAEligibility />,
                  },
                  {
                    path: "lesion_tracking",
                    children: [
                      {
                        index: true,
                        element: (
                          <Navigate
                            to="/home/imaging_findings/lesion_tracking/local_lesions"
                            replace
                          />
                        ),
                      },
                      {
                        path: ":mitnmSection",
                        element: <LesionTracking />,
                      },
                    ],
                  },
                  {
                    path: "recip_criteria",
                    element: <RecipCriteria />,
                  },
                  {
                    path: "quantitative_parameters",
                    element: <QuantitativeParams />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  // New routing starts here
  {
    path: "patients",
    element: <HomePageContextProvidersV2 />,
    children: [
      {
        element: <HomePageV2 />,
        children: [
          {
            index: true,
            element: <PatientsPage />,
          },
          {
            path: ":patientId",
            children: [
              {
                index: true,
                element: <PatientPage />,
              },
              {
                path: "scans",
                children: [
                  {
                    index: true,
                    element: <PatientScansPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
