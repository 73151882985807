import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as dicomParser from "dicom-parser";
import { DateTime } from "luxon";
import { usePatientsContext } from "../contexts/PatientsContext";
import { usePatientScansContext } from "../contexts/PatientScansContext";
import { ImagingModality, Radiopharmaceutical } from "../constants/enums";
import { useLogContext } from "./LogToast/LogContext";
import { useTranslation } from "react-i18next";

interface ImportDICOMModalProps {
  show: boolean;
  onHide: () => void;
}

export default function ImportDICOMModal({
  show,
  onHide,
}: ImportDICOMModalProps): React.ReactElement {
  const { t } = useTranslation(["importDicom", "patient", "common"]);
  const { log } = useLogContext();
  const { currentPatient, setCurrentPatient } = usePatientsContext();
  const { currentPatientScan, setCurrentPatientScan } =
    usePatientScansContext();

  const [dicomFile, setDicomFile] = React.useState<File | undefined>();
  const [fileParsed, setFileParsed] = React.useState(false);
  const [modality, setModality] = React.useState<string | null>(null);
  const [patientName, setPatientName] = React.useState<string | null>(null);
  const [dateOfBirth, setDateOfBirth] = React.useState<DateTime | null>(null);
  const [location, setLocation] = React.useState<string | null>(null);
  const [scannerModel, setScannerModel] = React.useState<string | null>(null);
  const [reconstructionMethod, setReconstructionMethod] = React.useState<
    string | null
  >(null);
  const [radiopharmaceutical, setRadiopharmaceutical] = React.useState<
    string | null
  >(null);
  const [injectedActivity, setInjectedActivity] = React.useState<number | null>(
    null
  );
  const [injectedPI, setinjectedPI] = React.useState<number | null>(null);

  // Update metadata every time the file is updated
  React.useEffect(() => {
    importMetadata();
  }, [dicomFile]);

  function updatePatient() {
    setCurrentPatient({
      ...currentPatient,
      patient_id: patientName == null ? currentPatient.patient_id : patientName,
      date_of_birth:
        dateOfBirth == null ? currentPatient.date_of_birth : dateOfBirth,
    });
  }

  function updatePatientScan() {
    setCurrentPatientScan({
      ...currentPatientScan,
      modality:
        modality != null
          ? (modality as ImagingModality)
          : currentPatientScan.modality,
      location: location != null ? location : currentPatientScan.location,
      scanner_model:
        scannerModel != null ? scannerModel : currentPatientScan.scanner_model,
      reconstruction_method:
        reconstructionMethod != null
          ? reconstructionMethod
          : currentPatientScan.reconstruction_method,
      radiopharmaceutical:
        radiopharmaceutical != null
          ? (radiopharmaceutical as Radiopharmaceutical)
          : currentPatientScan.radiopharmaceutical,
      injected_activity:
        injectedActivity != null
          ? injectedActivity
          : currentPatientScan.injected_activity,
      injected_time:
        injectedPI != null ? injectedPI : currentPatientScan.injected_time,
    });
  }

  function cleanup() {
    setFileParsed(false);
    setModality(null);
    setPatientName(null);
    setDateOfBirth(null);
    setLocation(null);
    setScannerModel(null);
    setReconstructionMethod(null);
    setRadiopharmaceutical(null);
    setInjectedActivity(null);
    setinjectedPI(null);
  }

  function hideModal() {
    cleanup();
    setDicomFile(undefined);
    onHide();
  }

  async function importMetadata() {
    cleanup();
    if (dicomFile == null) {
      return;
    }

    const arrayBuffer = await dicomFile.arrayBuffer();

    const byteArray = new Uint8Array(arrayBuffer);
    const dataSet = dicomParser.parseDicom(byteArray);

    if (dataSet.warnings.length > 0) {
      console.warn(`warnings:\n${dataSet.warnings.join("\n")}`);
    }

    let rawRadiopharmaceuticalInformationSequence = null;
    let rawRadionuclideCodeSequence = null;

    if (dataSet.elements.x00540016?.items != null) {
      rawRadiopharmaceuticalInformationSequence =
        dataSet.elements.x00540016.items[0].dataSet;
      if (
        rawRadiopharmaceuticalInformationSequence?.elements.x00540300.items !=
        null
      ) {
        rawRadionuclideCodeSequence =
          rawRadiopharmaceuticalInformationSequence.elements.x00540300.items[0]
            .dataSet;
      }
    }

    // Patient ID
    setPatientName(dataSet.string("x00100010") || null);

    // Birth date
    const rawBirthDate = dataSet.string("x00100030");
    setDateOfBirth(
      rawBirthDate ? DateTime.fromFormat(rawBirthDate, "yyyyMMdd") : null
    );

    // Location
    setLocation(dataSet.string("x00081010") || null);

    // Scanner model
    const manufacturer = dataSet.string("x00080070");
    const model = dataSet.string("x00081090");
    setScannerModel(
      !manufacturer && !model ? null : `${manufacturer} ${model}`
    );

    // Reconstruction method
    setReconstructionMethod(dataSet.string("x00541103") || null);

    // Radiopharmaceutical
    const rawRadiopharmaceutical =
      rawRadiopharmaceuticalInformationSequence?.string("x00180031") || "";
    const rawCodeMeaning =
      rawRadionuclideCodeSequence?.string("x00080104") || "";
    setRadiopharmaceutical(
      !rawRadiopharmaceutical && !rawCodeMeaning
        ? null
        : `${rawRadiopharmaceutical} ${rawCodeMeaning}`
    );

    // Injected activity - divide by 1M and round. Normal range should be within 60-200
    const rawInjectedActivity =
      rawRadiopharmaceuticalInformationSequence?.string("x00181074") == null
        ? NaN
        : parseFloat(
            rawRadiopharmaceuticalInformationSequence!.string("x00181074")!
          );

    if (Number.isNaN(rawInjectedActivity)) {
      setInjectedActivity(null);
    } else {
      setInjectedActivity(Math.round(rawInjectedActivity / 10 ** 6));
    }

    // Injected pi
    const rawSeriesTime = dataSet.string("x00080031")?.split(".")[0] || ""; // Format: HHMMSS.000000
    const rawRadiopharmaceuticalStartTime =
      rawRadiopharmaceuticalInformationSequence
        ?.string("x00181072")
        ?.split(".")[0] || "";

    if (rawSeriesTime && rawRadiopharmaceuticalStartTime) {
      const seriesTime = DateTime.fromFormat(
        rawSeriesTime.toString(),
        "hhmmss"
      );
      const radiopharmaceuticalStartTime = DateTime.fromFormat(
        rawRadiopharmaceuticalStartTime.toString(),
        "hhmmss"
      );
      setinjectedPI(
        Math.round(
          seriesTime.diff(radiopharmaceuticalStartTime, "minutes").minutes
        )
      );
    } else {
      setinjectedPI(null);
    }

    // Modality
    setModality(dataSet.string("x00080060") || null);

    setFileParsed(true);
  }

  return (
    <Modal show={show} onHide={hideModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("importDicom:header")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className="mb-2">
            <Col>
              <Form.Label className="mb-0">
                {t("importDicom:dicomFile")}
              </Form.Label>
              <Form.Control
                disabled={currentPatient == null || currentPatientScan == null}
                type="file"
                accept="application/dicom"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDicomFile(e.target.files ? e.target.files[0] : undefined)
                }
              />
            </Col>
          </Form.Group>
          {fileParsed && (
            <>
              <Form.Group as={Row}>
                <Col xs="4">
                  <Form.Label className="col-form-label">
                    {t("patient:patientName")}
                  </Form.Label>
                </Col>
                <Col xs="8">
                  <Form.Control
                    plaintext
                    readOnly
                    value={patientName || t("common:notFound")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col xs="4">
                  <Form.Label className="col-form-label">
                    {t("patient:dateOfBirth")}
                  </Form.Label>
                </Col>
                <Col xs="8">
                  <Form.Control
                    plaintext
                    readOnly
                    value={
                      dateOfBirth?.toLocaleString(DateTime.DATE_SHORT) ||
                      t("common:notFound")
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col xs="4">
                  <Form.Label className="col-form-label">
                    {t("patient:scannerLocation")}
                  </Form.Label>
                </Col>
                <Col xs="8">
                  <Form.Control
                    plaintext
                    readOnly
                    value={location || t("common:notFound")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col xs="4">
                  <Form.Label className="col-form-label">
                    {t("patient:scannerModel")}
                  </Form.Label>
                </Col>
                <Col xs="8">
                  <Form.Control
                    plaintext
                    readOnly
                    value={scannerModel || t("common:notFound")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col xs="4">
                  <Form.Label className="col-form-label">
                    {t("patient:modality")}
                  </Form.Label>
                </Col>
                <Col xs="8">
                  <Form.Control
                    plaintext
                    readOnly
                    value={modality || t("common:notFound")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col xs="4">
                  <Form.Label className="col-form-label">
                    {t("patient:reconstructionMethod")}
                  </Form.Label>
                </Col>
                <Col xs="8">
                  <Form.Control
                    plaintext
                    readOnly
                    value={reconstructionMethod || t("common:notFound")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col xs="4">
                  <Form.Label className="col-form-label">
                    {t("patient:radiopharmaceutical")}
                  </Form.Label>
                </Col>
                <Col xs="8">
                  <Form.Control
                    plaintext
                    readOnly
                    value={radiopharmaceutical || t("common:notFound")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col xs="4">
                  <Form.Label className="col-form-label">
                    {t("patient:injectedActivity")}
                  </Form.Label>
                </Col>
                <Col xs="8">
                  <Form.Control
                    plaintext
                    readOnly
                    value={injectedActivity || t("common:notFound")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col xs="4">
                  <Form.Label className="col-form-label">
                    {t("patient:injectedTime")}
                  </Form.Label>
                </Col>
                <Col xs="8">
                  <Form.Control
                    plaintext
                    readOnly
                    value={injectedPI || t("common:notFound")}
                  />
                </Col>
              </Form.Group>
              <Row className="mb-2">
                <Col md>
                  <Button
                    variant="success"
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      updatePatient();
                      updatePatientScan();
                      log(t("successPrompt"));
                      hideModal();
                    }}
                  >
                    {t("common:import")}
                  </Button>
                </Col>
                <Col md>
                  <Button
                    variant="secondary"
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      hideModal();
                    }}
                  >
                    {t("common:cancel")}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
