import { DateTime } from "luxon";
import { parseDate } from "../utils/dateUtils";
import { APIError } from "../utils/errors";

export const gleasonScores = ["6", "7a", "7b", "8", "9", "10"] as const;
export type GleasonScore = typeof gleasonScores[number];

export interface Patient {
  id: number | null;
  patient_id: string;
  org_id: Number | null;
  created_by_id: string | null;
  created_at: DateTime | null;
  updated_at: DateTime | null;
  date_of_birth: DateTime | null;
  study_id: string | null;
  diagnosis_date: DateTime | null;
  gleason_score: GleasonScore | null;
  initial_psa_value: number | null;
  c_tnm: string | null;
}

export function initialPatient(patient_id: string): Patient {
  return {
    id: null,
    patient_id,
    org_id: null,
    created_by_id: null,
    created_at: null,
    updated_at: null,
    date_of_birth: null,
    study_id: null,
    diagnosis_date: null,
    gleason_score: null,
    initial_psa_value: null,
    c_tnm: null,
  };
}

export function serializeToPatient(jsonResponse: any): Patient {
  return {
    ...initialPatient(""),
    ...jsonResponse,
    created_at: parseDate(jsonResponse.created_at),
    updated_at: parseDate(jsonResponse.updated_at),
    date_of_birth: parseDate(jsonResponse.date_of_birth),
    diagnosis_date: parseDate(jsonResponse.diagnosis_date),
  };
}

export function serializeFromPatient(p: Patient): string {
  return JSON.stringify({
    ...p,
    created_at: p.created_at?.toISO(),
    updated_at: p.updated_at?.toISO(),
    date_of_birth: p.date_of_birth?.toISO(),
    diagnosis_date: p.diagnosis_date?.toISO(),
  });
}

export async function getAllPatients(accessToken: string): Promise<Patient[]> {
  const response = await fetch("/api/patients", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse.map(serializeToPatient);
}

export async function createPatient(accessToken: string, patient: Patient) {
  const response = await fetch("/api/patients", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serializeFromPatient(patient),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return serializeToPatient(jsonResponse);
}

export async function updatePatient(
  accessToken: string,
  patientId: number,
  patient: Patient
) {
  const response = await fetch(`/api/patients/${patientId}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serializeFromPatient(patient),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function getPatientById(
  accessToken: string,
  patientId: number
): Promise<Patient> {
  const response = await fetch(`/api/patients/${patientId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return serializeToPatient(jsonResponse);
}
