import React from "react";
import { Coordinates, EllipseCoordinates } from "../../api/patientScans";
import { Button, Form, InputGroup, Overlay } from "react-bootstrap";
import BootstrapIcon from "../BootstrapIcon";
import UpdatingPopover from "./UpdatingPopover";
import { useTranslation } from "react-i18next";

interface SUVmaxOverlayProps {
  show: boolean;
  top: number;
  left: number;
  onHide: () => void;
  markerId?: string;
  marker?: Coordinates | EllipseCoordinates;
  updateSUVmax: (markerId: string, newSUVmax: number) => void;
}

export default function SUVmaxOverlay({
  show,
  onHide,
  top,
  left,
  markerId,
  marker,
  updateSUVmax,
}: SUVmaxOverlayProps) {
  const { t } = useTranslation("common");
  const ref = React.useRef(null);
  const [localSUVmax, setLocalSUVmax] = React.useState(0);

  React.useEffect(() => {
    if (marker != null) {
      setLocalSUVmax(marker.suvMax || 0);
    }
  }, [marker]);

  return (
    <>
      <div
        ref={ref}
        style={{
          position: "relative",
          left: left,
          top: top,
          height: 1,
          width: 1,
        }}
      ></div>
      <Overlay target={ref} show={show} placement="top">
        {/* @ts-ignore No idea what's going on there */}
        <UpdatingPopover>
          <InputGroup size="sm">
            <InputGroup.Text>{t("common:suvMax")}</InputGroup.Text>
            <Form.Control
              disabled={!marker || !markerId}
              type="number"
              size="sm"
              min="0"
              step=".1"
              style={{ width: "5rem" }}
              pattern="^\d*(\.\d{0,1})?$"
              value={localSUVmax || ""}
              onChange={(e) => {
                setLocalSUVmax(parseFloat(e.target.value) || 0);
              }}
            />
            <Button
              variant="outline-success"
              disabled={!marker || !markerId}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                if (markerId != null) {
                  updateSUVmax(markerId, localSUVmax);
                }
                onHide();
              }}
            >
              <BootstrapIcon name="check2" size={16} />
            </Button>
          </InputGroup>
        </UpdatingPopover>
      </Overlay>
    </>
  );
}
