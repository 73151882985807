import React from "react";
import { useParams } from "react-router";

export function useCurrentPatientId() {
  const { patientId } = useParams();

  return React.useMemo(
    () =>
      patientId && parseInt(patientId) != null && !isNaN(parseInt(patientId))
        ? parseInt(patientId)
        : null,
    [patientId]
  );
}

export function useCurrentScanId() {
  const { scanId } = useParams();

  return React.useMemo(
    () =>
      scanId && parseInt(scanId) != null && !isNaN(parseInt(scanId))
        ? parseInt(scanId)
        : null,
    [scanId]
  );
}
