import { DateTime } from "luxon";
import { parseDate } from "../utils/dateUtils";
import { APIError } from "../utils/errors";

export interface PatientScanComparison {
  id: number | null;
  patient_id: number | null;
  created_by_id: number | null;
  first_scan_id: number | null;
  second_scan_id: number | null;
  created_at: DateTime | null;
  updated_at: DateTime | null;
}

export function initialPatientScanComparison(): PatientScanComparison {
  return {
    id: null,
    patient_id: null,
    created_by_id: null,
    first_scan_id: null,
    second_scan_id: null,
    created_at: null,
    updated_at: null,
  };
}

export function serializeToPatientScanComparison(
  jsonResponse: any
): PatientScanComparison {
  return {
    ...jsonResponse,
    created_at: parseDate(jsonResponse.created_at),
    updated_at: parseDate(jsonResponse.updated_at),
  };
}

export function serializeFromPatientScanComparison(
  comparison: PatientScanComparison
): string {
  return JSON.stringify({
    ...comparison,
    created_at: comparison.created_at?.toISO(),
    updated_at: comparison.updated_at?.toISO(),
  });
}

export async function createPatientScanComparison(
  accessToken: string,
  patientId: number,
  comparison: PatientScanComparison
): Promise<any> {
  const response = await fetch(`/api/patients/${patientId}/scan_comparisons`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serializeFromPatientScanComparison(comparison),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function deletePatientScanComparison(
  accessToken: string,
  patientId: number,
  comparison: PatientScanComparison
) {
  const response = await fetch(
    `/api/patients/${patientId}/scan_comparisons/${comparison.id}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function getScanComparisonsForPatient(
  accessToken: string,
  patientId: number
): Promise<PatientScanComparison[]> {
  const response = await fetch(`/api/patients/${patientId}/scan_comparisons`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse.map(serializeToPatientScanComparison);
}
