import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import BootstrapIcon from "./BootstrapIcon";
import { usePatientsContext } from "../contexts/PatientsContext";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PatientsPage(): React.ReactElement {
  const { t } = useTranslation(["patient", "common"]);
  const { patients } = usePatientsContext();
  const navigate = useNavigate();

  return (
    <>
      {/* Header */}
      <Row style={{ height: 85 }}>
        <Col md className="d-flex">
          <h3 className="my-auto">{t("patients")}</h3>
        </Col>
        <Col md className="d-flex justify-content-end">
          <Button variant="outline-secondary" className="my-auto">
            <BootstrapIcon name="search" className="me-1" />
            Search
          </Button>
          <Button
            variant="outline-primary"
            className="my-auto ms-2"
            onClick={() => {
              navigate("/patients/new");
            }}
          >
            <BootstrapIcon name="person-add" className="me-1" />
            {t("newPatient")}
          </Button>
        </Col>
      </Row>
      {/* Content */}
      <Form style={{ marginTop: 12 }}>
        {patients.length === 0 ? (
          <p>{t("noPatientsPlaceholder")}</p>
        ) : (
          <Row>
            <Form.Group as={Col} xs="3">
              <Form.Label className="mb-0">{t("patientId")}</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs="3">
              <Form.Label className="mb-0">{t("dateOfBirth")}</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs="6">
              <Form.Label className="mb-0">{t("common:actions")}</Form.Label>
            </Form.Group>
          </Row>
        )}
        {patients.map((patient) => (
          <Row key={`patients-${patient.id}`}>
            <Form.Group as={Col} xs="3">
              <Form.Control plaintext readOnly value={patient.patient_id} />
            </Form.Group>
            <Form.Group as={Col} xs="3">
              <Form.Control
                plaintext
                readOnly
                value={patient.date_of_birth?.toLocaleString(
                  DateTime.DATE_SHORT
                )}
              />
            </Form.Group>
            <Form.Group as={Col} xs="6">
              <Link
                to={`/patients/${patient.id}`}
                className="link-secondary me-3"
              >
                <BootstrapIcon name="pencil" className="me-1" />
                {t("common:edit")}
              </Link>
              <Link
                to={`/patients/${patient.id}/scans`}
                className="link-secondary me-3"
              >
                <BootstrapIcon name="binoculars" className="me-1" />
                {t("viewScans")}
              </Link>
              <Link
                to={`/patients/${patient.id}/scans/new`}
                className="link-success me-3"
              >
                <BootstrapIcon name="file-earmark-plus" className="me-1" />
                {t("newScan")}
              </Link>
            </Form.Group>
          </Row>
        ))}
      </Form>
    </>
  );
}
