import * as React from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import * as Excel from "exceljs";
import { useAuthContext } from "../../contexts/AuthContext";
import { shortRegionName, ALL_TNM_REGIONS } from "../../constants/regionNames";
import { saveAs } from "file-saver";
import {
  getOrgPatientFeedback,
  getOrgPatients,
  getOrgPatientScans,
  getOrgStudyParticipations,
  getOrgUsers,
} from "../../api/orgs";
import { PatientScan, PatientScanMarkerInfo } from "../../api/patientScans";
import { ScanType } from "../../constants/enums";
import { Patient } from "../../api/patients";
import { StudyParticipation } from "../../api/studyParticipations";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/stringUtils";

function ResearchPatientScansManagement() {
  const { t } = useTranslation([
    "patient",
    "studyParticipation",
    "luPSMAEligibility",
    "common",
  ]);
  const { getAccessToken, currentUser } = useAuthContext();

  const [patients, setPatients] = React.useState<Patient[]>([]);
  const [patientScans, setPatientScans] = React.useState<PatientScan[]>([]);
  const [users, setUsers] = React.useState<any[]>([]);
  const [studyParticipations, setStudyParticipations] = React.useState<
    StudyParticipation[]
  >([]);
  const [patientFeedback, setPatientFeedback] = React.useState<any[]>([]);

  async function fetchData() {
    const accessToken = await getAccessToken();
    const users = await getOrgUsers(accessToken, currentUser.org_id);
    const newPatients = await getOrgPatients(accessToken, currentUser.org_id);
    const newStudyParticipations = await getOrgStudyParticipations(
      accessToken,
      currentUser.org_id
    );
    const newPatientScans = await getOrgPatientScans(
      accessToken,
      currentUser.org_id
    );
    const newPatientFeedback = await getOrgPatientFeedback(
      accessToken,
      currentUser.org_id
    );
    setPatients(newPatients);
    setStudyParticipations(newStudyParticipations);
    setPatientScans(newPatientScans);
    setPatientFeedback(newPatientFeedback);
    setUsers(users);
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  function patientScanToTableRow(patientScan: PatientScan) {
    const patientName =
      patients.find((p) => p.id === patientScan.patient_id)?.patient_id ||
      patientScan.patient_id;

    const radiotracer =
      patientScan.scan_type === ScanType.REGULAR
        ? patientScan.radiotracer
        : `${patientScan.metadata.firstScanRadiotracer}-${patientScan.metadata.secondScanRadiotracer}`;

    const user = users.find((u) => u.id === patientScan.created_by_id);

    const studyParticipation = studyParticipations.find(
      (s) => s.user_id === patientScan.created_by_id
    );

    return (
      <tr key={patientScan.id}>
        <td>{patientName}</td>
        <td>
          {studyParticipation && studyParticipation.group
            ? studyParticipation.group
            : t("common:notApplicable")}
        </td>
        <td>
          {user.first_name} {user.last_name}
        </td>
        <td>{patientScan.scan_type}</td>
        <td>{radiotracer}</td>
        <td>{patientScan.modality || t("common:notApplicable")}</td>
        <td>
          {(patientScan.updated_at &&
            patientScan.updated_at
              .plus(patientScan.updated_at.diffNow())
              .toRelative()) ||
            t("common:notApplicable")}
        </td>
      </tr>
    );
  }

  function patientScanToExcelRow(patientScan: PatientScan) {
    const patientName =
      patients.find((p) => p.id === patientScan.patient_id)?.patient_id ||
      patientScan.patient_id;

    const radiotracer =
      patientScan.scan_type === ScanType.REGULAR
        ? patientScan.radiotracer
        : `${patientScan.metadata.firstScanRadiotracer}-${patientScan.metadata.secondScanRadiotracer}`;

    const affectedRegions: { [key: string]: number } = {};
    for (let image of [
      "prostate_tumor",
      "pelvic_lymph_node_metastases",
      "bone_metastases",
      "other_organ_metastases",
    ]) {
      if (!patientScan.marker_info.hasOwnProperty(image)) {
        continue;
      }
      let markerCountByKeys =
        image === "other_organ_metastases"
          ? ["marker_count_by_organ", "marker_count_by_lymph_node"]
          : ["marker_count_by_region"];
      for (let countByNodeKey of markerCountByKeys) {
        if (
          !patientScan.marker_info[
            image as keyof PatientScanMarkerInfo
          ].hasOwnProperty(countByNodeKey)
        ) {
          continue;
        }
        //@ts-ignore TODO: Figure out a better way for typing here
        for (let regionId in patientScan.marker_info[image][countByNodeKey]) {
          affectedRegions[shortRegionName(regionId as string)] = 1;
        }
      }
    }

    const user = users.find((u) => u.id === patientScan.created_by_id);
    const studyParticipation = studyParticipations.find(
      (s) => s.user_id === patientScan.created_by_id
    );

    return {
      id: patientScan.id,
      patientId: patientName,
      group: studyParticipation
        ? studyParticipation.group
        : t("common:notApplicable"),
      readerId: `${user.first_name} ${user.last_name}`,
      scanType: patientScan.scan_type,
      clinicalIndication: patientScan.clinical_indication,
      prostateRemoved: patientScan.prostate_removed
        ? t("common:yes")
        : t("common:no"),
      radiotracer,
      modality: patientScan.modality || t("common:notApplicable"),
      ...ALL_TNM_REGIONS.reduce((prev, region) => {
        return {
          ...prev,
          [region]: affectedRegions.hasOwnProperty(region) ? 1 : 0,
        };
      }, {}),
      otherLymphNodeInvolvement:
        patientScan.marker_info.pelvic_lymph_node_metastases &&
        patientScan.marker_info.pelvic_lymph_node_metastases
          .are_other_nodes_involved
          ? 1
          : 0,
      otherLymphNodeInvolvementText:
        patientScan.marker_info.pelvic_lymph_node_metastases
          .other_nodes_involvement ?? "",
      dmi:
        patientScan.marker_info.bone_metastases &&
        patientScan.marker_info.bone_metastases.is_dmi
          ? 1
          : 0,
      otherOrganInvolvement:
        patientScan.marker_info.other_organ_metastases &&
        patientScan.marker_info.other_organ_metastases.are_other_organs_involved
          ? 1
          : 0,
      otherOrganInvolvementText:
        patientScan.marker_info.other_organ_metastases
          .other_organ_involvement ?? "",
    };
  }

  function patientFeedbackToExcelRow(patientFeedbackObject: any) {
    const patientName =
      patients.find((p) => p.id === patientFeedbackObject.patient_id)
        ?.patient_id || patientFeedbackObject.patient_id;

    const user = users.find(
      (u) => u.id === patientFeedbackObject.created_by_id
    );
    const studyParticipation = studyParticipations.find(
      (s) => s.user_id === patientFeedbackObject.created_by_id
    );

    return {
      id: patientFeedbackObject.id,
      patientId: patientName,
      group: studyParticipation?.group,
      readerId: `${user.first_name} ${user.last_name}`,
      treatable: patientFeedbackObject.treatable
        ? t("common:yes")
        : t("common:no"),
      comment: patientFeedbackObject.comment,
    };
  }

  async function exportToExcel() {
    const workbook = new Excel.Workbook();

    const patientScansWorksheet = workbook.addWorksheet(
      t("sections:patientScans")
    );
    patientScansWorksheet.columns = [
      { header: t("common:id"), key: "id" },
      { header: t("patient:patientId"), key: "patientId" },
      { header: t("studyParticipation:readerGroup"), key: "group" },
      { header: t("studyParticipation:readerId"), key: "readerId" },
      { header: t("patient:scanType"), key: "scanType" },
      {
        header: t("patient:clinicalIndication"),
        key: "clinicalIndication",
      },
      {
        header: t("patient:priorRadicalProstatectomy"),
        key: "prostateRemoved",
      },
      { header: t("patient:radiopharmaceutical"), key: "radiotracer" },
      { header: t("patient:modality"), key: "modality" },
      ...ALL_TNM_REGIONS.map((region) => ({ header: region, key: region })),
      {
        header: t("miTNM:otherLymphNodeInvolvement"),
        key: "otherLymphNodeInvolvement",
      },
      {
        header: t("miTNM:otherLymphNodeInvolvementPlaceholder"),
        key: "otherLymphNodeInvolvementText",
      },
      { header: t("common:dmi"), key: "dmi" },
      {
        header: t("miTNM:otherOrganInvolvement"),
        key: "otherOrganInvolvement",
      },
      {
        header: t("miTNM:otherOrganInvolvementPlaceholder"),
        key: "otherOrganInvolvementText",
      },
    ];

    const patientFeedbackWorksheet = workbook.addWorksheet(
      t("sections:lupsmaEligibility")
    );
    patientFeedbackWorksheet.columns = [
      { header: t("common:id"), key: "id" },
      { header: t("patient:patientId"), key: "patientId" },
      { header: t("studyParticipation:readerGroup"), key: "group" },
      { header: t("studyParticipation:readerId"), key: "readerId" },
      {
        header: capitalizeFirstLetter(
          t("luPSMAEligibility:eligibility.eligible")
        ),
        key: "treatable",
      },
      { header: t("common:comment"), key: "comment" },
    ];

    for (let patientScan of patientScans) {
      patientScansWorksheet.addRow(patientScanToExcelRow(patientScan));
    }
    for (let currentPatientFeedback of patientFeedback) {
      patientFeedbackWorksheet.addRow(
        patientFeedbackToExcelRow(currentPatientFeedback)
      );
    }

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });

    saveAs(blob, `${t("sections:patientScans")}.xlsx`);
  }

  return (
    <>
      <Row className="my-3 px-3">
        <Col md>
          <h3>{t("sections:patientScans")}</h3>
        </Col>
        <Col md>
          <Button
            variant="success"
            className="float-end"
            onClick={() => {
              exportToExcel();
            }}
          >
            {t("common:exportToExcelButton")}
          </Button>
        </Col>
      </Row>
      <Row className="px-3">
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t("patient:patientId")}</th>
                <th>{t("studyParticipation:readerGroup")}</th>
                <th>{t("studyParticipation:reader")}</th>
                <th>{t("patient:scanType")}</th>
                <th>{t("patient:radiopharmaceutical")}</th>
                <th>{t("patient:modality")}</th>
                <th>{t("common:lastModified")}</th>
              </tr>
            </thead>
            <tbody>{patientScans.map(patientScanToTableRow)}</tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

export default ResearchPatientScansManagement;
