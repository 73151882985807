import React from "react";
import * as Excel from "exceljs";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Patient } from "../../api/patients";
import { PatientScan, PatientScanMarkerInfo } from "../../api/patientScans";
import { useAuthContext } from "../../contexts/AuthContext";
import { getOrgPatientScans, getOrgPatients } from "../../api/orgs";
import { DateTime } from "luxon";
import {
  clinicalIndicationName,
  clinicalStateName,
  restagingCategoryName,
} from "../../constants/enums";
import getCombinedTNMCodeLine from "../MiTNM/TNMCodeLine/combinedTNMCodeLineUtils";
import { useAppSettingsContext } from "../../contexts/AppSettingsContext";
import saveAs from "file-saver";

import "./ClinicPatientScansManagement.css";
import { ALL_TNM_REGIONS, shortRegionName } from "../../constants/regionNames";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/stringUtils";

export default function ClinicPatientScansManagement(): React.ReactElement {
  const { t } = useTranslation(["patient", "sections", "common"]);
  const { getAccessToken, currentUser } = useAuthContext();
  const { TNMClassification } = useAppSettingsContext();

  const [patients, setPatients] = React.useState<Patient[]>([]);
  const [patientScans, setPatientScans] = React.useState<PatientScan[]>([]);

  async function fetchData() {
    const accessToken = await getAccessToken();
    const newPatients = await getOrgPatients(accessToken, currentUser.org_id);
    const newPatientScans = await getOrgPatientScans(
      accessToken,
      currentUser.org_id
    );
    setPatients(newPatients);
    setPatientScans(newPatientScans);
  }

  function patientScanToTableRow(patientScan: PatientScan): React.ReactElement {
    /*
      <tr>
        <th>Patient ID</th>
        <th>Scan date</th>
        <th>Clinical indication</th>
        <th>Clinical state</th>
        <th>Restaging category</th>
        <th>Radiopharmaceutical</th>
        <th>Imaging Technique</th>
        <th>TNM codeline</th>
      </tr>
    */

    const [shortTNMCodeLine, _] = getCombinedTNMCodeLine(
      TNMClassification,
      patientScan.marker_info,
      patientScan.clinical_indication,
      patientScan.prostate_removed
    );

    return (
      <tr key={`patientScan-${patientScan.id}`}>
        <td>
          {
            patients.find((patient) => patient.id === patientScan.patient_id)
              ?.patient_id
          }
        </td>
        <td>
          {patientScan.date?.toLocaleString(DateTime.DATE_SHORT) ||
            t("common:notApplicable")}
        </td>
        <td>{clinicalIndicationName(patientScan.clinical_indication)}</td>
        <td>
          {patientScan.clinical_state
            ? clinicalStateName(patientScan.clinical_state)
            : t("common:notApplicable")}
        </td>
        <td>
          {patientScan.restaging_category
            ? restagingCategoryName(patientScan.restaging_category)
            : t("common:notApplicable")}
        </td>
        <td>{patientScan.radiopharmaceutical || t("common:notApplicable")}</td>
        <td>{patientScan.modality || t("common:notApplicable")}</td>
        <td>{shortTNMCodeLine}</td>
      </tr>
    );
  }

  function patientScanToExcelRow(patientScan: PatientScan) {
    const patientName =
      patients.find((p) => p.id === patientScan.patient_id)?.patient_id ||
      patientScan.patient_id;

    const [shortTNMCodeLine, _] = getCombinedTNMCodeLine(
      TNMClassification,
      patientScan.marker_info,
      patientScan.clinical_indication,
      patientScan.prostate_removed
    );

    const affectedRegions: { [key: string]: number } = {};
    for (let image of [
      "prostate_tumor",
      "pelvic_lymph_node_metastases",
      "bone_metastases",
      "other_organ_metastases",
    ]) {
      if (!patientScan.marker_info.hasOwnProperty(image)) {
        continue;
      }
      let markerCountByKeys =
        image === "other_organ_metastases"
          ? ["marker_count_by_organ", "marker_count_by_lymph_node"]
          : ["marker_count_by_region"];
      for (let countByNodeKey of markerCountByKeys) {
        if (
          !patientScan.marker_info[
            image as keyof PatientScanMarkerInfo
          ].hasOwnProperty(countByNodeKey)
        ) {
          continue;
        }
        //@ts-ignore TODO: Figure out a better way for typing here
        for (let regionId in patientScan.marker_info[image][countByNodeKey]) {
          affectedRegions[shortRegionName(regionId as string)] = 1;
        }
      }
    }

    return {
      id: patientScan.id,
      patientId: patientName,
      date: patientScan.date?.toLocaleString(DateTime.DATE_SHORT) || "",
      clinicalIndication: clinicalIndicationName(
        patientScan.clinical_indication
      ),
      restagingCategory:
        patientScan.restaging_category != null
          ? restagingCategoryName(patientScan.restaging_category)
          : "",
      prostateRemoved: patientScan.prostate_removed ? "true" : "false",
      location: patientScan.location || "",
      scannerType: patientScan.scanner_type || "",
      scannerModel: patientScan.scanner_model || "",
      reconstructionMethod: patientScan.reconstruction_method || "",
      contrast: patientScan.contrast || "",
      radiopharmaceutical: patientScan.radiopharmaceutical || "",
      injectedActivity: patientScan.injected_activity || "",
      injectedTime: patientScan.injected_time || "",
      createdAt:
        patientScan.created_at?.toLocaleString(DateTime.DATETIME_SHORT) || "",
      updatedAt:
        patientScan.updated_at?.toLocaleString(DateTime.DATETIME_SHORT) || "",
      modality: patientScan.modality || "",
      diurecits: patientScan.diuretics || "",
      clinicalState:
        patientScan.clinical_state != null
          ? clinicalStateName(patientScan.clinical_state)
          : "",
      tnmCodeLine: shortTNMCodeLine,
      ...ALL_TNM_REGIONS.reduce((prev, region) => {
        return {
          ...prev,
          [region]: affectedRegions.hasOwnProperty(region) ? 1 : 0,
        };
      }, {}),
      dmi:
        patientScan.marker_info.bone_metastases &&
        patientScan.marker_info.bone_metastases.is_dmi
          ? 1
          : 0,
    };
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  async function exportToExcel() {
    const workbook = new Excel.Workbook();

    const patientScansWorksheet = workbook.addWorksheet(
      t("sections:patientScans")
    );

    /*
    - Clinical History (A-F)
      - Patient ID (A)
      - Scan Date (B)
      - Clinical Indication (C)
      - Clinical state (D)
      - Restaging category (E)
      - Prior radical prostatectomy (F)
    - Technical Information (G-P)
      - Imaging technique (G)
      - Scanner location (H)
      - Scanner model (I)
      - scanner type (J)
      - Reconstruction method (K)
      - Radiopharmaceutical (L)
      - Injected activity (Mbq) (M)
      - Injected time p.i. (min) (N)
      - Diuretics (O)
      - Contrast agent (P)
    - Imaging Findings (Q)
      - TNM codeline (Q)
    */

    patientScansWorksheet.mergeCells("A1", "F1");
    patientScansWorksheet.getCell("A1").value = t("sections:clinicalHistory");
    patientScansWorksheet.getCell("A1").alignment = { horizontal: "center" };

    patientScansWorksheet.mergeCells("G1", "P1");
    patientScansWorksheet.getCell("G1").value = t(
      "sections:technicalInformation"
    );
    patientScansWorksheet.getCell("G1").alignment = { horizontal: "center" };

    patientScansWorksheet.mergeCells("Q1", "EL1");
    patientScansWorksheet.getCell("Q1").value = t("sections:imagingFindings");
    patientScansWorksheet.getCell("Q1").alignment = { horizontal: "center" };

    // Second line of headers
    patientScansWorksheet.getRow(2).values = [
      t("patientId"),
      t("scanDate"),
      t("clinicalIndication"),
      t("clinicalState"),
      t("restagingCategory"),
      t("priorRadicalProstatectomy"),
      t("imagingTechnique"),
      t("scannerLocation"),
      t("scannerModel"),
      t("scannerType"),
      t("reconstructionMethod"),
      t("radiopharmaceutical"),
      t("injectedActivity"),
      t("injectedTime"),
      t("diuretics"),
      t("contrastAgent"),
      t("sections:tnmCodeline"),
      ...ALL_TNM_REGIONS,
      capitalizeFirstLetter(t("common:dmi")),
    ];

    patientScansWorksheet.columns = [
      { key: "patientId" },
      { key: "date" },
      { key: "clinicalIndication" },
      { key: "clinicalState" },
      { key: "restagingCategory" },
      { key: "prostateRemoved" },
      { key: "modality" },
      { key: "location" },
      { key: "scannerType" },
      { key: "scannerType" },
      { key: "reconstructionMethod" },
      { key: "radiopharmaceutical" },
      { key: "injectedActivity" },
      { key: "injectedTime" },
      { key: "diurecits" },
      { key: "contrast" },
      { key: "tnmCodeLine" },
      ...ALL_TNM_REGIONS.map((region) => ({ key: region })),
      { key: "dmi" },
    ];

    for (let patientScan of patientScans) {
      patientScansWorksheet.addRow(patientScanToExcelRow(patientScan));
    }

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });

    saveAs(blob, `${t("sections:patientScans")}.xlsx`);
  }

  return (
    <>
      <Row className="my-3 px-3">
        <Col md>
          <h3>{t("sections:patientScans")}</h3>
        </Col>
        <Col md>
          <Button
            variant="success"
            className="float-end"
            onClick={() => {
              exportToExcel();
            }}
          >
            {t("common:exportToExcelButton")}
          </Button>
        </Col>
      </Row>
      <Row className="px-3">
        <Col>
          <Table size="sm" striped bordered hover>
            <thead>
              <tr>
                <th colSpan={5}>{t("sections:clinicalHistory")}</th>
                <th colSpan={2}>{t("sections:technicalInformation")}</th>
                <th>{t("sections:imagingFindings")}</th>
              </tr>
              <tr>
                <th>{t("patientId")}</th>
                <th>{t("scanDate")}</th>
                <th>{t("clinicalIndication")}</th>
                <th>{t("clinicalState")}</th>
                <th>{t("restagingCategory")}</th>
                <th>{t("radiopharmaceutical")}</th>
                <th>{t("imagingTechnique")}</th>
                <th>{t("sections:tnmCodeline")}</th>
              </tr>
            </thead>
            <tbody>{patientScans.map(patientScanToTableRow)}</tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
