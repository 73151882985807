import * as React from "react";
import { useAuthContext } from "./AuthContext";
import { getAllPatients, Patient } from "../api/patients";
import { useOrgContext } from "./OrgContext";

interface PatientsContextV2Type {
  loading: boolean;
  patients: Patient[];
  refreshPatients: () => Promise<void>;
}

const PatientsContextV2 = React.createContext<PatientsContextV2Type>({
  loading: false,
  patients: [],
  refreshPatients: async () => {},
});

interface PatientsContextV2ProviderProps {
  children: React.ReactNode;
}

export function PatientsContextV2Provider({
  children,
}: PatientsContextV2ProviderProps) {
  const { getAccessToken } = useAuthContext();
  const { currentOrg } = useOrgContext();

  const [loading, setLoading] = React.useState(false);
  const [patients, setPatients] = React.useState<Patient[]>([]);

  React.useEffect(() => {
    refreshPatients();
  }, [currentOrg]);

  async function refreshPatients() {
    setLoading(true);
    const accessToken = await getAccessToken();
    setPatients(await getAllPatients(accessToken));
    setLoading(false);
  }

  return (
    <PatientsContextV2.Provider
      value={{
        loading,
        patients,
        refreshPatients,
      }}
    >
      {children}
    </PatientsContextV2.Provider>
  );
}

export const usePatientsContextV2 = () => React.useContext(PatientsContextV2);
