import { NavigateFunction } from "react-router-dom";
import { getAllPatients, Patient } from "../api/patients";
import {
  getPatientScansForPatient,
  initialMarkerInfo,
  PatientScan,
} from "../api/patientScans";
import {
  StudyParticipation,
  updateStudyParticipation,
} from "../api/studyParticipations";
import {
  ScanType,
  Radiotracer,
  ClinicalIndication,
  ImagingModality,
} from "../constants/enums";
import {
  calculateDiscrepancies,
  manualDiscrepancyCalculation,
} from "./researchDiscrepancyUtils";

export const RESEARCH_PATIENT_IDS = [
  "Test Patient",
  "CHUGA-003",
  "CHUGA-004",
  "CHUGA-005",
  "CHUGA-006",
  "CHUGA-007",
  "CHUGA-008",
  "CHUGA-009",
  "CHUGA-010",
  "CHUGA-011",
  "CHUGA-012",
  "CHUGA-013",
  "CHUGA-014",
  "CHUGA-015",
  "CHUGA-016",
  "CHUGA-017",
  "CHUGA-018",
  "CHUGA-020",
  "CHUGA-021",
  "CHUGA-024",
  "CHUGA-025",
  "CHUGA-026",
  "CHUGA-028",
  "CHUGA-029",
  "CHUGA-030",
  "CHUGA-031",
  "CHUGA-032",
  "CHUGA-033",
  "CHUGA-034",
  "CHUGA-037",
  "CHUGA-038",
  "CHUGA-039",
  "CHUGA-040",
  "CHUGA-041",
  "CHUGA-044",
  "CHUGA-046",
  "CHUGA-047",
  "CHUGA-048",
  "CHUGA-049",
  "CHUGA-050",
  "CHUGA-053",
  "CHUGA-054",
  "CHUGA-055",
  "CHUGA-056",
  "CHUGA-057",
  "CHUGA-059",
  "CHUGA-061",
  "CHUGA-062",
  "CHUGA-063",
  "CHUGA-064",
  "CHUGA-065",
  "CHUGA-066",
  "CHUGA-067",
  "CHUGA-068",
  "CHUGA-069",
  "CHUGA-070",
  "CHUGA-071",
  "CHUGA-072",
  "CHUGA-073",
  "CHUGA-074",
  "CHUGA-075",
];

// Load current step based on the study participation value
export async function loadCurrentStep(
  accessToken: string,
  currentUser: any,
  navigate: NavigateFunction,
  currentPatient: Patient,
  setCurrentPatient: (newPatient: Patient) => void,
  currentPatientScan: PatientScan,
  setCurrentPatientScan: (newScan: PatientScan) => void,
  currentUserStudyParticipation: StudyParticipation | undefined,
  setCurrentUserStudyParticipation: (
    newStudyParticipation: StudyParticipation
  ) => void,
  showFeedbackModal: () => void,
  log: (message: string, delay: number) => void
) {
  if (!currentUserStudyParticipation) {
    console.warn("Study participation not defined. Cannot proceed");
    navigate("/home/imaging_findings/tnm_classification/local_lesions");
    return;
  }

  // Fetch patients independently of the PatientsContext - it is liable to race conditions
  const patients = await getAllPatients(accessToken);

  if (
    !currentUserStudyParticipation.current_patient_id ||
    !currentUserStudyParticipation.current_step
  ) {
    const newCurrentPatient = patients.find(
      (p) => p.patient_id === RESEARCH_PATIENT_IDS[0]
    );

    if (!newCurrentPatient?.id) {
      console.error("Current patient not defined. Cannot proceed");
      return;
    }

    setCurrentUserStudyParticipation({
      ...currentUserStudyParticipation,
      current_patient_id: newCurrentPatient.id,
      current_step: 1,
    });
    return;
  }

  const newCurrentPatient = patients.find(
    (p) => p.id === currentUserStudyParticipation.current_patient_id
  );

  if (!newCurrentPatient?.id) {
    console.error("Current patient not defined. Cannot proceed");
    return;
  }

  const newCurrentPatientScans = (
    await getPatientScansForPatient(accessToken, newCurrentPatient.id)
  ).filter((s) => s.created_by_id === currentUser.id);

  if (currentPatient?.id !== newCurrentPatient.id) {
    setCurrentPatient(newCurrentPatient);
  }

  if (currentUserStudyParticipation.current_step === 1) {
    // Check if the current step already exists in the database
    const existingPatientScan = newCurrentPatientScans.find(
      (scan) =>
        scan.scan_type === ScanType.REGULAR &&
        scan.radiotracer === Radiotracer.PSMA
    );
    if (existingPatientScan) {
      console.log(
        `Patient ${newCurrentPatient.patient_id} already has step 1 - see #${existingPatientScan.id}`
      );
      nextStep(
        accessToken,
        existingPatientScan,
        currentUserStudyParticipation,
        setCurrentUserStudyParticipation,
        showFeedbackModal
      );
      return;
    }
    setCurrentPatientScan({
      ...currentPatientScan,
      clinical_indication: ClinicalIndication.RESTAGING,
      scan_type: ScanType.REGULAR,
      modality: ImagingModality.PET,
      radiotracer: Radiotracer.PSMA,
      marker_info: initialMarkerInfo(),
    });
    navigate("/home/imaging_findings/tnm_classification/local_lesions");
    return;
  }

  if (currentUserStudyParticipation.current_step === 2) {
    // Check if the current step already exists in the database
    const existingPatientScan = newCurrentPatientScans.find(
      (scan) =>
        scan.scan_type === ScanType.REGULAR &&
        scan.radiotracer ===
          (currentUserStudyParticipation.group === "1"
            ? Radiotracer.FDG
            : Radiotracer.FCH)
    );
    if (existingPatientScan) {
      console.log(
        `Patient ${newCurrentPatient.patient_id} already has step 2 - see #${existingPatientScan.id}`
      );
      nextStep(
        accessToken,
        existingPatientScan,
        currentUserStudyParticipation,
        setCurrentUserStudyParticipation,
        showFeedbackModal
      );
      return;
    }
    setCurrentPatientScan({
      ...currentPatientScan,
      clinical_indication: ClinicalIndication.RESTAGING,
      scan_type: ScanType.REGULAR,
      modality: ImagingModality.PET,
      radiotracer:
        currentUserStudyParticipation.group === "1"
          ? Radiotracer.FDG
          : Radiotracer.FCH,
      marker_info: initialMarkerInfo(),
    });
    navigate("/home/imaging_findings/tnm_classification/local_lesions");
    return;
  }

  if (currentUserStudyParticipation.current_step === 3) {
    const secondScanRadiotracer =
      currentUserStudyParticipation.group === "1"
        ? Radiotracer.FDG
        : Radiotracer.FCH;

    // Check if the current step already exists in the database
    const existingPatientScan = newCurrentPatientScans.find(
      (scan) =>
        scan.scan_type === ScanType.DISCREPANCIES &&
        scan.metadata.firstScanRadiotracer === Radiotracer.PSMA &&
        scan.metadata.secondScanRadiotracer === secondScanRadiotracer
    );
    if (existingPatientScan) {
      console.log(
        `Patient ${newCurrentPatient.patient_id} already has step 3 - see #${existingPatientScan.id}`
      );
      nextStep(
        accessToken,
        existingPatientScan,
        currentUserStudyParticipation,
        setCurrentUserStudyParticipation,
        showFeedbackModal
      );
      return;
    }
    const firstScan = newCurrentPatientScans.find(
      (ps) =>
        ps.patient_id === currentUserStudyParticipation.current_patient_id &&
        ps.scan_type === ScanType.REGULAR &&
        ps.radiotracer === Radiotracer.PSMA
    );
    if (!firstScan) {
      console.error(
        "Patient scan defined in the study participation not found"
      );
      return;
    }
    const secondScan = newCurrentPatientScans.find(
      (ps) =>
        ps.patient_id === currentUserStudyParticipation.current_patient_id &&
        ps.scan_type === ScanType.REGULAR &&
        ps.radiotracer === secondScanRadiotracer
    );
    if (!secondScan) {
      console.error(
        "Patient scan defined in the study participation not found"
      );
      return;
    }
    const newCurrentPatientScan = {
      ...currentPatientScan,
      scan_type: ScanType.DISCREPANCIES,
      // Set the prostate removed and the clinical indication to match the first scan
      clinical_indication: firstScan.clinical_indication,
      prostate_removed: firstScan.prostate_removed,
      metadata: {
        firstScanId: firstScan.id!,
        firstScanRadiotracer: Radiotracer.PSMA,
        secondScanId: secondScan.id!,
        secondScanRadiotracer: secondScanRadiotracer,
      },
    };
    setCurrentPatientScan(newCurrentPatientScan);
    calculateDiscrepancies(
      newCurrentPatientScan,
      setCurrentPatientScan,
      firstScan,
      secondScan
    );
    navigate("/home/imaging_findings/tnm_classification/summary");
    return;
  }

  if (currentUserStudyParticipation.current_step === 4) {
    const firstScanDiscrepancyRadiotracer =
      currentUserStudyParticipation.group === "1"
        ? Radiotracer.FDG
        : Radiotracer.FCH;
    const secondScanRadiotracer =
      currentUserStudyParticipation.group === "1"
        ? Radiotracer.FCH
        : Radiotracer.FDG;

    // Check if the current step already exists in the database
    const existingPatientScan = newCurrentPatientScans.find(
      (scan) =>
        scan.scan_type === ScanType.DISCREPANCIES &&
        scan.metadata.firstScanRadiotracer ===
          `[${Radiotracer.PSMA}-${firstScanDiscrepancyRadiotracer}]` &&
        scan.metadata.secondScanRadiotracer === secondScanRadiotracer
    );
    if (existingPatientScan) {
      console.log(
        `Patient ${newCurrentPatient.patient_id} already has step 4 - see #${existingPatientScan.id}`
      );
      nextStep(
        accessToken,
        existingPatientScan,
        currentUserStudyParticipation,
        setCurrentUserStudyParticipation,
        showFeedbackModal
      );
      return;
    }

    const firstScan = newCurrentPatientScans.find(
      (ps) =>
        ps.scan_type === ScanType.DISCREPANCIES &&
        ps.metadata.firstScanRadiotracer === Radiotracer.PSMA &&
        ps.metadata.secondScanRadiotracer === firstScanDiscrepancyRadiotracer
    );

    if (!firstScan) {
      console.error(
        "Patient scan defined in the study participation not found"
      );
      return;
    }

    const newCurrentPatientScan = {
      ...currentPatientScan,
      scan_type: ScanType.DISCREPANCIES,
      // Set the prostate removed and the clinical indication to match the first scan
      clinical_indication: firstScan.clinical_indication,
      prostate_removed: firstScan.prostate_removed,
      marker_info: firstScan.marker_info,
      metadata: {
        firstScanId: firstScan.id!,
        firstScanRadiotracer: `[${Radiotracer.PSMA}-${firstScanDiscrepancyRadiotracer}]`,
        // We explicitly don't set second scan
        secondScanRadiotracer,
      },
    };

    setCurrentPatientScan(newCurrentPatientScan);

    manualDiscrepancyCalculation(
      newCurrentPatientScan,
      setCurrentPatientScan,
      firstScan,
      secondScanRadiotracer,
      log
    );
    navigate("/home/imaging_findings/tnm_classification/local_lesions");
    return;
  }

  console.error("Unreachable state");
  return;
}

// Calculate next study participation step and update all variables accordingly
export async function nextStep(
  accessToken: string,
  currentPatientScan: PatientScan,
  currentUserStudyParticipation: StudyParticipation | undefined,
  setCurrentUserStudyParticipation: (sp: StudyParticipation) => void,
  showFeedbackModal: () => void
) {
  if (
    !currentUserStudyParticipation?.id ||
    !currentUserStudyParticipation?.current_step
  ) {
    console.error("Study participation not defined. Cannot proceed");
    return;
  }

  const { current_step } = currentUserStudyParticipation;
  const {
    prostate_tumor,
    pelvic_lymph_node_metastases,
    bone_metastases,
    other_organ_metastases,
  } = currentPatientScan.marker_info;

  let scanIsEmpty = true;

  if (prostate_tumor) {
    scanIsEmpty =
      scanIsEmpty && Object.keys(prostate_tumor.markers).length === 0;
  }
  if (pelvic_lymph_node_metastases) {
    scanIsEmpty =
      scanIsEmpty &&
      Object.keys(pelvic_lymph_node_metastases.markers).length === 0;
  }
  if (bone_metastases) {
    scanIsEmpty =
      scanIsEmpty &&
      !bone_metastases.is_dmi &&
      Object.keys(bone_metastases.markers).length === 0;
  }
  if (other_organ_metastases) {
    scanIsEmpty =
      scanIsEmpty && Object.keys(other_organ_metastases.markers).length === 0;
  }

  if (current_step === 4 || (current_step === 3 && scanIsEmpty)) {
    showFeedbackModal();
  } else {
    // Update current user study participation
    const newStudyParticipation: StudyParticipation = {
      ...currentUserStudyParticipation,
      current_step: currentUserStudyParticipation.current_step + 1,
    };
    await updateStudyParticipation(
      accessToken,
      currentUserStudyParticipation.id,
      newStudyParticipation
    );
    setCurrentUserStudyParticipation(newStudyParticipation);
  }
}

export async function nextPatient(
  accessToken: string,
  patients: Patient[],
  currentUserStudyParticipation: StudyParticipation | undefined,
  setCurrentUserStudyParticipation: (sp: StudyParticipation) => void
) {
  if (
    !currentUserStudyParticipation ||
    !currentUserStudyParticipation.id ||
    !currentUserStudyParticipation.current_patient_id
  ) {
    console.error("Cannot proceed - study participation is not defined");
    return;
  }

  const { current_patient_id } = currentUserStudyParticipation;
  const studyParticipationCurrentPatient = patients.find(
    (p) => p.id === current_patient_id
  );

  if (!studyParticipationCurrentPatient?.patient_id) {
    console.error("Current study participation patient not found");
    return;
  }

  const patientIndex = RESEARCH_PATIENT_IDS.indexOf(
    studyParticipationCurrentPatient.patient_id
  );

  if (patientIndex === -1) {
    console.error("Current study participation patient not found");
    return;
  }

  if (patientIndex === RESEARCH_PATIENT_IDS.length - 1) {
    alert("Congratulations, you have finished the study!");
    return;
  }

  const studyParticipationNextPatient = patients.find(
    (p) => p.patient_id === RESEARCH_PATIENT_IDS[patientIndex + 1]
  );

  if (!studyParticipationNextPatient?.id) {
    console.error("Next study participation patient not found");
    return;
  }

  // Update current user study participation
  const newStudyParticipation: StudyParticipation = {
    ...currentUserStudyParticipation,
    current_patient_id: studyParticipationNextPatient.id,
    current_step: 1,
  };
  await updateStudyParticipation(
    accessToken,
    currentUserStudyParticipation.id,
    newStudyParticipation
  );
  setCurrentUserStudyParticipation(newStudyParticipation);
}
