import i18n from "../i18n";

// Prostate staging
export const PROSTATE_STAGING_REGIONS = [
  "Apex-TRI",
  "Apex-TLI",
  "Apex-BRI",
  "Apex-BLI",
  "Apex-TRM",
  "Apex-TLM",
  "Apex-BRM",
  "Apex-BLM",
  "Apex-TRO",
  "Apex-TLO",
  "Apex-BRO",
  "Apex-BLO",
  "Mid-TRI",
  "Mid-TLI",
  "Mid-BRI",
  "Mid-BLI",
  "Mid-TRM",
  "Mid-TLM",
  "Mid-BRM",
  "Mid-BLM",
  "Mid-TRO",
  "Mid-TLO",
  "Mid-BRO",
  "Mid-BLO",
  "Base-TRI",
  "Base-TLI",
  "Base-BRI",
  "Base-BLI",
  "Base-TRM",
  "Base-TLM",
  "Base-BRM",
  "Base-BLM",
  "Base-TRO",
  "Base-TLO",
  "Base-BRO",
  "Base-BLO",
  "EPE",
  "Bladder",
  "RSV",
  "LSV",
];

export const PROSTATE_STAGING_EQUIVALENT_REGIONS = [
  ["Apex-TRI", "Apex-BRI"],
  ["Apex-TLI", "Apex-BLI"],
  ["Apex-TRM", "Apex-BRM"],
  ["Apex-TLM", "Apex-BLM"],
  ["Apex-TRO", "Apex-BRO"],
  ["Apex-TLO", "Apex-BLO"],
  ["Mid-TRI", "Mid-BRI"],
  ["Mid-TLI", "Mid-BLI"],
  ["Mid-TRM", "Mid-BRM"],
  ["Mid-TLM", "Mid-BLM"],
  ["Mid-TRO", "Mid-BRO"],
  ["Mid-TLO", "Mid-BLO"],
  ["Base-TRI", "Base-BRI"],
  ["Base-TLI", "Base-BLI"],
  ["Base-TRM", "Base-BRM"],
  ["Base-TLM", "Base-BLM"],
  ["Base-TRO", "Base-BRO"],
  ["Base-TLO", "Base-BLO"],
];

export const PROSTATE_STAGING_REGION_NAMES_SHORT =
  PROSTATE_STAGING_REGIONS.reduce((prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:short.${region}`));
    return prevMap;
  }, new Map<string, string>());

export const PROSTATE_STAGING_REGION_NAMES_LONG =
  PROSTATE_STAGING_REGIONS.reduce((prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:long.${region}`));
    return prevMap;
  }, new Map<string, string>());

// In this case, TNM regions are virtual from the image regions
export const PROSTATE_STAGING_TNM_REGIONS = new Set(
  PROSTATE_STAGING_REGIONS.map((region) =>
    i18n.t(`regionNames:short.${region}`)
  )
);

// Prostate restaging
export const PROSTATE_RESTAGING_REGIONS = [
  "RSV",
  "LSV",
  "Prostate",
  "Prostate-Capsule",
  "ES",
  "PFM-Left",
  "PFM-Right",
  "Rectum",
];

export const PROSTATE_RESTAGING_REGION_NAMES_SHORT =
  PROSTATE_RESTAGING_REGIONS.reduce((prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:short.${region}`));
    return prevMap;
  }, new Map<string, string>());

export const PROSTATE_RESTAGING_REGION_NAMES_LONG =
  PROSTATE_RESTAGING_REGIONS.reduce((prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:long.${region}`));
    return prevMap;
  }, new Map<string, string>());

export const PROSTATE_RESTAGING_TNM_REGIONS = new Set(
  PROSTATE_RESTAGING_REGIONS.map((region) =>
    i18n.t(`regionNames:short.${region}`)
  )
);

// Prostate removed
export const PROSTATE_REMOVED_REGIONS = [
  "SVB-Left",
  "SVB-Right",
  "RV",
  "VTA",
  "Rectum",
];

export const PROSTATE_REMOVED_REGION_NAMES_SHORT =
  PROSTATE_REMOVED_REGIONS.reduce((prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:short.${region}`));
    return prevMap;
  }, new Map<string, string>());

export const PROSTATE_REMOVED_REGION_NAMES_LONG =
  PROSTATE_REMOVED_REGIONS.reduce((prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:long.${region}`));
    return prevMap;
  }, new Map<string, string>());

export const PROSTATE_REMOVED_TNM_REGIONS = new Set(
  PROSTATE_REMOVED_REGIONS.map((region) =>
    i18n.t(`regionNames:short.${region}`)
  )
);

// Pelvic lymph nodes
export const PELVIC_LYMPH_NODES_REGIONS = [
  "EIR",
  "EIL",
  "IIR",
  "IIL",
  "PS",
  "OBR",
  "OBL",
];

export const PELVIC_LYMPH_NODES_REGION_NAMES_SHORT =
  PELVIC_LYMPH_NODES_REGIONS.reduce((prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:short.${region}`));
    return prevMap;
  }, new Map<string, string>());

export const PELVIC_LYMPH_NODES_REGION_NAMES_LONG =
  PELVIC_LYMPH_NODES_REGIONS.reduce((prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:long.${region}`));
    return prevMap;
  }, new Map<string, string>());

export const PELVIC_LYMPH_NODES_TNM_REGIONS = new Set(
  PELVIC_LYMPH_NODES_REGIONS.map((region) =>
    i18n.t(`regionNames:short.${region}`)
  )
);

// Bones
export const BONE_REGIONS = [
  "Skull",
  "Skull-Front",
  "Skull-Back",
  "Clavicula-Right",
  "Clavicula-Left",
  "Scapula-Right",
  "Scapula-Left",
  "Humerus-Right",
  "Humerus-Left",
  "Ilium-Right",
  "Ilium-Left",
  "Iscium-Right",
  "Iscium-Left",
  "Pubic-Bone-Right",
  "Pubic-Bone-Left",
  "Femur-Right",
  "Femur-Left",
  "Clavicula-Right-Front",
  "Clavicula-Left-Front",
  "Humerus-Right-Front",
  "Humerus-Left-Front",
  "Ilium-Right-Front",
  "Ilium-Left-Front",
  "Iscium-Right-Front",
  "Iscium-Left-Front",
  "Pubic-Bone-Right-Front",
  "Pubic-Bone-Left-Front",
  "Femur-Right-Front",
  "Femur-Left-Front",
  "Scapula-Right-Back",
  "Scapula-Left-Back",
  "Humerus-Right-Back",
  "Humerus-Left-Back",
  "Ilium-Right-Back",
  "Ilium-Left-Back",
  "Iscium-Right-Back",
  "Iscium-Left-Back",
  "Pubic-Bone-Right-Back",
  "Pubic-Bone-Left-Back",
  "Femur-Right-Back",
  "Femur-Left-Back",
  "Sternum",
  "R1-Right",
  "R1-Left",
  "R2-Right",
  "R2-Left",
  "R3-Right",
  "R3-Left",
  "R4-Right",
  "R4-Left",
  "R5-Right",
  "R5-Left",
  "R6-Right",
  "R6-Left",
  "R7-Right",
  "R7-Left",
  "R8-Right",
  "R8-Left",
  "R9-Right",
  "R9-Left",
  "R10-Right",
  "R10-Left",
  "R11-Right",
  "R11-Left",
  "R12-Right",
  "R12-Left",
  "R1-Right-Front",
  "R1-Left-Front",
  "R2-Right-Front",
  "R2-Left-Front",
  "R3-Right-Front",
  "R3-Left-Front",
  "R4-Right-Front",
  "R4-Left-Front",
  "R5-Right-Front",
  "R5-Left-Front",
  "R6-Right-Front",
  "R6-Left-Front",
  "R7-Right-Front",
  "R7-Left-Front",
  "R8-Right-Front",
  "R8-Left-Front",
  "R9-Right-Front",
  "R9-Left-Front",
  "R10-Right-Front",
  "R10-Left-Front",
  "R11-Right-Front",
  "R11-Left-Front",
  "R12-Right-Front",
  "R12-Left-Front",
  "R8-Right-Back",
  "R8-Left-Back",
  "R9-Right-Back",
  "R9-Left-Back",
  "R10-Right-Back",
  "R10-Left-Back",
  "R11-Right-Back",
  "R11-Left-Back",
  "R12-Right-Back",
  "R12-Left-Back",
  "C1",
  "C2",
  "C3",
  "C4",
  "C5",
  "C6",
  "C7",
  "T1",
  "T2",
  "T3",
  "T4",
  "T5",
  "T6",
  "T7",
  "T8",
  "T9",
  "T10",
  "T11",
  "T12",
  "L1",
  "L2",
  "L3",
  "L4",
  "L5",
  "Sacrum",
];

export const BONE_EQUVALENT_REGIONS = [
  ["Skull", "Skull-Front", "Skull-Back"],
  ["Clavicula-Right", "Clavicula-Right-Front"],
  ["Clavicula-Left", "Clavicula-Left-Front"],
  ["Scapula-Right", "Scapula-Right-Back"],
  ["Scapula-Left", "Scapula-Left-Back"],
  ["Humerus-Right", "Humerus-Right-Front", "Humerus-Right-Back"],
  ["Humerus-Left", "Humerus-Left-Front", "Humerus-Left-Back"],
  ["Ilium-Right", "Ilium-Right-Front", "Ilium-Right-Back"],
  ["Ilium-Left", "Ilium-Left-Front", "Ilium-Left-Back"],
  ["Iscium-Right", "Iscium-Right-Front", "Iscium-Right-Back"],
  ["Iscium-Left", "Iscium-Left-Front", "Iscium-Left-Back"],
  ["Pubic-Bone-Right", "Pubic-Bone-Right-Front", "Pubic-Bone-Right-Back"],
  ["Pubic-Bone-Left", "Pubic-Bone-Left-Front", "Pubic-Bone-Left-Back"],
  ["Femur-Right", "Femur-Right-Front", "Femur-Right-Back"],
  ["Femur-Left", "Femur-Left-Front", "Femur-Left-Back"],
  ["R1-Right", "R1-Right-Front"],
  ["R2-Right", "R2-Right-Front"],
  ["R3-Right", "R3-Right-Front"],
  ["R4-Right", "R4-Right-Front"],
  ["R5-Right", "R5-Right-Front"],
  ["R6-Right", "R6-Right-Front"],
  ["R7-Right", "R7-Right-Front"],
  ["R8-Right", "R8-Right-Front", "R8-Right-Back"],
  ["R9-Right", "R9-Right-Front", "R9-Right-Back"],
  ["R10-Right", "R10-Right-Front", "R10-Right-Back"],
  ["R11-Right", "R11-Right-Back"],
  ["R12-Right", "R12-Right-Back"],
  ["R1-Left", "R1-Left-Front"],
  ["R2-Left", "R2-Left-Front"],
  ["R3-Left", "R3-Left-Front"],
  ["R4-Left", "R4-Left-Front"],
  ["R5-Left", "R5-Left-Front"],
  ["R6-Left", "R6-Left-Front"],
  ["R7-Left", "R7-Left-Front"],
  ["R8-Left", "R8-Left-Front", "R8-Left-Back"],
  ["R9-Left", "R9-Left-Front", "R9-Left-Back"],
  ["R10-Left", "R10-Left-Front", "R10-Left-Back"],
  ["R11-Left", "R11-Left-Back"],
  ["R12-Left", "R12-Left-Back"],
];

export const BONE_REGION_NAMES_SHORT = BONE_REGIONS.reduce(
  (prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:short.${region}`));
    return prevMap;
  },
  new Map<string, string>()
);

export const BONE_REGION_NAMES_LONG = BONE_REGIONS.reduce((prevMap, region) => {
  prevMap.set(region, i18n.t(`regionNames:long.${region}`));
  return prevMap;
}, new Map<string, string>());

export const BONE_TNM_REGIONS = new Set(
  BONE_REGIONS.map((region) => i18n.t(`regionNames:short.${region}`))
);

// Other organs
export const OTHER_ORGANS_REGIONS = [
  "Lung-Left",
  "Lung-Right",
  "Liver",
  "Brain-Left",
  "Brain-Right",
  "Adrenal-Gland-Right",
  "Adrenal-Gland-Left",
];

export const OTHER_ORGANS_REGION_NAMES_SHORT = OTHER_ORGANS_REGIONS.reduce(
  (prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:short.${region}`));
    return prevMap;
  },
  new Map<string, string>()
);

export const OTHER_ORGANS_REGION_NAMES_LONG = OTHER_ORGANS_REGIONS.reduce(
  (prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:long.${region}`));
    return prevMap;
  },
  new Map<string, string>()
);

export const OTHER_ORGANS_TNM_REGIONS = new Set(
  OTHER_ORGANS_REGIONS.map((region) => i18n.t(`regionNames:short.${region}`))
);

// Other lymph nodes
export const OTHER_LYMPH_NODES_REGIONS = [
  "CR",
  "CL",
  "SCR",
  "SCL",
  "AXR",
  "AXL",
  "MED/HIL",
  "RC",
  "RP",
  "IR",
  "IL",
  "CIR",
  "CIL",
];

export const OTHER_LYMPH_NODES_REGION_NAMES_SHORT =
  OTHER_LYMPH_NODES_REGIONS.reduce((prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:short.${region}`));
    return prevMap;
  }, new Map<string, string>());

export const OTHER_LYMPH_NODES_REGION_NAMES_LONG =
  OTHER_LYMPH_NODES_REGIONS.reduce((prevMap, region) => {
    prevMap.set(region, i18n.t(`regionNames:long.${region}`));
    return prevMap;
  }, new Map<string, string>());

export const OTHER_LYMPH_NODES_TNM_REGIONS = new Set(
  OTHER_LYMPH_NODES_REGIONS.map((region) =>
    i18n.t(`regionNames:short.${region}`)
  )
);

// Summary
export const REGION_NAMES_LONG = new Map([
  ...PROSTATE_STAGING_REGION_NAMES_LONG,
  ...PROSTATE_RESTAGING_REGION_NAMES_LONG,
  ...PROSTATE_REMOVED_REGION_NAMES_LONG,
  ...PELVIC_LYMPH_NODES_REGION_NAMES_LONG,
  ...BONE_REGION_NAMES_LONG,
  ...OTHER_ORGANS_REGION_NAMES_LONG,
  ...OTHER_LYMPH_NODES_REGION_NAMES_LONG,
]);

export const REGION_NAMES_SHORT = new Map([
  ...PROSTATE_STAGING_REGION_NAMES_SHORT,
  ...PROSTATE_RESTAGING_REGION_NAMES_SHORT,
  ...PROSTATE_REMOVED_REGION_NAMES_SHORT,
  ...PELVIC_LYMPH_NODES_REGION_NAMES_SHORT,
  ...BONE_REGION_NAMES_SHORT,
  ...OTHER_ORGANS_REGION_NAMES_SHORT,
  ...OTHER_LYMPH_NODES_REGION_NAMES_SHORT,
]);

export const EQUIVALENT_REGIONS = [
  ...PROSTATE_STAGING_EQUIVALENT_REGIONS,
  ...BONE_EQUVALENT_REGIONS,
];

export const ALL_TNM_REGIONS = [
  ...new Set([
    ...PROSTATE_STAGING_TNM_REGIONS,
    ...PROSTATE_RESTAGING_TNM_REGIONS,
    ...PROSTATE_REMOVED_TNM_REGIONS,
    ...PELVIC_LYMPH_NODES_TNM_REGIONS,
    ...BONE_TNM_REGIONS,
    ...OTHER_ORGANS_TNM_REGIONS,
    ...OTHER_LYMPH_NODES_TNM_REGIONS,
  ]),
];

export default function regionName(regionId: string | undefined) {
  if (regionId == null) {
    return "";
  }
  if (regionId.includes("*")) {
    return "Click to remove marker";
  }
  return REGION_NAMES_LONG.get(regionId) || "";
}

export function shortRegionName(regionId: string | undefined) {
  if (regionId == null) {
    return "";
  }
  return REGION_NAMES_SHORT.get(regionId) || "";
}
