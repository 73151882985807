import React from "react";
import { useNavigate } from "react-router-dom";
import { GleasonScore, gleasonScores } from "../api/patients";
import { Col, Row, Form, Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { PatientTreatment } from "../api/patientTreatments";
import TreatmentRowEdit from "./ClinicalData/TreatmentRowEdit";
import TreatmentRow from "./ClinicalData/TreatmentRow";
import { useLogContext } from "./LogToast/LogContext";
import { useCurrentPatientId } from "../utils/routerUtils";
import { useCurrentPatientContext } from "../contexts/CurrentPatientContext";
import { TestResult } from "../api/testResults";
import TestResultRow from "./ClinicalData/TestResultRow";
import TestResultRowEdit from "./ClinicalData/TestResultRowEdit";
import { calculatePSADoublingTime } from "../utils/psaValueUtils";

export default function PatientPage(): React.ReactElement {
  const { t } = useTranslation(["patient", "treatments", "labTests", "common"]);
  const { log } = useLogContext();
  const {
    currentPatient,
    setCurrentPatient,
    saveCurrentPatient,
    discardCurrentPatientChanges,
    treatments,
    testResults,
  } = useCurrentPatientContext();

  const {
    list: treatmentList,
    updateAtIndex: updateTreatmentAtIndex,
    deleteAtIndex: deleteTreatmentAtIndex,
    add: addTreatment,
    editAtIndex: editTreatmentAtIndex,
  } = treatments;

  const {
    list: testResultList,
    updateAtIndex: updateTestResultAtIndex,
    deleteAtIndex: deleteTestResultAtIndex,
    add: addTestResult,
    editAtIndex: editTestResultAtIndex,
  } = testResults;

  const currentPatientId = useCurrentPatientId();
  const navigate = useNavigate();

  const psaDoublingTime = React.useMemo(() => {
    const psaValues = testResultList.filter(
      (testResult) =>
        testResult.deleted === false && testResult.test_name === "PSA"
    );

    return calculatePSADoublingTime(psaValues);
  }, [testResultList]);

  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">
          {currentPatient.id === null
            ? t("patient:newPatient")
            : t("patient:patientLabelWithId", {
                id: currentPatient.id,
              })}
        </h3>
      </div>
      <Form style={{ marginTop: 12 }}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="patientId">
            <Form.Label className="mb-0">{t("patient:patientId")}</Form.Label>
            <Form.Control
              type="text"
              value={currentPatient.patient_id}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  patient_id: e.target.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="patientId">
            <Form.Label className="mb-0">{t("patient:studyId")}</Form.Label>
            <Form.Control
              type="text"
              value={currentPatient.study_id || ""}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  study_id: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("patient:dateOfBirth")}</Form.Label>
            <DatePicker
              className="form-control"
              value={
                currentPatient.date_of_birth &&
                currentPatient.date_of_birth.toJSDate()
              }
              onChange={(value: Date | null) => {
                setCurrentPatient({
                  ...currentPatient,
                  date_of_birth: value && DateTime.fromJSDate(value),
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">{t("patient:age")}</Form.Label>
            <Form.Control
              plaintext
              readOnly
              value={t("common:ageInYears", {
                count: currentPatient.date_of_birth
                  ? Math.floor(
                      -currentPatient.date_of_birth.diffNow("years").years
                    )
                  : 0,
              })}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} lg={3} md={6} sm={12}>
            <Form.Label className="mb-0">{"initialPsaValue"}</Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              value={currentPatient.initial_psa_value || ""}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  initial_psa_value: parseFloat(e.target.value) || null,
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col} lg={3} md={6} sm={12}>
            <Form.Label className="mb-0">
              {t("patient:diagnosisDate")}
            </Form.Label>
            <DatePicker
              className="form-control"
              value={
                currentPatient.diagnosis_date &&
                currentPatient.diagnosis_date.toJSDate()
              }
              onChange={(value: Date | null) => {
                setCurrentPatient({
                  ...currentPatient,
                  diagnosis_date: value && DateTime.fromJSDate(value),
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col} lg={3} md={6} sm={12}>
            <Form.Label className="mb-0">
              {t("patient:gleasonScore")}
            </Form.Label>
            <Form.Select
              value={currentPatient.gleason_score || "n/a"}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  gleason_score: e.target.value as GleasonScore,
                });
              }}
            >
              <option value="n/a" disabled>
                {t("patient:gleasonScorePlaceholder")}
              </option>
              {gleasonScores.map((score) => (
                <option value={score} key={score}>
                  {score}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} lg={3} md={6} sm={12}>
            <Form.Label className="mb-0">{t("patient:ctnm")}</Form.Label>
            <Form.Control
              type="text"
              value={currentPatient.c_tnm || ""}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  c_tnm: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
      </Form>
      {/* Treatment History */}
      <Row className="mt-5">
        <Col>
          <h5>{t("treatments:header")}</h5>
        </Col>
      </Row>
      <Form>
        {treatmentList.length === 0 ? (
          <p>{t("treatments:emptyPlaceholder")}</p>
        ) : (
          <Row>
            <Form.Group as={Col} xs="6">
              <Form.Label className="mb-0">{t("treatments:name")}</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs="2">
              <Form.Label className="mb-0">
                {t("treatments:startDate")}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs="2">
              <Form.Label className="mb-0">
                {t("treatments:endDate")}
              </Form.Label>
            </Form.Group>
          </Row>
        )}
        {treatmentList
          .filter((t) => t.deleted === false)
          .map((treatment, index) =>
            treatment.edited ? (
              <TreatmentRowEdit
                key={index}
                treatment={treatment}
                onUpdateTreatment={(newTreatment: PatientTreatment) => {
                  updateTreatmentAtIndex(newTreatment, index);
                }}
                onDiscardChanges={() => {
                  editTreatmentAtIndex(false, index);
                }}
              />
            ) : (
              <TreatmentRow
                key={index}
                treatment={treatment}
                onDeleteTreatment={() => {
                  deleteTreatmentAtIndex(index);
                }}
                onEditTreatment={() => {
                  editTreatmentAtIndex(true, index);
                }}
              />
            )
          )}
      </Form>
      <Row className="mb-3">
        <Col>
          <Button
            variant="outline-primary"
            onClick={() => {
              addTreatment();
            }}
          >
            {t("treatments:addTreatmentButton")}
          </Button>
        </Col>
      </Row>
      {/* Test results */}
      <Row className="mt-5">
        <Col>
          <h5>{t("labTests:header")}</h5>
        </Col>
      </Row>
      {testResultList.length === 0 ? (
        <p>{t("labTests:emptyPlaceholder")}</p>
      ) : (
        <Row>
          <Form.Group as={Col} xs="6">
            <Form.Label className="mb-0">{t("labTests:psaValue")}</Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs="2">
            <Form.Label className="mb-0">{t("labTests:testDate")}</Form.Label>
          </Form.Group>
        </Row>
      )}
      <Form>
        {testResultList
          .filter((t) => t.deleted === false)
          .map((testResult, index) =>
            testResult.edited ? (
              <TestResultRowEdit
                key={index}
                showTestName={false}
                testResult={testResult}
                onUpdateTestResult={(newTestResult: TestResult) => {
                  updateTestResultAtIndex(newTestResult, index);
                }}
                onDiscardChanges={() => {
                  editTestResultAtIndex(false, index);
                }}
              />
            ) : (
              <TestResultRow
                key={index}
                showTestName={false}
                highlighted={
                  /* TODO: introduce the highlight logic that depends on the current scan */ false
                }
                testResult={testResult}
                onDeleteTestResult={() => {
                  deleteTestResultAtIndex(index);
                }}
                onEditTestResult={() => {
                  editTestResultAtIndex(true, index);
                }}
              />
            )
          )}
      </Form>
      <Row className="mb-3">
        <Col xs="6">
          <Button
            disabled={!currentPatient.id}
            variant="outline-primary"
            onClick={() => {
              addTestResult();
            }}
          >
            {t("labTests:addPSAValueButton")}
          </Button>
        </Col>
        <Col xs="4" className="d-flex align-items-center">
          <h6 className="mb-0">
            {psaDoublingTime
              ? t("labTests:psaDoublingTime", { time: psaDoublingTime })
              : "N/A"}
          </h6>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="d-flex flex-row justify-content-between flex-nowrap pb-2">
          <Button
            variant="outline-secondary"
            style={{ minWidth: 100, minHeight: 41 }}
            onClick={() => {
              discardCurrentPatientChanges();
              navigate("/patients");
            }}
          >
            {t("common:cancel")}
          </Button>
          <Button
            variant="outline-primary"
            onClick={async () => {
              const newPatient = await saveCurrentPatient();
              if (currentPatientId === null && newPatient.id !== null) {
                navigate(`/patients/${newPatient.id}`);
              } else {
                log(t("common:changesSaved"));
              }
            }}
          >
            {t("common:save")}
          </Button>
        </Col>
      </Row>
    </>
  );
}
