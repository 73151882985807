import * as React from "react";
import { useAuthContext } from "./AuthContext";
import {
  createPatient,
  getAllPatients,
  getPatientById,
  initialPatient,
  Patient,
  updatePatient,
} from "../api/patients";
import { useOrgContext } from "./OrgContext";
interface PatientsContextType {
  loading: boolean;
  patients: Patient[];
  currentPatient: Patient;
  setCurrentPatient: (newPatient: Patient) => void;
  savePatient: (patient: Patient) => Promise<void>;
  changesSaved: boolean;
}

const PatientsContext = React.createContext<PatientsContextType>({
  loading: false,
  patients: [],
  currentPatient: initialPatient(""),
  setCurrentPatient: () => {},
  savePatient: (patient) => Promise.resolve(),
  changesSaved: false,
});

interface PatientsContextProviderProps {
  children: React.ReactNode;
}

export function PatientsContextProvider({
  children,
}: PatientsContextProviderProps) {
  const { getAccessToken } = useAuthContext();
  const { currentOrg } = useOrgContext();

  const [loading, setLoading] = React.useState(false);
  // TODO: Refactor patients as a useMemo function
  // Docs: https://reactjs.org/docs/hooks-reference.html#usememo
  const [patients, setPatients] = React.useState<Patient[]>([]);
  const [currentPatient, setCurrentPatient__internal] = React.useState<Patient>(
    initialPatient("")
  );
  const [changesSaved, setChangesSaved] = React.useState(false);

  // Update the patient list every time
  React.useEffect(() => {
    setLoading(true);
    setCurrentPatient__internal(initialPatient(""));
    fetchAllPatients();
  }, [currentOrg]);

  async function fetchAllPatients() {
    const accessToken = await getAccessToken();
    setPatients(await getAllPatients(accessToken));
    setLoading(false);
  }

  async function handleCreatePatient(patientId: number) {
    const accessToken = await getAccessToken();
    const newPatient = await getPatientById(accessToken, patientId);

    setPatients(patients.concat(newPatient));
    setCurrentPatient__internal(newPatient);
    setChangesSaved(true);
  }

  async function handleUpdatePatient(patient: Patient) {
    setPatients(patients.map((p) => (p.id === patient.id ? patient : p)));
    setCurrentPatient__internal(patient);
    setChangesSaved(true);
  }

  function setCurrentPatient(newPatient: Patient) {
    setCurrentPatient__internal(newPatient);
    setChangesSaved(false);
  }

  async function savePatient(patient: Patient) {
    const accessToken = await getAccessToken();

    try {
      if (patient.id) {
        await updatePatient(accessToken, patient.id, patient);
        handleUpdatePatient(patient);
      } else {
        const response = await createPatient(accessToken, patient);
        await handleCreatePatient(response.id as number);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <PatientsContext.Provider
      value={{
        loading,
        patients,
        currentPatient,
        setCurrentPatient,
        savePatient,
        changesSaved,
      }}
    >
      {children}
    </PatientsContext.Provider>
  );
}

export const usePatientsContext = () => React.useContext(PatientsContext);
