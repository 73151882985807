import { DateTime } from "luxon";
import * as React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  createPatientTreatment,
  deletePatientTreatment,
  getTreatmentsForPatient,
  initialPatientTreatment,
  PatientTreatment,
  updatePatientTreatment,
} from "../../api/patientTreatments";
import { useAuthContext } from "../../contexts/AuthContext";
import { usePatientsContext } from "../../contexts/PatientsContext";
import TreatmentRow from "./TreatmentRow";
import TreatmentRowEdit from "./TreatmentRowEdit";
import { useTranslation } from "react-i18next";

interface TreatmentHistoryProps {}

function TreatmentHistory(props: TreatmentHistoryProps) {
  const { t } = useTranslation("treatments");
  const { getAccessToken } = useAuthContext();
  const { currentPatient } = usePatientsContext();

  const [treatments, setTreatments] = React.useState<PatientTreatment[]>([]);

  const [editMode, setEditMode] = React.useState<boolean[]>([]);

  function sortTreatments(
    newTreatments: PatientTreatment[],
    newEditMode: boolean[]
  ) {
    const combinedTreatments = newTreatments.map((treatment, index) => ({
      treatment: treatment,
      editMode: newEditMode[index],
    }));

    combinedTreatments.sort((a, b) => {
      if (a.treatment.ended_at == null && b.treatment.ended_at != null) {
        return -1;
      }
      if (a.treatment.ended_at != null && b.treatment.ended_at == null) {
        return 1;
      }
      return b.treatment.started_at.diff(a.treatment.started_at).milliseconds;
    });

    setTreatments(combinedTreatments.map((cm) => cm.treatment));
    setEditMode(combinedTreatments.map((cm) => cm.editMode));
  }

  function setEditModeAtIndex(treatmentIndex: number, newValue: boolean) {
    setEditMode(
      editMode.map((flag, index) => {
        return index === treatmentIndex ? newValue : flag;
      })
    );
  }

  React.useEffect(() => {
    (async () => {
      if (!currentPatient.id) {
        console.warn("Current patient is not defined");
        return;
      }
      try {
        const accessToken = await getAccessToken();
        const newTreatments = await getTreatmentsForPatient(
          accessToken,
          currentPatient.id
        );
        sortTreatments(
          newTreatments,
          new Array(newTreatments.length).fill(false)
        );
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPatient.id]);

  function addTreatment() {
    if (!currentPatient.id) {
      console.warn("Current patient is not defined");
    } else {
      sortTreatments(
        treatments.concat(
          initialPatientTreatment(currentPatient.id, "", DateTime.now())
        ),
        editMode.concat(true)
      );
    }
  }

  async function updateTreatmentAtIndex(
    treatmentIndex: number,
    newTreatment: PatientTreatment
  ) {
    if (!currentPatient.id) {
      console.warn("Current patient is not defined");
      return;
    }

    const accessToken = await getAccessToken();

    if (newTreatment.id) {
      await updatePatientTreatment(
        accessToken,
        currentPatient.id,
        newTreatment.id,
        newTreatment
      );
    } else {
      const response = await createPatientTreatment(
        accessToken,
        currentPatient.id,
        newTreatment
      );
      newTreatment.id = response.id;
    }

    sortTreatments(
      treatments.map((treatment, index) => {
        return index === treatmentIndex ? newTreatment : treatment;
      }),
      editMode.map((flag, index) => {
        return index === treatmentIndex ? false : flag;
      })
    );
  }

  async function deleteTreatmentAtIndex(index: number) {
    if (!currentPatient.id) {
      console.warn("Current patient is not defined");
      return;
    }

    const treatment = treatments[index];

    if (treatment.id) {
      try {
        const accessToken = await getAccessToken();
        await deletePatientTreatment(
          accessToken,
          currentPatient.id,
          treatment.id
        );
      } catch (e) {
        console.error(e);
        return;
      }
    }

    setTreatments(treatments.filter((_, i) => i !== index));
    setEditMode(editMode.filter((_, i) => i !== index));
  }

  return (
    <>
      <Row className="mt-5">
        <Col>
          <h5>{t("header")}</h5>
        </Col>
      </Row>
      <Form>
        {treatments.length === 0 ? (
          <p>{t("emptyPlaceholder")}</p>
        ) : (
          <Row>
            <Form.Group as={Col} xs="6">
              <Form.Label className="mb-0">{t("name")}</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs="2">
              <Form.Label className="mb-0">{t("startDate")}</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs="2">
              <Form.Label className="mb-0">{t("endDate")}</Form.Label>
            </Form.Group>
          </Row>
        )}
        {treatments.map((treatment, index) =>
          editMode[index] ? (
            <TreatmentRowEdit
              key={index}
              treatment={treatment}
              onUpdateTreatment={(newTreatment) => {
                updateTreatmentAtIndex(index, newTreatment);
              }}
              onDiscardChanges={() => {
                setEditModeAtIndex(index, false);
              }}
            />
          ) : (
            <TreatmentRow
              key={index}
              treatment={treatment}
              onDeleteTreatment={() => {
                deleteTreatmentAtIndex(index);
              }}
              onEditTreatment={() => {
                setEditModeAtIndex(index, true);
              }}
            />
          )
        )}
      </Form>
      <Row className="mb-3">
        <Col>
          <Button
            disabled={!currentPatient.id}
            variant="outline-primary"
            onClick={() => {
              addTreatment();
            }}
          >
            {t("addTreatmentButton")}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default TreatmentHistory;
