import React from "react";
import { PatientRecipCriteria } from "../../api/patientRecipCriteria";
import { PatientScan } from "../../api/patientScans";
import {
  ClinicalIndication,
  RecipCriteriaResponseEvaluation,
  RestagingCategory,
} from "../../constants/enums";
import { useTranslation } from "react-i18next";

interface RecipCriteriaProps {
  criteriaApplicable: boolean;
  responseEvaluation: RecipCriteriaResponseEvaluation;
  reportText: string;
  reference: string;
}

export default function useRecipCriteria(
  patientScan: PatientScan,
  patientRecipCriteria: PatientRecipCriteria | null
): RecipCriteriaProps {
  const { t } = useTranslation("recipCriteria");

  const criteriaApplicable = React.useMemo(() => {
    return (
      patientScan.clinical_indication === ClinicalIndication.RESTAGING &&
      patientScan.restaging_category ===
        RestagingCategory.TREATMENT_RESPONSE_EVALUATION
    );
  }, [patientScan.clinical_indication, patientScan.restaging_category]);

  const responseEvaluation = React.useMemo(() => {
    if (patientRecipCriteria == null) {
      return RecipCriteriaResponseEvaluation.NotApplicable;
    }
    switch (patientRecipCriteria.psma_positive_tumor_volume_change) {
      case "complete_response":
        return RecipCriteriaResponseEvaluation.CompleteResponse;
      case "partial_response":
        if (patientRecipCriteria.new_lesion_appearance) {
          return RecipCriteriaResponseEvaluation.StableDisease;
        } else {
          return RecipCriteriaResponseEvaluation.PartialResponse;
        }
      case "stable_disease":
        return RecipCriteriaResponseEvaluation.StableDisease;
      case "progressive_disease":
        if (patientRecipCriteria.new_lesion_appearance) {
          return RecipCriteriaResponseEvaluation.ProgressiveDisease;
        } else {
          return RecipCriteriaResponseEvaluation.StableDisease;
        }
      case null:
      case undefined:
        return RecipCriteriaResponseEvaluation.NotApplicable;
      default:
        console.warn("Unknown PSMA tumor change");
        return RecipCriteriaResponseEvaluation.NotApplicable;
    }
  }, [patientRecipCriteria]);

  const reportText = React.useMemo(() => {
    if (patientRecipCriteria == null) {
      return "";
    }
    const lesionAppearanceText = patientRecipCriteria.new_lesion_appearance
      ? `${t("newLesionAppearanceReportText")}${
          patientRecipCriteria.new_lesion_location
            ? ` (${patientRecipCriteria.new_lesion_location})`
            : ""
        }`
      : t("noNewLesionAppearanceReportText");

    let psmaPositiveTumorVolumeChangeText;
    switch (patientRecipCriteria.psma_positive_tumor_volume_change) {
      case "complete_response":
        psmaPositiveTumorVolumeChangeText = t("completeResponseReportText");
        break;
      case "partial response":
        psmaPositiveTumorVolumeChangeText = t("partialResponseReportText");
        break;
      case "stable_disease":
        psmaPositiveTumorVolumeChangeText = t("stableDiseaseReportText");
        break;
      case "progressive_disease":
        psmaPositiveTumorVolumeChangeText = t("progressiveDiseaseReportText");
        break;
      default:
        psmaPositiveTumorVolumeChangeText = t("unknownResponseReportText");
    }

    let recipCriteriaText;
    switch (responseEvaluation) {
      case RecipCriteriaResponseEvaluation.StableDisease:
        recipCriteriaText = t("stableDisease").toLocaleLowerCase();
        break;
      case RecipCriteriaResponseEvaluation.CompleteResponse:
        recipCriteriaText = t("completeResponse").toLocaleLowerCase();
        break;
      case RecipCriteriaResponseEvaluation.ProgressiveDisease:
        recipCriteriaText = t("progressiveDisease").toLocaleLowerCase();
        break;
      case RecipCriteriaResponseEvaluation.PartialResponse:
        recipCriteriaText = t("partialResponse").toLocaleLowerCase();
        break;
      default:
        recipCriteriaText = t("unknownResponse").toLocaleLowerCase();
    }

    return t("reportText", {
      lesionAppearanceText,
      psmaPositiveTumorVolumeChangeText,
      recipCriteriaText,
    });
  }, [patientRecipCriteria, responseEvaluation, t]);

  const reference = t("citation");

  return {
    criteriaApplicable,
    responseEvaluation,
    reportText,
    reference,
  };
}
