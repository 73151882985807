import * as React from "react";
import PropTypes from "prop-types";
import { GlobalHotKeys } from "react-hotkeys";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

import { copyImages } from "../../utils/clipboardUtils";
import BootstrapIcon from "../BootstrapIcon";
import { useTranslation } from "react-i18next";

const CopyImagesButton = ({ images, labels, log }) => {
  const { t } = useTranslation("miTNM");

  function onClick() {
    copyImages(images, labels)
      .then(() => {
        log(t("miTNM:copyAllImagesSuccessPrompt"));
      })
      .catch((error) => {
        console.error(error);
        log(error.message);
      });
  }

  return (
    <GlobalHotKeys
      handlers={{
        COPY_IMAGE: onClick,
      }}
      allowChanges={true}
    >
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="copy-images-button-tooltip">
            {t("miTNM:copyAllImagesOverlay")}
          </Tooltip>
        }
      >
        <Button variant="outline-secondary" onClick={onClick}>
          <BootstrapIcon name="file-earmark-image" size={18} />
        </Button>
      </OverlayTrigger>
    </GlobalHotKeys>
  );
};

CopyImagesButton.propTypes = {
  images: PropTypes.arrayOf(PropTypes.instanceOf(Blob)).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  log: PropTypes.func.isRequired,
};

export default CopyImagesButton;
